<template>
    <activix-tooltip :content="tooltip">
        <div class="input-wrapper">
            <activix-checkbox
                :disabled="disabled"
                :value="checked"
                :size="size"
                :color="color"
                :cross="isCross"
                @mouseenter.native="disabled || fetchLead(lead.id)"
                @click.native.prevent="onClick"
            />

            <template v-if="inClientCard">
                <span class="editable | ml-4 | md:ml-2" :class="{ disabled: disabled }" @click="onDateClick">
                    {{ localizedDate || emptyValue }}
                </span>
            </template>

            <activix-confirm-modal
                :approve-text="$t('dashboards.removeIt')"
                :content="$t('dashboards.deleteFollowUpConfirmation', [$t('dashboards.performance.recorded')])"
                :deny-text="$t('general.cancel')"
                :opened.sync="uncheckModalOpened"
                :title="$t('general.areYouSure')"
                type="error"
                @approve="triggerUncheck"
                @deny="updateChecked"
            />
        </div>
    </activix-tooltip>
</template>

<script>
    import { get } from 'lodash-es';

    import { mapActions, mapState } from 'pinia';

    // Value Objects
    import ActivixDate from '../../../../value-objects/ActivixDate.js';

    import FollowUpMixin from '../../../../mixins/FollowUpMixin.js';
    import { useDashboardStore } from '../../../../store/modules/dashboard/store.js';
    import { useGlobalStore } from '../../../../store/store.js';

    export default {
        mixins: [FollowUpMixin],

        props: {
            lead: {
                type: Object,
                required: true,
            },
            startDate: {
                type: ActivixDate,
                default: null,
            },
            endDate: {
                type: ActivixDate,
                default: null,
            },
            inClientCard: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            return {
                checked: false,
                uncheckModalOpened: false,
            };
        },

        computed: {
            ...mapState(useGlobalStore, ['authUser', 'guest']),
            ...mapState(useDashboardStore, ['dashboardType']),

            recordedDate() {
                return get(this.soldOrFirstWantedVehicle, 'recorded_date', null);
            },

            date() {
                return new ActivixDate(this.recordedDate, 'date');
            },

            localizedDate() {
                return this.date.toHumanShort(false);
            },

            isInDates() {
                return this.date.isBetween(this.startDate, this.endDate);
            },

            soldVehicle() {
                return this.lead.getSoldVehicle();
            },

            soldOrFirstWantedVehicle() {
                return this.lead.getSoldOrFirstWantedVehicle();
            },

            color() {
                if (!this.inClientCard && this.dashboardType == 'saleTable' && this.date.isPast()) {
                    return 'green';
                }

                return 'blue';
            },

            disabled() {
                if (['invalid', 'lost', 'duplicate'].includes(this.lead.status)) {
                    return true;
                }

                if (!this.$can('leads.recorded:update')) {
                    return true;
                }

                return this.guest;
            },

            isCross() {
                if (this.inClientCard || !this.recordedDate || this.isInDates) {
                    return false;
                }

                return true;
            },

            tooltip() {
                if (!this.inClientCard) {
                    let tooltip = `<strong>${this.$t('clientCard.vehicles.saved_date')}</strong>`;

                    if (this.recordedDate) {
                        tooltip += `<br>${this.localizedDate}</strong>`;

                        if (!this.isInDates) {
                            tooltip += `<br><em>${this.$t('dashboards.notInDate')}</em>`;
                        }
                    }

                    return tooltip;
                }

                return '';
            },
        },

        watch: {
            recordedDate: {
                immediate: true,
                handler() {
                    this.updateChecked();
                },
            },
        },

        methods: {
            ...mapActions(useGlobalStore, ['updateLeadVehicleAction', 'fetchLead']),

            onDateClick() {
                const toggleChecked = !this.checked;

                this.onClick(toggleChecked);
            },

            updateChecked() {
                this.checked = !!this.recordedDate;
            },

            onClick(toggleChecked = true) {
                if (this.disabled) {
                    return;
                }

                if (toggleChecked) {
                    this.checked = !this.checked;
                }

                this.openModal();
            },

            openModal() {
                if (this.checked) {
                    if (!this.soldVehicle && this.lead.wanted_vehicles.length > 1) {
                        this.$modal.show('selectVehicle', {
                            leadId: this.lead.id,
                            step: 'recorded',
                        });
                    } else {
                        this.$modal.show('followUp', {
                            leadId: this.lead.id,
                            current: 'recorded',
                            last: null,
                        });
                    }
                } else if (this.soldOrFirstWantedVehicle) {
                    this.uncheckModalOpened = true;
                }
            },

            triggerUncheck() {
                const data = {
                    recorded_date: null,
                };

                this.updateLeadVehicleAction(data, this.soldOrFirstWantedVehicle.id);
            },
        },

        created() {
            this.$eventBus.$on('cancel-follow-up', this.updateChecked);
        },

        mounted() {
            this.updateChecked();
        },

        beforeDestroy() {
            this.$eventBus.$off('cancel-follow-up', this.updateChecked);
        },
    };
</script>
