<template>
    <div>
        <div class="text-center text-sm" v-if="showDateTime">
            {{ dateTime }}
        </div>
        <div class="flex items-end mt-3 mb-6">
            <activix-avatar
                class="rounded-full shadow-inner text-xl text-grey-600 mr-3"
                :size="36"
                :username="contextLead.fullName"
                :src="communication.url"
                v-if="isIncoming"
            />
            <div class="max-w-md rounded-lg overflow-hidden" :class="classes" v-html="formattedMessage" />
        </div>
    </div>
</template>

<script>
    /* eslint-disable vue/require-prop-types */
    import { mapState } from 'pinia';
    import ActivixDate from '../../value-objects/ActivixDate.js';
    import CommunicationType from '../../entities/CommunicationType.js';
    import { useContextStore } from '../../store/modules/context/store.js';

    export default {
        props: {
            communication: {
                required: true,
            },
            content: {
                type: Object,
                required: true,
            },
            showDateTime: {
                type: Boolean,
                required: true,
            },
        },

        computed: {
            ...mapState(useContextStore, ['contextLead']),

            formattedMessage() {
                return this.content.message.replace(/<img src="https:\/\/scontent.xx.fbcdn[^>]*>/g, `[${this.$t('facebook.image')}]`);
            },

            isIncoming() {
                return this.content.communication_type_id == CommunicationType.INCOMING;
            },

            dateTime() {
                return new ActivixDate(this.content.created_at).toHumanShort();
            },

            classes() {
                const classes = [];

                if (this.content.is_attachment) {
                    classes.push('p-0');
                } else {
                    classes.push('p-3');
                }

                if (this.content.communication_type_id == CommunicationType.OUTGOING) {
                    classes.push('bg-blue-500');
                    classes.push('text-white');
                    classes.push('ml-auto');
                } else {
                    classes.push('bg-grey-300');
                    classes.push('mr-auto');
                }

                return classes;
            },
        },
    };
</script>
