<template>
    <activix-modal
        size="sm"
        :name="name"
        ref="modal"
        @before-open="onBeforeOpen"
        @close="onClose"
        @closed="onClosed"
    >
        <template slot="body">
            <sweet-alert-icon class="mx-auto" type="error" />
            <div class="text-2xl font-semibold text-center text-grey-650" v-text="$t('general.areYouSure')" />
            <div class="text-lg text-center" v-text="modalBodyMessage" />
        </template>

        <template slot="footer">
            <activix-button type="danger" @click="canceledByDealer">
                {{ $t('result.canceledByDealer') }}
            </activix-button>
            <activix-button type="danger" @click="canceledByClient">
                {{ isPhoneAppointment ? $tc('result.canceled', 1) : $t('result.noShow') }}
            </activix-button>
        </template>
    </activix-modal>
</template>

<script>
    import { mapActions } from 'pinia';
    import SweetAlertIcon from '../elements/SweetAlert/Icon.vue';
    import { useGlobalStore } from '../../store/store.js';

    export default {
        components: {
            SweetAlertIcon,
        },

        props: {
            name: {
                type: String,
                required: true,
            },
        },

        data() {
            return {
                leadId: null,
                mainEventId: null,
                isPhoneAppointment: false,
                onCloseCallback: null,
            };
        },

        computed: {
            modalBodyMessage() {
                const isPhoneAppointment = this.isPhoneAppointment
                    ? this.$t('dashboards.performance.appointmentPhone')
                    : this.$t('dashboards.performance.appointment');

                return this.$t('dashboards.deleteFollowUpConfirmation', [isPhoneAppointment]);
            },
        },

        methods: {
            ...mapActions(useGlobalStore, ['updateLeadAction']),

            canceledByDealer() {
                const field = this.isPhoneAppointment ? 'appt_call' : 'appointment';
                const data = {};

                data[field] = false;
                data[`${field}_date`] = null;

                this.updateLeadAction(this.leadId, data);
                this.close();
            },

            canceledByClient() {
                const field = this.isPhoneAppointment ? 'appt_call' : 'appointment';
                const data = {};

                data[field] = true;

                this.updateLeadAction(this.leadId, data, {
                    mainEvent: {
                        id: this.mainEventId,
                        canceled: true,
                    },
                });

                this.close();
            },

            close() {
                this.$refs.modal.hide();
            },

            onBeforeOpen({ leadId, mainEventId, isPhoneAppointment = false, onClose = null }) {
                this.leadId = leadId;
                this.mainEventId = mainEventId;
                this.isPhoneAppointment = isPhoneAppointment;
                this.onCloseCallback = onClose;
            },

            onClose() {
                if (this.onCloseCallback) {
                    this.onCloseCallback();
                }
            },

            onClosed() {
                this.leadId = null;
                this.mainEventId = null;
                this.isPhoneAppointment = false;
                this.onCloseCallback = null;
            },
        },
    };
</script>
