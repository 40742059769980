<template>
    <activix-tooltip :content="tooltip">
        <icon
            :class="className"
            :name="iconName"
            @click="$emit('click')"
        />
    </activix-tooltip>
</template>

<script>
    import Lead from '@/entities/Lead.js';

    export default {
        props: {
            lead: {
                type: Lead,
                required: true,
            },
        },

        computed: {
            className() {
                if (this.lead.customer.isConfirmedConsent) {
                    return 'text-green-500';
                }

                if (this.lead.customer.isActiveConsent) {
                    return 'text-blue-500';
                }

                if (this.lead.customer.isAboutToExpireConsent) {
                    return 'text-orange-500';
                }

                if (this.lead.customer.isExpiredConsent) {
                    return 'text-red-500';
                }

                return null;
            },

            iconName() {
                if (this.lead.customer.isExpiredConsent) {
                    return 'regular/remove-shield';
                }

                return 'regular/check-shield';
            },

            tooltip() {
                if (this.lead.customer.isAboutToExpireConsent) {
                    return this.$tc('consent.expiresIn', this.lead.customer.temporaryConsentDaysLeft, { days: this.lead.customer.temporaryConsentDaysLeft });
                }

                if (this.lead.customer.isExpiredConsent) {
                    return this.$t('consent.statuses.expired');
                }

                return '';
            },
        },
    };
</script>
