<template>
    <div class="input-wrapper">
        <activix-checkbox
            :disabled="disabled"
            :value="checked"
            :size="size"
            @click.native.prevent="onClick"
        />
    </div>
</template>

<script>
    import { mapActions, mapState } from 'pinia';

    import FollowUpMixin from '../../../../mixins/FollowUpMixin.js';
    import { useGlobalStore } from '../../../../store/store.js';

    export default {
        mixins: [FollowUpMixin],

        props: {
            lead: {
                type: Object,
                required: true,
            },
            inClientCard: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            return {
                checked: false,
            };
        },

        computed: {
            ...mapState(useGlobalStore, ['authUser', 'guest']),

            disabled() {
                if (['invalid', 'lost', 'duplicate'].includes(this.lead.status)) {
                    return true;
                }

                if (!this.$can('leads.twenty_four_hour:update')) {
                    return true;
                }

                if (this.lead.disabledBySaleVerified(this.authUser)) {
                    return true;
                }

                return this.guest;
            },
        },

        watch: {
            'lead.twenty_four_hour': {
                immediate: true,
                handler() {
                    this.updateChecked();
                },
            },
        },

        methods: {
            ...mapActions(useGlobalStore, ['updateLeadAction']),

            updateChecked() {
                this.checked = this.lead.twenty_four_hour;
            },

            onClick() {
                if (this.disabled) {
                    return;
                }

                this.checked = !this.checked;
                this.updateLeadAction(this.lead.id, { twenty_four_hour: this.checked });
            },
        },

        mounted() {
            this.updateChecked();
        },
    };
</script>
