export default {
    computed: {
        size() {
            if (this.inClientCard) {
                return this.lgLayout ? 'md' : 'sm';
            }

            return 'md';
        },

        emptyValue() {
            return this.lgLayout ? '' : '...';
        },
    },
};
