<template>
    <activix-tooltip :content="tooltip">
        <div class="input-wrapper">
            <activix-checkbox
                :disabled="disabled"
                :value="checked"
                :size="size"
                :cross="isCross"
                @mouseenter.native="disabled || fetchLead(lead.id)"
                @click.native.prevent="onClick"
            />

            <template v-if="inClientCard">
                <span class="editable | ml-4 | md:ml-2" :class="{ disabled: disabled }" @click="onDateClick">
                    {{ localizedDate || emptyValue }}
                </span>
            </template>
        </div>
    </activix-tooltip>
</template>

<script>
    import { mapActions, mapState } from 'pinia';

    import ActivixDate from '../../../../value-objects/ActivixDate.js';

    import FollowUpMixin from '../../../../mixins/FollowUpMixin.js';
    import { useGlobalStore } from '../../../../store/store.js';
    import LeadType from '../../../../entities/LeadType.js';

    export default {
        mixins: [FollowUpMixin],

        props: {
            lead: {
                type: Object,
                required: true,
            },
            startDate: {
                type: ActivixDate,
                default: null,
            },
            endDate: {
                type: ActivixDate,
                default: null,
            },
            inClientCard: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            return {
                checked: false,
            };
        },

        computed: {
            ...mapState(useGlobalStore, ['authUser', 'guest']),

            date() {
                return new ActivixDate(this.lead.discounted_date);
            },

            localizedDate() {
                return this.date.toHumanShort(false);
            },

            isInDates() {
                return this.date.isBetween(this.startDate, this.endDate);
            },

            disabled() {
                if (['invalid', 'lost', 'duplicate'].includes(this.lead.status)) {
                    return true;
                }

                if (!this.lead.delivered_date && (!this.lead.isOfType(LeadType.RENEWAL) || !this.lead.refinanced_date)) {
                    return true;
                }

                if (!this.$can('leads.discounted_date:update')) {
                    return true;
                }

                return this.guest;
            },

            isCross() {
                if (this.inClientCard || !this.lead.discounted_date || this.isInDates) {
                    return false;
                }

                return true;
            },

            tooltip() {
                if (['invalid', 'lost', 'duplicate'].includes(this.lead.status)) {
                    return this.$t('clientCard.leadCannotHaveStatus');
                }

                if (!this.lead.delivered_date && (!this.lead.isOfType(LeadType.RENEWAL) || !this.lead.refinanced_date)) {
                    return this.$t('clientCard.mustBeDelivered');
                }

                if (!this.lead.account.client_card_fields.process.discounted) {
                    return this.$t('clientCard.discountedMustBeActive');
                }

                if (!this.inClientCard) {
                    let tooltip = `<strong>${this.$tc('clientCard.discounted', 1)}</strong>`;

                    if (this.lead.discounted_date) {
                        tooltip += `<br>${this.localizedDate}`;

                        if (!this.isInDates) {
                            tooltip += `<br><em>${this.$t('dashboards.notInDate')}</em>`;
                        }
                    }

                    return tooltip;
                }

                return '';
            },
        },

        watch: {
            'lead.discounted_date': {
                immediate: true,
                handler() {
                    this.updateChecked();
                },
            },
        },

        methods: {
            ...mapActions(useGlobalStore, ['updateLeadAction', 'fetchLead']),

            updateChecked() {
                this.checked = !!this.lead.discounted_date;
            },

            onDateClick() {
                const toggleChecked = !this.checked;

                this.onClick(toggleChecked);
            },

            onClick(toggleChecked = true) {
                if (this.disabled) {
                    return;
                }

                if (toggleChecked) {
                    this.checked = !this.checked;
                }

                if (this.checked) {
                    this.$modal.show('followUp', {
                        leadId: this.lead.id,
                        current: 'discounted',
                        last: null,
                    });
                } else {
                    this.removeProcess();
                }
            },

            removeProcess() {
                this.updateLeadAction(this.lead.id, { discounted_date: null });
            },

            onCancelFollowUp() {
                this.updateChecked();
            },
        },

        created() {
            this.$eventBus.$on('cancel-follow-up', this.updateChecked);
        },

        mounted() {
            this.updateChecked();
        },

        beforeDestroy() {
            this.$eventBus.$off('cancel-follow-up', this.updateChecked);
        },
    };
</script>
