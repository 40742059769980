<template>
    <activix-tooltip :content="tooltip">
        <div class="input-wrapper">
            <activix-checkbox
                :disabled="isDisabled"
                :value="checkboxValue"
                :size="size"
                :color="color"
                :cross="isCross"
                @mouseenter.native="isDisabled || fetchLead(lead.id)"
                @click.native.prevent="isWalkIn ? '' : onClick()"
            />

            <template v-if="inClientCard">
                <span class="editable | ml-4 | md:ml-2" :class="{ disabled: disabled }" @click="onDateClick">
                    {{ localizedDate || emptyValue }}
                </span>
            </template>

            <activix-confirm-modal
                :approve-text="$t('dashboards.removeIt')"
                :content="
                    $t('dashboards.deleteFollowUpConfirmation', [
                        isHomePresented
                            ? $t('dashboards.performance.home_presented')
                            : $t('dashboards.performance.presented'),
                    ])
                "
                :deny-text="$t('general.cancel')"
                :opened.sync="uncheckModalOpened"
                :title="$t('general.areYouSure')"
                type="error"
                @approve="triggerUncheck"
                @deny="updateChecked"
            />

            <activix-confirm-modal
                :content="$t('clientCard.addVisitWithoutAppointmentWarning')"
                :opened.sync="visitWarningOpened"
                @approve="onApproved"
                @deny="uncheckPresented"
            />
        </div>
    </activix-tooltip>
</template>

<script>
    import { mapActions, mapState } from 'pinia';
    import { getIconMarkup } from '../../../../utils/icon.js';

    import ActivixDate from '../../../../value-objects/ActivixDate.js';

    import FollowUpMixin from '../../../../mixins/FollowUpMixin.js';
    import { useGlobalStore } from '../../../../store/store.js';

    export default {
        mixins: [FollowUpMixin],

        props: {
            lead: {
                type: Object,
                required: true,
            },
            startDate: {
                type: ActivixDate,
                default: null,
            },
            endDate: {
                type: ActivixDate,
                default: null,
            },
            inClientCard: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            return {
                checked: false,
                uncheckModalOpened: false,
                visitWarningOpened: false,
                shouldOpenFollowUpModal: false,
            };
        },

        computed: {
            ...mapState(useGlobalStore, ['authUser', 'guest']),

            isDisabled() {
                return this.disabled || this.isWalkIn;
            },

            date() {
                if (this.lead.presented_date) {
                    return new ActivixDate(this.lead.presented_date);
                }

                return new ActivixDate(this.lead.home_presented_date);
            },

            color() {
                return this.isHomePresented ? 'purple' : 'blue';
            },

            localizedDate() {
                return this.date.toHumanShort();
            },

            isHomePresented() {
                return !!this.lead.home_presented_date;
            },

            isInDates() {
                return this.date.isBetween(this.startDate, this.endDate);
            },

            isWalkIn() {
                return this.lead.lead_type_id == 3 && this.lead.division_id != 3;
            },

            checkboxTooltip() {
                if (this.inClientCard && this.isWalkIn) {
                    return this.$t('clientCard.cantUncheckOnWalkIn');
                }

                return '';
            },

            checkboxValue() {
                return !!this.lead.presented_date || !!this.lead.home_presented_date;
            },

            disabled() {
                if (['invalid', 'lost', 'duplicate'].includes(this.lead.status)) {
                    return true;
                }

                if (this.lead.be_back_date) {
                    return true;
                }

                if (this.lead.presented_date && this.lead.sale_date && !this.lead.sale_by_phone) {
                    return true;
                }

                if (this.lead.disabledBySaleVerified(this.authUser)) {
                    return true;
                }

                return !this.$can('leads.presented_date:update');
            },

            isCross() {
                if (
                    this.inClientCard ||
                    (!this.lead.presented_date && !this.lead.home_presented_date) ||
                    this.isInDates
                ) {
                    return false;
                }

                return true;
            },

            tooltip() {
                let tooltip = '';

                if (!this.inClientCard) {
                    if (this.lead.home_presented_date) {
                        tooltip += `
                            <div class="flex items-center justify-center">
                                <strong class="mr-1">${this.$t('result.presented')}</strong>
                                ${getIconMarkup('regular/house-chimney-2', { class: 'event-icon' })}
                            </div>
                        `;

                        tooltip += `${this.localizedDate}`;

                        if (!this.isInDates) {
                            tooltip += `<br><em>${this.$t('dashboards.notInDate')}</em>`;
                        }
                    }

                    if (this.lead.presented_date) {
                        tooltip += `<strong>${this.$t('result.presented')}</strong>`;
                        tooltip += `<br>${this.localizedDate}`;

                        if (!this.isInDates) {
                            tooltip += `<br><em>${this.$t('dashboards.notInDate')}</em>`;
                        }
                    }
                } else if (['invalid', 'lost', 'duplicate'].includes(this.lead.status)) {
                    tooltip += `<em>${this.$t(`clientCard.statusTooltip.${this.lead.status}`, [
                        this.$t('clientCard.statusTooltip.inputs.presented'),
                    ])}</em>`;
                } else if (this.lead.disabledBySaleVerified(this.authUser)) {
                    tooltip += `<em>${this.$t('clientCard.verifiedSaleUneditable')}</em>`;
                }

                return tooltip;
            },

            showModalFromUrl() {
                return (
                    !this.disabled &&
                    !this.lead.presented_date &&
                    !this.lead.delivered_date &&
                    !this.lead.sale_date &&
                    !empty(this.$route.query.date)
                );
            },
        },

        watch: {
            'lead.presented_date': {
                immediate: true,
                handler() {
                    this.updateChecked();
                },
            },

            '$route.query': {
                immediate: true,
                handler() {
                    if (this.showModalFromUrl) {
                        this.$modal.show('followUp', {
                            leadId: this.lead.id,
                            current: 'presented',
                            last: null,
                        });
                    }
                },
            },
        },

        methods: {
            ...mapActions(useGlobalStore, ['updateLeadAction', 'fetchLead']),

            onDateClick() {
                const toggleChecked = !this.checked;

                this.onClick(toggleChecked);
            },

            updateChecked() {
                this.checked = this.checkboxValue;
            },

            onClick(toggleChecked = true) {
                if (this.disabled) {
                    return;
                }

                if (toggleChecked) {
                    this.checked = !this.checked;
                }

                if (this.checked) {
                    const currentAppt = new ActivixDate(this.lead.appt_confirmation_date);

                    if (
                        this.inClientCard &&
                        !this.lead.presented_date &&
                        currentAppt.isToday() &&
                        this.lead.division_id == 3
                    ) {
                        this.updateLeadAction(this.lead.id, {
                            presented: true,
                            presented_date: currentAppt.toString(),
                        });
                    } else {
                        this.triggerOpenFollowUpModal();
                    }
                } else if (this.lead.lead_type_id != 3) {
                    this.uncheckModalOpened = true;
                }
            },

            triggerOpenFollowUpModal() {
                if (
                    !this.isWalkIn &&
                    !this.lead.appointment_date &&
                    !this.lead.presented_date &&
                    !this.lead.home_presented_date &&
                    !this.lead.sale_by_phone
                ) {
                    this.visitWarningOpened = true;
                    return;
                }

                this.openFollowUpModal();
                this.shouldOpenFollowUpModal = false;
            },

            continueOpenFollowUp() {
                if (this.shouldOpenFollowUpModal) {
                    this.openFollowUpModal();
                    this.shouldOpenFollowUpModal = false;
                }
            },

            triggerUncheck() {
                if (this.isHomePresented) {
                    this.updateLeadAction(this.lead.id, {
                        home_presented: false,
                        home_presented_date: null,
                    });

                    return;
                }

                this.updateLeadAction(this.lead.id, {
                    presented: false,
                    presented_date: null,
                });
            },

            uncheckPresented() {
                this.checked = false;
            },

            onApproved() {
                this.shouldOpenFollowUpModal = true;
                this.continueOpenFollowUp();
            },

            openFollowUpModal() {
                this.$modal.show('followUp', {
                    leadId: this.lead.id,
                    hideTrash: this.isWalkIn,
                    current: this.isHomePresented ? 'home_presented' : 'presented',
                    last: this.lead.division_id == 3 ? 'appt_confirmation' : 'appointment',
                });
            },
        },

        created() {
            this.$eventBus.$on('cancel-follow-up', this.updateChecked);
        },

        mounted() {
            this.updateChecked();
        },

        beforeDestroy() {
            this.$eventBus.$off('cancel-follow-up', this.updateChecked);
        },
    };
</script>
