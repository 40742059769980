<template>
    <div>
        <activix-modal
            :opened="opened"
            :portal="portal"
            @close="close"
            @closed="onClosed"
        >
            <template slot="header">
                <h4 class="modal-title">
                    {{ $t('clientCard.chooseFusionRule') }}
                </h4>
            </template>

            <template slot="body">
                <div class="flex justify-center">
                    <activix-tooltip :content="$t('clientCard.mergeFillTooltip')">
                        <activix-button
                            :active="currentAction === 'fill'"
                            :type="currentAction === 'fill' ? 'grey' : 'default'"
                            @click="currentAction = 'fill'"
                        >
                            {{ $t('accounts.edit.fill') }}
                        </activix-button>
                    </activix-tooltip>

                    <activix-tooltip :content="$t('clientCard.mergeReplaceTooltip')">
                        <activix-button
                            :active="currentAction === 'overwrite'"
                            :type="currentAction === 'overwrite' ? 'grey' : 'default'"
                            @click="currentAction = 'overwrite'"
                        >
                            {{ $t('accounts.edit.overwrite') }}
                        </activix-button>
                    </activix-tooltip>
                </div>
                <hr class="my-6" />
                <div class="flex items-center lg:px-14" v-if="mergedLead && contextLead">
                    <div class="flex-1 flex justify-center overflow-hidden">
                        <button class="text-left overflow-hidden" @click="!isEvent ? (direction = 'toCurrent') : ''">
                            <div>
                                <icon :name="getIcon(contextLead.lead_type_id)" class="text-blue-500 text-2xl" />
                                <span class="font-semibold ml-2">{{ getTypeName(contextLead) }}</span>
                            </div>
                            <div>{{ getDate(contextLead) }}</div>
                            <div class="truncate max-w-48">
                                {{ contextLead.fullName }}
                            </div>
                            <div>
                                #{{ contextLead.id }}
                            </div>
                        </button>
                    </div>
                    <div class="flex mx-4 flex-col items-center text-xl">
                        <icon
                            name="bold/arrow-thick-left"
                            :class="{
                                'opacity-50': direction === 'toOther',
                                'text-blue-500': direction === 'toCurrent',
                                'cursor-pointer': !isEvent,
                                'cursor-not-allowed': isEvent,
                            }"
                            @click="!isEvent ? (direction = 'toCurrent') : ''"
                        />
                        <icon
                            name="bold/arrow-thick-right"
                            :class="{
                                'opacity-50': direction === 'toCurrent',
                                'text-blue-500': direction === 'toOther',
                                'cursor-pointer': !isEvent,
                                'cursor-not-allowed': isEvent,
                            }"
                            @click="!isEvent ? (direction = 'toOther') : ''"
                        />
                    </div>
                    <div class="flex-1 flex justify-center overflow-hidden">
                        <button class="text-left overflow-hidden" @click="!isEvent ? (direction = 'toOther') : ''">
                            <div>
                                <icon :name="getIcon(mergedLead.lead_type_id)" class="text-blue-500 text-2xl" />
                                <span class="font-semibold ml-2">{{ getTypeName(mergedLead) }}</span>
                            </div>
                            <div>{{ getDate(mergedLead) }}</div>
                            <div class="truncate max-w-48">
                                {{ mergedLead.fullName }}
                            </div>
                            <div>
                                #{{ mergedLead.id }}
                            </div>
                        </button>
                    </div>
                </div>
                <div class="mt-6 text-center" v-if="!fromHasSms && toRenew">
                    <span class="italic">{{ $t('clientCard.mergeLeadRenewNote') }}</span>
                </div>
            </template>

            <template slot="footer">
                <activix-button :disabled="executing" @click="close">
                    {{ $t('general.cancel') }}
                </activix-button>
                <activix-button
                    type="primary"
                    :disabled="disabled"
                    :loading="executing"
                    @click="modals.mergeConfirmationOpen = true"
                >
                    {{ $t('clientCard.mergeLead') }}
                </activix-button>
            </template>
        </activix-modal>

        <activix-confirm-modal
            type="warning"
            :content="$t('clientCard.sureMerge')"
            :opened.sync="modals.mergeConfirmationOpen"
            @approve="executeAction"
        />
    </div>
</template>

<script>
    import { mapActions, mapState } from 'pinia';
    import LeadType from '../../entities/LeadType.js';
    import { useDashboardStore } from '../../store/modules/dashboard/store.js';
    import { useGlobalStore } from '../../store/store.js';
    import { useContextStore } from '../../store/modules/context/store.js';

    export default {
        props: {
            opened: {
                type: Boolean,
                required: true,
            },
            portal: {
                type: String,
                default: 'modal-1',
            },
        },

        data() {
            return {
                currentAction: '',
                direction: 'toOther',
                executing: false,
                isEvent: false,
                modals: {
                    mergeConfirmationOpen: false,
                },
                LeadType,
            };
        },

        computed: {
            ...mapState(useContextStore, ['contextLead']),
            ...mapState(useGlobalStore, ['mergedLead']),
            ...mapState(useDashboardStore, ['bulkSelect']),

            toRenew() {
                return (
                    (this.direction === 'toOther' &&
                        this.mergedLead &&
                        this.mergedLead.lead_type_id === LeadType.RENEWAL) ||
                    (this.direction === 'toCurrent' && this.contextLead.isOfType(LeadType.RENEWAL))
                );
            },

            fromHasSms() {
                return (
                    (this.direction === 'toOther' && this.contextLead && this.hasSmsCommunication(this.contextLead)) ||
                    (this.direction === 'toCurrent' && this.mergedLead && this.hasSmsCommunication(this.mergedLead))
                );
            },

            disabled() {
                return !this.currentAction;
            },
        },

        watch: {
            contextLead() {
                if (this.contextLead.id) {
                    if (this.contextLead.account.id) {
                        this.currentAction = this.contextLead.account.merge_rule;
                    }

                    if (
                        this.contextLead.lead_type.name === 'event' &&
                        !empty(this.mergedLead) &&
                        this.mergedLead.lead_type.name !== 'event'
                    ) {
                        this.direction = 'toCurrent';
                        this.isEvent = true;
                    }
                }
            },

            mergedLead() {
                if (!empty(this.mergedLead) && this.contextLead.id) {
                    if (this.mergedLead.lead_type.name === 'event' && this.contextLead.lead_type.name !== 'event') {
                        this.direction = 'toOther';
                        this.isEvent = true;
                    }

                    if (this.mergedLead.lead_type.name !== 'event' && this.contextLead.lead_type.name === 'event') {
                        this.direction = 'toCurrent';
                        this.isEvent = true;
                    }
                }
            },
        },

        methods: {
            ...mapActions(useDashboardStore, ['setAllLeadsInTableSelected']),
            ...mapActions(useGlobalStore, ['mergeLeads']),

            getIcon(leadTypeId) {
                switch (leadTypeId) {
                    case LeadType.EMAIL:
                        return 'light/gauge-dashboard';
                    case LeadType.PHONE:
                        return 'regular/phone';
                    case LeadType.WALK_IN:
                        return 'regular/walking';
                    case LeadType.LOYALTY:
                        return 'light/award-ribbon-star-3';
                    case LeadType.RENEWAL:
                        return 'bold/synchronize-arrow-1';
                    case LeadType.EVENT:
                        return 'regular/megaphone-1';
                    case LeadType.WEB_ORDER:
                        return 'regular/shopping-cart-check';
                    default:
                        return 'regular/wench-1';
                }
            },

            getTypeName(lead) {
                switch (lead.lead_type_id) {
                    case LeadType.EMAIL:
                        return this.$t('dashboards.webLead');
                    case LeadType.PHONE:
                        return this.$t('dashboards.phoneUp');
                    case LeadType.WALK_IN:
                        return this.$t('dashboards.walkIn');
                    case LeadType.LOYALTY:
                        return this.$t('dashboards.loyalty');
                    case LeadType.RENEWAL:
                        return this.$t('dashboards.renewalAbr');
                    case LeadType.SMS:
                        return this.$t('dashboards.sms');
                    case LeadType.EVENT:
                        return this.$t('dashboards.event');
                    case LeadType.DMS:
                        return this.$t('dashboards.dms');
                    case LeadType.WEB_ORDER:
                        return this.$t('dashboards.webOrder');
                }
                return '';
            },

            getDate(lead) {
                let date = null;

                switch (lead.lead_type_id) {
                    case LeadType.PHONE:
                        date = lead.call_date;
                        break;

                    case LeadType.WALK_IN:
                        date = lead.presented_date;
                        break;

                    case LeadType.DMS:
                        date = lead.appointment_date;
                }

                date = locale_dt(date || lead.created_at);

                return date.humanShort();
            },

            hasSmsCommunication(lead) {
                return lead.communications.some(c => c.communication_method_id === 3);
            },

            async executeAction() {
                let from = this.contextLead;
                let to = this.mergedLead;

                if (this.direction === 'toCurrent') {
                    from = this.mergedLead;
                    to = this.contextLead;
                }

                this.executing = true;
                this.$emit('merging');

                this.close();

                this.$notify.info(this.$t('massActions.warningMerge'));

                try {
                    await this.mergeLeads(from, to, this.currentAction, this.$route.name);

                    this.setAllLeadsInTableSelected(false);
                    this.$eventBus.$emit('leads-merged', { from, to });
                    this.$eventBus.$emit('delete-leads', [from.id]);
                    this.$emit('merged');
                } finally {
                    this.executing = false;
                    this.$emit('canceled');
                }
            },

            close() {
                this.$emit('update:opened', false);
            },

            onClosed() {
                this.isEvent = false;
            },
        },

        mounted() {
            this.$nextTick(() => {
                if (this.contextLead.id && this.contextLead.account.id) {
                    this.currentAction = this.contextLead.account.merge_rule;
                }
            });
        },
    };
</script>
