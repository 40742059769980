<template>
    <activix-tooltip :content="tooltip">
        <div class="input-wrapper">
            <activix-checkbox
                :disabled="disabled"
                :value="checked"
                :size="size"
                :color="color"
                :cross="isCross"
                @mouseenter.native="disabled || fetchLead(lead.id)"
                @click.native.prevent="onClick"
            />

            <template v-if="inClientCard">
                <span class="editable | ml-4 | md:ml-2" :class="{ disabled: disabled }" @click="onDateClick">
                    {{ localizedDate || emptyValue }}
                </span>
            </template>
        </div>
    </activix-tooltip>
</template>

<script>

    import { mapActions, mapState } from 'pinia';

    import ActivixDate from '../../../../value-objects/ActivixDate.js';

    import FollowUpMixin from '../../../../mixins/FollowUpMixin.js';

    // Pinia
    import { useDashboardStore } from '../../../../store/modules/dashboard/store.js';
    import { useGlobalStore } from '../../../../store/store.js';

    export default {
        mixins: [FollowUpMixin],

        props: {
            lead: {
                type: Object,
                required: true,
            },
            startDate: {
                type: ActivixDate,
                default: null,
            },
            endDate: {
                type: ActivixDate,
                default: null,
            },
            inClientCard: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            return {
                checked: false,
            };
        },

        computed: {
            ...mapState(useGlobalStore, ['authUser', 'guest']),
            ...mapState(useDashboardStore, ['dashboardType']),

            date() {
                return new ActivixDate(this.lead.csi_date);
            },

            localizedDate() {
                return this.date.toHumanShort(false);
            },

            isInDates() {
                return this.date.isBetween(this.startDate, this.endDate);
            },

            isServiceLead() {
                return this.lead.division_id == 3;
            },

            color() {
                if (!this.inClientCard && this.dashboardType == 'saleTable') {
                    return 'green';
                }

                return 'blue';
            },

            disabled() {
                if (['invalid', 'lost', 'duplicate'].includes(this.lead.status)) {
                    return true;
                }

                if (!this.isServiceLead && !this.lead.delivered_date && !this.lead.csi_date) {
                    return true;
                }

                if (this.isServiceLead && !this.lead.invoiced && !this.lead.csi_date) {
                    return true;
                }

                if (!this.$can('leads.csi_date:update')) {
                    return true;
                }

                return this.guest;
            },

            isCross() {
                if (this.inClientCard || !this.lead.csi_date || this.isInDates) {
                    return false;
                }

                return true;
            },

            tooltip() {
                let tooltip = '';

                if (!this.inClientCard) {
                    tooltip += `<strong>${this.$t('date.csi')}</strong>`;

                    if (this.lead.csi_date) {
                        tooltip += `<br>${this.localizedDate}`;

                        if (!this.isInDates) {
                            tooltip += `<br><em>${this.$t('dashboards.notInDate')}</em>`;
                        }
                    }
                } else if (['invalid', 'lost', 'duplicate'].includes(this.lead.status)) {
                    tooltip += `<em>${this.$t(`clientCard.statusTooltip.${this.lead.status}`, [
                        this.$t('clientCard.statusTooltip.inputs.csi'),
                    ])}</em>`;
                }

                return tooltip;
            },
        },

        watch: {
            'lead.csi_date': {
                immediate: true,
                handler() {
                    this.updateChecked();
                },
            },
        },

        methods: {
            ...mapActions(useGlobalStore, ['updateLeadAction', 'fetchLead']),

            updateChecked() {
                this.checked = !!this.lead.csi_date;
            },

            onDateClick() {
                const toggleChecked = !this.checked;

                this.onClick(toggleChecked);
            },

            onClick(toggleChecked = true) {
                if (this.disabled) {
                    return;
                }

                if (toggleChecked) {
                    this.checked = !this.checked;
                }

                if (this.checked) {
                    this.$modal.show('followUp', {
                        leadId: this.lead.id,
                        current: 'csi',
                        last: this.isServiceLead ? 'presented' : 'delivered',
                    });
                } else {
                    this.removeProcess();
                }
            },

            removeProcess() {
                this.updateLeadAction(this.lead.id, {
                    csi: false,
                    csi_date: null,
                });
            },
        },

        created() {
            this.$eventBus.$on('cancel-follow-up', this.updateChecked);
        },

        mounted() {
            this.updateChecked();
        },

        beforeDestroy() {
            this.$eventBus.$off('cancel-follow-up', this.updateChecked);
        },
    };
</script>
