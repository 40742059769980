<template>
    <activix-tooltip :content="tooltip">
        <div class="input-wrapper">
            <activix-checkbox
                :disabled="disabled"
                :value="checked"
                :size="size"
                :color="color"
                :cross="isCross"
                @mouseenter.native="disabled || fetchLead(lead.id)"
                @click.native.prevent="onClick"
            />
            <template v-if="inClientCard">
                <span class="editable | ml-4 | md:ml-2" :class="{ disabled: disabled }" @click="onDateClick">
                    {{ localizedDate || emptyValue }}
                </span>

                <span class="flex items-center ml-2" v-if="canAddAppointment && !disabled">
                    <icon name="bold/add" class="link-grey text-sm" @click="addAppointment" />
                </span>
            </template>

            <activix-confirm-modal
                :approve-text="$t('dashboards.removeIt')"
                :content="$t('dashboards.deleteFollowUpConfirmation', [$t('dashboards.performance.appointment')])"
                :deny-text="$t('general.cancel')"
                :opened.sync="uncheckModalOpened"
                :title="$t('general.areYouSure')"
                type="error"
                @approve="triggerUncheck"
                @deny="updateChecked"
            />

            <edit-phone-appointment :lead-id="lead.id" :opened.sync="editPhoneAppointmentModalOpened" />
        </div>
    </activix-tooltip>
</template>

<script>
    import { mapActions, mapState } from 'pinia';
    import { getIconMarkup } from '../../../../utils/icon.js';

    import ActivixDate from '../../../../value-objects/ActivixDate.js';

    import EditPhoneAppointment from '../../../modals/EditPhoneAppointment.vue';

    import TaskEventType from '../../../../entities/TaskEventType.js';
    import LeadType from '../../../../entities/LeadType.js';
    import Division from '../../../../entities/Division.js';

    import FollowUpMixin from '../../../../mixins/FollowUpMixin.js';
    import { useGlobalStore } from '../../../../store/store.js';

    export default {
        components: { EditPhoneAppointment },

        mixins: [FollowUpMixin],

        props: {
            lead: {
                type: Object,
                required: true,
            },
            startDate: {
                type: ActivixDate,
                default: null,
            },
            endDate: {
                type: ActivixDate,
                default: null,
            },
            inClientCard: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            return {
                checked: false,
                uncheckModalOpened: false,
                editPhoneAppointmentModalOpened: false,
            };
        },

        computed: {
            ...mapState(useGlobalStore, ['authUser', 'guest']),

            date() {
                return new ActivixDate(this.lead.appt_call_date);
            },

            appointmentDate() {
                return new ActivixDate(this.lead.appointment_date);
            },

            localizedDate() {
                return this.date.toHumanShort();
            },

            isInDates() {
                return this.date.isBetween(this.startDate, this.endDate);
            },

            mainEvent() {
                if (!this.lead || !this.lead.task_events) {
                    return false;
                }

                return this.lead.task_events.find(taskEvent => {
                    return taskEvent.task_event_type_id == TaskEventType.PHONE_APPOINTMENT && taskEvent.main_event;
                });
            },

            canAddAppointment() {
                return !this.date.isEmpty() && this.appointmentDate.isEmpty();
            },

            color() {
                if (this.mainEvent && this.mainEvent.canceled) {
                    return 'red';
                }

                if (this.mainEvent && this.mainEvent.confirmed) {
                    return 'green';
                }

                return 'purple';
            },

            disabled() {
                if (this.lead.division_id == Division.SERVICE) {
                    return true;
                }

                if (this.lead.lead_type_id == LeadType.WALK_IN) {
                    return true;
                }

                if (['invalid', 'lost', 'duplicate'].includes(this.lead.status)) {
                    return true;
                }

                if (this.lead.sale_date) {
                    return true;
                }

                if (this.lead.presented_date || this.lead.home_presented_date) {
                    return true;
                }

                if (this.lead.appointment_date && !this.lead.appt_call_date) {
                    return true;
                }

                if (!this.$can('leads.phone_appointment:update')) {
                    return true;
                }

                if (this.lead.disabledBySaleVerified(this.authUser)) {
                    return true;
                }

                return this.guest;
            },

            isCross() {
                if (this.inClientCard || !this.lead.appt_call_date || this.isInDates) {
                    return false;
                }

                return true;
            },

            tooltip() {
                let tooltip = '';

                if (!this.inClientCard) {
                    tooltip += `
                        <div class="flex items-center justify-center">
                            <span class="font-bold mr-1">${this.$t('result.appointment')}</span>
                            ${getIconMarkup('regular/phone-landline', { class: 'event-icon' })}
                        </div>
                    `;
                }

                let disabledTooltip = '';

                if (this.lead.division_id == Division.SERVICE) {
                    disabledTooltip = this.$t('result.leadIsService');
                } else if (this.lead.lead_type_id == LeadType.WALK_IN) {
                    disabledTooltip = this.$t('result.leadIsWalkIn');
                } else if (['invalid', 'lost', 'duplicate'].includes(this.lead.status)) {
                    disabledTooltip += `${this.$t(`clientCard.statusTooltip.${this.lead.status}`, [
                        this.$t('clientCard.statusTooltip.inputs.phoneAppointment'),
                    ])}`;
                } else if (this.lead.sale_by_phone) {
                    disabledTooltip = this.$t('result.leadIsSoldbyPhone');
                } else if (this.lead.presented_date || this.lead.home_presented_date) {
                    disabledTooltip = this.$t('result.leadAlreadyPresented');
                } else if (this.lead.appointment_date && !this.lead.appt_call_date) {
                    disabledTooltip = this.$t('result.leadAlreadyHasAppointment');
                } else if (this.lead.disabledBySaleVerified(this.authUser)) {
                    disabledTooltip = this.$t('clientCard.verifiedSaleUneditable');
                } else if (this.guest) {
                    disabledTooltip = this.$t('clientCard.guestMode');
                }

                if (disabledTooltip) {
                    return `
                        ${tooltip}
                        <div class="italic">${disabledTooltip}</div>
                    `;
                }

                if (!this.inClientCard) {
                    if (this.lead.appt_call_date) {
                        tooltip += `<div>${this.localizedDate}</div>`;

                        if (!this.isInDates) {
                            tooltip += `<div class="italic">${this.$t('dashboards.notInDate')}</div>`;
                        }
                    }
                }

                if (this.lead.appt_call_date && this.mainEvent && this.mainEvent.no_show) {
                    tooltip += `<div>${this.$t('modal.noShowTooltip')}</div>`;
                }

                return tooltip;
            },
        },

        watch: {
            'lead.appt_call_date': {
                immediate: true,
                handler() {
                    this.updateChecked();
                },
            },
        },

        methods: {
            ...mapActions(useGlobalStore, ['updateLeadAction', 'fetchLead']),

            onDateClick() {
                const toggleChecked = !this.checked;

                this.onClick(toggleChecked);
            },

            updateChecked() {
                this.checked = !!this.lead.appt_call_date;
            },

            onClick() {
                if (this.disabled) {
                    return;
                }

                this.openModal();
            },

            openModal() {
                if (!this.checked) {
                    if (this.appointmentDate.isEmpty()) {
                        this.editPhoneAppointmentModalOpened = true;
                        return;
                    }

                    this.dispatchSetCurrentFollowUp('appt_call', true);
                } else if (this.mainEvent && !this.mainEvent.canceled) {
                    this.openCancelAppointmentModal();
                } else {
                    this.uncheckModalOpened = true;
                }
            },

            dispatchSetCurrentFollowUp(currentSetup, showPhoneIcon) {
                this.$modal.show('followUp', {
                    leadId: this.lead.id,
                    current: currentSetup,
                    last: null,
                    showPhoneIcon,
                });
            },

            addAppointment() {
                if (this.disabled) {
                    return;
                }

                this.dispatchSetCurrentFollowUp('appointment', false);
            },

            triggerUncheck() {
                this.updateLeadAction(this.lead.id, {
                    appt_call: false,
                    appt_call_date: null,
                });
            },

            openCancelAppointmentModal() {
                this.$modal.show('cancelAppointment', {
                    leadId: this.lead.id,
                    mainEventId: this.mainEvent.id,
                    isPhoneAppointment: true,
                    onClose: this.updateChecked,
                });
            },
        },

        created() {
            this.$eventBus.$on('cancel-follow-up', this.updateChecked);
            this.$eventBus.$on('open-cancel-phone-appointment', this.openCancelAppointmentModal);
        },

        mounted() {
            this.updateChecked();
        },

        beforeDestroy() {
            this.$eventBus.$off('cancel-follow-up', this.updateChecked);
            this.$eventBus.$off('open-cancel-phone-appointment', this.openCancelAppointmentModal);
        },
    };
</script>
