<template>
    <div class="input-wrapper">
        <activix-checkbox
            :disabled="disabled"
            :value="checked"
            :size="size"
            :color="color"
            @click.native.prevent="onClick"
        />
    </div>
</template>

<script>
    import { mapActions, mapState } from 'pinia';

    import FollowUpMixin from '../../../../mixins/FollowUpMixin.js';
    import { useGlobalStore } from '../../../../store/store.js';

    export default {
        mixins: [FollowUpMixin],

        props: {
            lead: {
                type: Object,
                required: true,
            },
            inClientCard: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            return {
                checked: false,
                first: true,
                update: false,
            };
        },

        computed: {
            ...mapState(useGlobalStore, ['authUser', 'guest']),

            disabled() {
                if (['invalid', 'lost', 'duplicate'].includes(this.lead.status)) {
                    return true;
                }

                if (!this.lead.presented) {
                    return true;
                }

                return this.guest;
            },

            color() {
                if (!this.inClientCard) {
                    return 'green';
                }

                return 'blue';
            },
        },

        watch: {
            lead() {
                this.update = true;
                this.updateChecked();
            },

            checked(newValue) {
                if (!this.first && !this.update) {
                    this.updateLeadAction(this.lead.id, { reached_client: newValue });
                }
            },
        },

        methods: {
            ...mapActions(useGlobalStore, ['updateLeadAction']),

            onClick() {
                if (this.disabled) {
                    return;
                }

                this.checked = !this.checked;
            },

            updateChecked() {
                this.checked = this.lead.reached_client;
                this.$nextTick(() => {
                    this.update = false;
                });
            },

            onCancelFollowUp() {
                this.update = true;
                this.updateChecked();
            },
        },

        mounted() {
            this.$nextTick(() => {
                this.$eventBus.$on('cancel-follow-up', this.onCancelFollowUp);

                this.updateChecked();

                this.first = false;
            });
        },

        beforeDestroy() {
            this.$eventBus.$off('cancel-follow-up', this.onCancelFollowUp);
        },
    };
</script>
