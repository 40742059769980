<template>
    <div className="input-wrapper">
        <activix-checkbox
            :disabled="disabled"
            :value="checked"
            :size="size"
            :color="color"
            @click.native.prevent="onClick"
        />
    </div>
</template>

<script>
    import { mapActions, mapState } from 'pinia';

    import { useGlobalStore } from '@/store/store.js';
    import FollowUpMixin from '../../../../mixins/FollowUpMixin.js';

    export default {
        mixins: [FollowUpMixin],

        props: {
            lead: {
                type: Object,
                required: true,
            },
            inClientCard: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            return {
                checked: false,
            };
        },

        computed: {
            ...mapState(useGlobalStore, ['authUser']),

            disabled() {
                if (['invalid', 'lost', 'duplicate'].includes(this.lead.status)) {
                    return true;
                }

                if (this.lead.disabledBySaleVerified(this.authUser)) {
                    return true;
                }

                return this.guest;
            },

            color() {
                return !this.inClientCard ? 'green' : 'blue';
            },
        },

        watch: {
            'lead.long_term': {
                immediate: true,
                handler() {
                    this.updateChecked();
                },
            },
        },

        methods: {
            ...mapActions(useGlobalStore, ['updateLeadAction']),

            onClick() {
                if (this.disabled) {
                    return;
                }

                this.checked = !this.checked;
                this.updateLeadAction(this.lead.id, { long_term: this.checked });
            },

            updateChecked() {
                this.checked = this.lead.long_term;
            },

        },

        mounted() {
            this.updateChecked();
        },

    };
</script>
