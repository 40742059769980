<template>
    <activix-tooltip :content="tooltip">
        <div class="input-wrapper">
            <checkbox
                name="tire"
                :size="size"
                :vehicle="true"
                :value="vehicleObject ? vehicleObject.tire : null"
                :record-id="vehicleObject ? vehicleObject.id : null"
                :disabled="disabled"
                :lead-id="leadId"
            />
        </div>
    </activix-tooltip>
</template>

<script>
    import { mapState } from 'pinia/dist/pinia.esm-browser';
    import { useGlobalStore } from '@/store/store';
    import { formater } from '../../../../globals/LeadFormatter.js';

    import Checkbox from '../../../lead/inputs/Checkbox.vue';

    import FollowUpMixin from '../../../../mixins/FollowUpMixin.js';

    export default {
        components: {
            Checkbox,
        },

        mixins: [FollowUpMixin],

        props: {
            vehicle: {
                type: Object,
                default: () => {
                    return {};
                },
            },
            guest: {
                type: Boolean,
                default: false,
            },
            lead: {
                type: Object,
                default: () => {
                    return {};
                },
            },
            inClientCard: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            return {
                checked: false,
            };
        },

        computed: {
            ...mapState(useGlobalStore, ['authUser']),

            disabled() {
                if (this.guest) {
                    return true;
                }

                if (this.lead.disabledBySaleVerified(this.authUser, this.vehicle)) {
                    return true;
                }

                if (this.inClientCard) {
                    return false;
                }

                return empty(this.vehicleObject);
            },

            tooltip() {
                if (this.inClientCard) {
                    return '';
                }

                let tooltip = this.$t('clientCard.vehicles.tires');

                if (this.disabled) {
                    tooltip += `<br><em>${this.$t('clientCard.vehicles.noWantedVehicle')}</em>`;
                    return tooltip;
                }

                if (!empty(this.lead) && this.lead.wanted_vehicles.length) {
                    const vehicleInfo = formater.formatVehicleWanted(this.lead, false);
                    tooltip += `<br>${
                        vehicleInfo != '-'
                            ? `<strong>${vehicleInfo}</strong>`
                            : `<em>${this.$t('clientCard.vehicles.noInfo')}</em>`
                    }`;
                    return tooltip;
                }

                return tooltip;
            },

            vehicleObject() {
                if (!empty(this.vehicle)) {
                    return this.vehicle;
                }

                if (!empty(this.lead)) {
                    return this.lead.getSoldOrFirstWantedVehicle();
                }

                return {};
            },

            leadId() {
                return !empty(this.vehicleObject) ? this.vehicleObject.lead_id : null;
            },
        },

        methods: {
            updateChecked() {
                this.checked = !empty(this.vehicleObject) ? this.vehicleObject.tire : false;
            },
        },

        created() {
            this.$eventBus.$on('cancel-follow-up', this.updateChecked);
        },

        mounted() {
            this.updateChecked();
        },

        beforeDestroy() {
            this.$eventBus.$off('cancel-follow-up', this.updateChecked);
        },
    };
</script>
