<template>
    <activix-tooltip :content="tooltip">
        <div class="input-wrapper cursor-pointer" @click="$emit('click')">
            <activix-checkbox
                class="pointer-events-none"
                :size="checkboxSize"
                :value="lead.customer.isConfirmedConsent"
            />
        </div>
    </activix-tooltip>
</template>

<script>
    export default {
        props: {
            checkboxSize: {
                type: String,
                default: 'sm',
                validator: value => ['xs', 'sm', 'md', 'lg'].includes(value),
            },
            lead: {
                type: Object,
                required: true,
            },
        },

        computed: {
            tooltip() {
                if (this.lead.customer.isConfirmedConsent) {
                    return this.lead.customer.consentStateString;
                }

                if (this.lead.customer.isActiveConsent) {
                    if (this.lead.customer.casl_consent.confirmation.sent_at) {
                        return this.$t('consent.waitingForDoubleOptIn');
                    }

                    return this.lead.customer.consentStateString;
                }

                return this.lead.customer.consentStatusString;
            },
        },
    };
</script>
