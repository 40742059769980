<template>
    <div class="overflow-auto">
        <table class="table | w-full" :class="tableClasses">
            <thead>
                <tr>
                    <th class="pl-6" colspan="2">
                        {{ $t('clientCard.communicationType') }}
                    </th>
                    <th v-if="showTimeElapsed">
                        {{ $t('clientCard.timeElapsed') }}
                    </th>
                    <th>{{ $t('clientCard.dateTime') }}</th>
                    <th>{{ $t('clientCard.user') }}</th>
                    <th>{{ $t('clientCard.emailReadAt') }}</th>
                    <th :class="{ 'pr-6': !showExchangeCount && !showAutomationAction }">
                        {{ $t('clientCard.attachments') }}
                    </th>
                    <th :class="{ 'pr-6': !showAutomationAction }" v-if="showExchangeCount">
                        {{ $t('clientCard.exchanges') }}
                    </th>
                    <th class="pr-6" v-if="showAutomationAction">
                        {{ $t('clientCard.action') }}
                    </th>
                </tr>
            </thead>

            <tbody>
                <tr class="h-20" v-if="!communicationsAndAutomationJobs.length">
                    <td class="text-center italic | py-4" colspan="100">
                        {{ $t('clientCard.noCommunication') }}
                    </td>
                </tr>

                <tr class="bg-grey-100 text-center" v-if="showLoadAutomationsRow">
                    <td colspan="100">
                        <a class="font-semibold" @click="automations.showAll = true">
                            {{ $t('clientCard.showAllAutomations') }}
                        </a>
                    </td>
                </tr>
                <tr
                    is="communication-list-item"
                    :communication="communication"
                    :show-exchange-count="showExchangeCount"
                    :show-time-elapsed="showTimeElapsed"
                    :show-automation-action="showAutomationAction"
                    :editable="!readOnly"
                    :key="communication.id"
                    @open-sms-modal="openSmsModal(communication)"
                    @open-update-modal="updateCurrentCommunication(communication)"
                    @open-view-modal="openEmailAccordionModal(communication.id)"
                    @open-automation-modal="openAutomationPreview(communication)"
                    @open-messenger-modal="openMessengerPreview(communication)"
                    v-for="communication in communicationsAndAutomationJobs"
                />
            </tbody>
        </table>

        <email-accordion-modal :opened.sync="modals.emailAccordionOpened" :communication-id="communicationId" />
        <email-lead name="communication:emailLead" :is-forwarding="isForwarding" />
        <automation-preview
            :lead="lead"
            :opened.sync="modals.automationPreview.opened"
            :job="modals.automationPreview.job"
            @closed="modals.automationPreview.job = null"
        />
        <messenger-preview
            :opened.sync="modals.messengerPreview.opened"
            :communication="modals.messengerPreview.communication"
            @closed="modals.messengerPreview.communication = null"
        />
    </div>
</template>

<script>
    // Utils
    import { orderBy, snakeCase } from 'lodash-es';

    // Mixins
    import { mapState } from 'pinia';
    import EmailCompose from '../../mixins/EmailCompose.js';

    // Entities
    import AutomationActionType from '../../entities/AutomationActionType.js';
    import CommunicationMethod from '../../entities/CommunicationMethod.js';
    import CommunicationType from '../../entities/CommunicationType.js';
    import CommunicationStatus from '../../entities/CommunicationStatus.js';
    import LeadType from '../../entities/LeadType.js';
    import Service from '../../entities/Service.js';

    // Components
    import AutomationPreview from '../modals/AutomationPreview.vue';
    import CommunicationListItem from './CommunicationListItem.vue';
    import EmailAccordionModal from '../modals/EmailAccordionModal.vue';
    import EmailLead from '../modals/EmailLead.vue';
    import MessengerPreview from '../modals/MessengerPreview.vue';

    // Pinia
    import { useContextStore } from '../../store/modules/context/store.js';
    import { useGlobalStore } from '../../store/store.js';
    import Supplier from '../../entities/Supplier.js';

    export default {
        components: {
            AutomationPreview,
            CommunicationListItem,
            EmailAccordionModal,
            EmailLead,
            MessengerPreview,
        },

        mixins: [EmailCompose],

        props: {
            readOnly: {
                type: Boolean,
                default: false,
            },
            communicationType: {
                type: Number,
                default: 0,
            },
            tableClasses: {
                type: String,
                default: 'border-t-2',
            },
            lead: {
                type: Object,
                required: true,
            },
        },

        data() {
            return {
                automations: {
                    limit: 3,
                    showAll: false,
                },
                communicationId: 0,
                isForwarding: false,
                modals: {
                    emailAccordionOpened: false,
                    addCommunicationOpened: false,
                    automationPreview: {
                        opened: false,
                        job: null,
                    },
                    messengerPreview: {
                        opened: false,
                        communication: null,
                    },
                },
            };
        },

        computed: {
            ...mapState(useGlobalStore, {
                activeUsers: 'activeUsers',
            }),
            ...mapState(useContextStore, {
                contextAccount: 'account',
                contextResponseTemplates: 'contextResponseTemplates',
            }),

            automationJobs() {
                const jobs = this.lead.automation_jobs.filter(job => {
                    if (!job.action) {
                        return false;
                    }

                    const smsJob = job.action.action == AutomationActionType.SEND_SMS;
                    const emailJob = job.action.action == AutomationActionType.SEND_EMAIL &&
                        this.contextResponseTemplates.some(t => t.id == job.action.parameters.template.id);

                    return !job.execution_started_at && (smsJob || emailJob);
                });

                return orderBy(jobs, ['execution_date'], ['asc']);
            },

            automationJobsAsCommunications() {
                const jobs = this.automationJobs.map(job => {
                    if (job.action.action == AutomationActionType.SEND_SMS) {
                        return this.formatSmsJobAsCommunication(job);
                    }

                    return this.formatEmailJobAsCommunication(job);
                });

                if (this.automations.showAll) {
                    return jobs;
                }

                return jobs.slice(0, this.automations.limit);
            },

            connectedCommunications() {
                return this.lead.communications.filter(communication => {
                    if (
                        (!this.communicationType || this.communicationType === communication.communication_method_id) &&
                        communication.connected &&
                        communication.status !== CommunicationStatus.INTERRUPTED &&
                        communication.status !== CommunicationStatus.ERROR &&
                        communication.status !== CommunicationStatus.INVALID &&
                        communication.status !== CommunicationStatus.PENDING
                    ) {
                        return true;
                    }

                    if (communication.communication_method_id === CommunicationMethod.MESSENGER &&
                        (
                            !this.communicationType ||
                            this.communicationType === CommunicationMethod.MESSENGER
                        )
                    ) {
                        return true;
                    }

                    if (
                        communication.kind === 'auto' &&
                        (
                            !this.communicationType ||
                            this.communicationType === CommunicationMethod.EMAIL
                        )
                    ) {
                        return true;
                    }

                    if (
                        communication.communication_method_id === CommunicationMethod.VIDEO &&
                        (
                            !this.communicationType ||
                            this.communicationType === CommunicationMethod.VIDEO && communication.status !== CommunicationStatus.ANSWERED
                        )
                    ) {
                        return true;
                    }

                    return false;
                });
            },

            showExchangeCount() {
                return this.lead.account.niotext;
            },

            showLoadAutomationsRow() {
                return this.automationJobs.length > this.automations.limit && !this.automations.showAll;
            },

            showTimeElapsed() {
                return this.lead.isNotOfType(LeadType.EVENT);
            },

            showAutomationAction() {
                return this.lead.account.automation;
            },

            communicationsAndAutomationJobs() {
                const communications = [...this.automationJobsAsCommunications, ...this.connectedCommunications].map(communication => {
                    return {
                        ...communication,
                        sort_created_at: communication.original_created_at || communication.created_at,
                    };
                }).filter(communication => {
                    const hasPolestarMarketingCommunication = communication?.suppliers?.some(supplier => supplier.id === Supplier.POLESTAR);

                    return !hasPolestarMarketingCommunication;
                });

                return orderBy(communications, ['sort_created_at'], ['desc']);
            },
        },

        methods: {
            formatEmailJobAsCommunication(job) {
                let templateLocale = this.lead.locale;

                if (job.action.parameters.locales.length === 1 &&
                    typeof job.action.parameters.locales[0] === 'string' &&
                    ['fr', 'en'].includes(job.action.parameters.locales[0])
                ) {
                    templateLocale = job.action.parameters.locales[0];
                }

                const template = this.contextResponseTemplates.find(t => t.id == job.action.parameters.template.id);
                let translation = template.translations.find(t => t.locale == templateLocale);
                const sender = this.lead[snakeCase(job.action.parameters.from_role)];

                if (!translation) {
                    translation = template.translations[0];
                }

                return {
                    id: `a_${job.id}`, // Custom id necessary to prevent overlap with real communications
                    automation: true,
                    automation_job: job,
                    created_at: job.execution_date,
                    created_by_user: false,
                    user: sender,
                    email_subject: this.replaceAttributesInMessage({
                        html: false,
                        lead: this.lead,
                        locale: this.lead.locale,
                        message: translation.subject,
                        sender,
                        users: this.contextAccount.users,
                    }),
                    email_body: this.replaceAttributesInMessage({
                        html: true,
                        lead: this.lead,
                        locale: this.lead.locale,
                        message: translation.body,
                        sender,
                        users: this.contextAccount.users,
                    }),
                    communication_method_id: CommunicationMethod.EMAIL,
                    communication_type_id: CommunicationType.OUTGOING,
                    service_id: Service.LEAD_XPRESS,
                };
            },

            formatSmsJobAsCommunication(job) {
                return {
                    id: `a_${job.id}`, // Custom id necessary to prevent overlap with real communications
                    automation: true,
                    automation_job: job,
                    locale: this.lead.locale,
                    created_at: job.execution_date,
                    created_by_user: false,
                    user: this.getSmsCommunicationUser(job),
                    email_subject: '',
                    email_body: '',
                    communication_method_id: CommunicationMethod.SMS,
                    communication_type_id: CommunicationType.OUTGOING,
                    service_id: Service.NIOTEXT,
                };
            },

            getSmsCommunicationUser(job) {
                if (this.lead[snakeCase(job.action.parameters.from_role)]) {
                    return this.lead[snakeCase(job.action.parameters.from_role)];
                }

                return this.activeUsers.find(user => user.id == job.action.parameters.fallback_user);
            },

            updateCurrentCommunication(communication) {
                this.$emit('open-add-communication-modal', { communication });
            },

            openSmsModal(communication) {
                this.$eventBus.$emit('open-sms-modal', {
                    leadId: this.lead.id,
                    communication,
                });
            },

            openMessengerPreview(communication) {
                this.modals.messengerPreview.communication = communication;
                this.modals.messengerPreview.opened = true;
            },

            openAutomationPreview(communication) {
                this.modals.automationPreview.job = communication.automation_job;
                this.modals.automationPreview.opened = true;
            },

            openEmailAccordionModal(communicationId = null) {
                if (communicationId) {
                    this.communicationId = communicationId;
                }

                this.modals.emailAccordionOpened = true;
            },

            openEmailLeadModal(isForwarding = false) {
                this.isForwarding = isForwarding;

                setTimeout(() => {
                    this.$modal.show('communication:emailLead', this.lead.id);
                }, 400);
            },
        },

        async created() {
            this.$eventBus.$on('open-email-lead-modal', this.openEmailLeadModal);
            this.$eventBus.$on('openEmailAccordionModal', this.openEmailAccordionModal);

            await this.$nextTick();

            if (this.$route && this.$route.query.communicationId) {
                if (this.connectedCommunications.some(c => c.id == this.$route.query.communicationId)) {
                    this.openEmailAccordionModal();
                }
            }
        },

        beforeDestroy() {
            this.$eventBus.$off('open-email-lead-modal', this.openEmailLeadModal);
            this.$eventBus.$off('openEmailAccordionModal', this.openEmailAccordionModal);
        },
    };
</script>
