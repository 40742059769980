<template>
    <activix-tooltip :content="tooltip">
        <div class="input-wrapper">
            <activix-checkbox
                :disabled="disabled"
                :value="checked"
                :size="size"
                :color="color"
                :cross="isCross"
                @mouseenter.native="disabled || fetchLead(lead.id)"
                @click.native.prevent="onClick"
            />

            <template v-if="inClientCard">
                <span class="editable | ml-4 | md:ml-2" :class="{ disabled: disabled }" @click="onDateClick">
                    {{ localizedDate || emptyValue }}
                </span>
            </template>

            <activix-confirm-modal
                :approve-text="$t('dashboards.removeIt')"
                :content="$t('dashboards.deleteFollowUpConfirmation', [$t('dashboards.performance.deliverable')])"
                :deny-text="$t('general.cancel')"
                :opened.sync="uncheckModalOpened"
                :title="$t('general.areYouSure')"
                type="error"
                @approve="triggerUncheck"
                @deny="updateChecked"
            />
        </div>
    </activix-tooltip>
</template>

<script>
    import AccountType from '@/entities/AccountType.js';

    import { mapActions, mapState } from 'pinia';

    // Entities
    import LeadType from '../../../../entities/LeadType.js';

    // Value Objects
    import ActivixDate from '../../../../value-objects/ActivixDate.js';

    import FollowUpMixin from '../../../../mixins/FollowUpMixin.js';

    // Pinia
    import { useDashboardStore } from '../../../../store/modules/dashboard/store.js';
    import { useContextStore } from '../../../../store/modules/context/store.js';
    import { useGlobalStore } from '../../../../store/store.js';

    export default {
        mixins: [FollowUpMixin],

        props: {
            lead: {
                type: Object,
                required: true,
            },
            startDate: {
                type: ActivixDate,
                default: null,
            },
            endDate: {
                type: ActivixDate,
                default: null,
            },
            inClientCard: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            return {
                checked: false,
                uncheckModalOpened: false,
            };
        },

        computed: {
            ...mapState(useGlobalStore, ['authUser', 'guest']),
            ...mapState(useContextStore, {
                contextAccount: 'account',
            }),
            ...mapState(useDashboardStore, ['dashboardType']),

            date() {
                return new ActivixDate(this.lead.deliverable_date);
            },

            localizedDate() {
                return this.date.toHumanShort();
            },

            isInDates() {
                return this.date.isBetween(this.startDate, this.endDate);
            },

            color() {
                if (!this.inClientCard && this.dashboardType == 'saleTable') {
                    return 'green';
                }

                return 'blue';
            },

            disabled() {
                if (['invalid', 'lost', 'duplicate'].includes(this.lead.status)) {
                    return true;
                }

                if (!this.lead.sale_date && !this.lead.deliverable_date && (!this.lead.isOfType(LeadType.RENEWAL) || !this.lead.refinanced_date)) {
                    return true;
                }

                if (empty(this.soldVehicle) && !this.checked && this.lead.account.type != AccountType.CUSTOM && (!this.lead.isOfType(LeadType.RENEWAL) || !this.lead.refinanced_date)) {
                    return true;
                }

                if (this.lead.delivery_date || this.lead.delivered_date) {
                    return true;
                }

                if (this.lead.delivered_date && (!this.lead.isOfType(LeadType.RENEWAL) || !this.lead.refinanced_date)) {
                    return true;
                }

                if (!this.$can('leads.deliverable_date:update')) {
                    return true;
                }

                return this.guest && !this.lead.hasDeliveryMan(this.authUser.id);
            },

            soldVehicle() {
                return this.lead.getSoldVehicle();
            },

            isCross() {
                if (this.inClientCard || !this.lead.deliverable_date || this.isInDates) {
                    return false;
                }

                return true;
            },

            tooltip() {
                if (this.guest) {
                    return this.$t('clientCard.guestMode');
                }

                if (!this.lead.sale_date && !this.lead.sale_by_phone && !this.lead.deliverable_date && (!this.lead.isOfType(LeadType.RENEWAL) || !this.lead.refinanced_date)) {
                    if (this.lead.isOfType(LeadType.RENEWAL)) {
                        return this.$t('clientCard.leadMustBeRenewed');
                    }

                    return this.$t('clientCard.leadMustBeSold');
                }

                if (!['invalid', 'lost', 'duplicate'].includes(this.lead.status)) {
                    if (!this.lead.sale_date && !this.lead.deliverable_date && (!this.lead.isOfType(LeadType.RENEWAL) || !this.lead.refinanced_date)) {
                        if (this.lead.isOfType(LeadType.RENEWAL)) {
                            return this.$t('clientCard.needRenewalChecked');
                        }

                        return this.$t('clientCard.needSaleChecked');
                    }
                } else {
                    return `${this.$t(`clientCard.statusTooltip.${this.lead.status}`, [
                        this.$t('clientCard.statusTooltip.inputs.deliverable'),
                    ])}`;
                }

                if (empty(this.soldVehicle) && !this.lead.deliverable_date && (!this.lead.isOfType(LeadType.RENEWAL) || !this.lead.refinanced_date)) {
                    return this.$t('clientCard.leadMustHaveASoldWantedVehicle');
                }

                let tooltip = '';

                if (!this.inClientCard) {
                    tooltip += `<strong>${this.$t('result.deliverable')}</strong>`;

                    if (this.lead.deliverable_date) {
                        tooltip += `<br>${this.localizedDate}</strong>`;

                        if (!this.isInDates) {
                            tooltip += `<br><em>${this.$t('dashboards.notInDate')}</em>`;
                        }
                    }
                }
                return tooltip;
            },
        },

        watch: {
            'lead.deliverable_date': {
                immediate: true,
                handler() {
                    this.updateChecked();
                },
            },
        },

        methods: {
            ...mapActions(useGlobalStore, ['updateLeadAction', 'fetchLead']),

            onDateClick() {
                const toggleChecked = !this.checked;

                this.onClick(toggleChecked);
            },

            updateChecked() {
                this.checked = !!this.lead.deliverable_date;
            },

            onClick(toggleChecked = true) {
                if (this.disabled) {
                    return;
                }

                if (toggleChecked) {
                    this.checked = !this.checked;
                }

                this.openModal();
            },

            openModal() {
                if (this.checked) {
                    this.$modal.show('followUp', {
                        leadId: this.lead.id,
                        current: 'deliverable',
                        last: 'sale',
                    });
                } else {
                    this.uncheckModalOpened = true;
                }
            },

            triggerUncheck() {
                this.updateLeadAction(this.lead.id, {
                    deliverable: false,
                    deliverable_date: null,
                });
            },
        },

        created() {
            this.$eventBus.$on('cancel-follow-up', this.updateChecked);
        },

        mounted() {
            this.updateChecked();
        },

        beforeDestroy() {
            this.$eventBus.$off('cancel-follow-up', this.updateChecked);
        },
    };
</script>
