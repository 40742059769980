<template>
    <activix-tooltip :content="tooltip">
        <div class="input-wrapper">
            <activix-checkbox
                :disabled="disabled"
                :value="checked"
                :size="size"
                :cross="isCross"
                @mouseenter.native="disabled || fetchLead(lead.id)"
                @click.native.prevent="onClick"
            />

            <template v-if="inClientCard">
                <span class="editable | ml-4 | md:ml-2" :class="{ disabled: disabled }" @click="onDateClick">
                    {{ localizedDate || emptyValue }}
                </span>
            </template>
        </div>
    </activix-tooltip>
</template>

<script>
    import { mapActions, mapState } from 'pinia';

    import ActivixDate from '../../../../value-objects/ActivixDate.js';

    import FollowUpMixin from '../../../../mixins/FollowUpMixin.js';
    import { useGlobalStore } from '../../../../store/store.js';

    export default {
        mixins: [FollowUpMixin],

        props: {
            lead: {
                type: Object,
                required: true,
            },
            startDate: {
                type: ActivixDate,
                default: null,
            },
            endDate: {
                type: ActivixDate,
                default: null,
            },
            inClientCard: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            return {
                checked: false,
            };
        },

        computed: {
            ...mapState(useGlobalStore, ['authUser', 'guest']),

            date() {
                return new ActivixDate(this.lead.take_over_date);
            },

            localizedDate() {
                return this.date.toHumanShort(false);
            },

            isInDates() {
                return this.date.isBetween(this.startDate, this.endDate);
            },

            disabled() {
                if ((!this.lead.presented_date && !this.lead.home_presented_date) || this.lead.status == 'lost') {
                    return true;
                }

                if (this.authUser.id) {
                    if (!this.authUser.isAuthorizedToActionOnLead(this.lead)) {
                        return true;
                    }

                    if (
                        !this.authUser.isDirector() &&
                        !this.authUser.isAdmin() &&
                        this.lead.account.take_over_director
                    ) {
                        return true;
                    }

                    if (this.lead.disabledBySaleVerified(this.authUser)) {
                        return true;
                    }
                }

                if (!this.$can('leads.take_over_date:update')) {
                    return true;
                }

                return this.guest;
            },

            tooltip() {
                let tooltip = '';

                if (!this.inClientCard) {
                    tooltip += `<strong>${this.$t('result.takeOver')}</strong>`;

                    if (this.lead.take_over_date) {
                        tooltip += `<br>${this.localizedDate}`;

                        if (!this.isInDates) {
                            tooltip += `<br><em>${this.$t('dashboards.notInDate')}</em>`;
                        }
                    }

                    if (!this.lead.presented_date && !this.lead.home_presented_date) {
                        tooltip += `<br><em>${this.$t('clientCard.needPresentedChecked')}</em>`;
                    }
                }

                if (
                    this.lead.presented_date &&
                    this.lead.account.take_over_director &&
                    !this.authUser.isDirector() &&
                    !this.authUser.isAdmin()
                ) {
                    if (tooltip) {
                        tooltip += '<br>';
                    }

                    tooltip += `<em>${this.$t('clientCard.needToBeDirector')}</em>`;
                }

                if (this.inClientCard && this.lead.take_over_director && this.lead.take_over) {
                    if (tooltip) {
                        tooltip += '<br>';
                    }

                    tooltip += `<strong>${this.$t('clientCard.takeOverBy')}</strong> : ${
                        this.lead.take_over_director.fullName
                    }`;
                }

                if (this.lead.disabledBySaleVerified(this.authUser)) {
                    if (tooltip) {
                        tooltip += '<br>';
                    }
                    tooltip += `<em>${this.$t('clientCard.verifiedSaleUneditable')}</em>`;
                }

                return tooltip;
            },

            isCross() {
                if (this.inClientCard || !this.lead.take_over_date || this.isInDates) {
                    return false;
                }

                return true;
            },
        },

        watch: {
            'lead.take_over_date': {
                immediate: true,
                handler() {
                    this.updateChecked();
                },
            },
        },

        methods: {
            ...mapActions(useGlobalStore, ['updateLeadAction', 'fetchLead']),

            onDateClick() {
                const toggleChecked = !this.checked;

                this.onClick(toggleChecked);
            },

            updateChecked() {
                this.checked = !!this.lead.take_over_date;
            },

            onClick(toggleChecked = true) {
                if (this.disabled) {
                    return;
                }

                if (toggleChecked) {
                    this.checked = !this.checked;
                }

                if (this.checked) {
                    this.$modal.show('followUp', {
                        leadId: this.lead.id,
                        current: 'take_over',
                        last: null,
                    });
                } else {
                    this.removeProcess();
                }
            },

            removeProcess() {
                this.updateLeadAction(this.lead.id, {
                    take_over: false,
                    take_over_date: null,
                });
            },
        },

        created() {
            this.$eventBus.$on('cancel-follow-up', this.updateChecked);
        },

        mounted() {
            this.updateChecked();
        },

        beforeDestroy() {
            this.$eventBus.$off('cancel-follow-up', this.updateChecked);
        },
    };
</script>
