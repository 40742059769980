<template>
    <div>
        <activix-modal
            size="xs"
            portal="modal-2"
            :name="name"
            :loading="loading"
            ref="modal"
            @before-open="onBeforeOpen"
            @closed="onClosed"
        >
            <template slot="header">
                <div class="flex justify-center items-center modal-title">
                    <h4 class="modal-title" :class="{ 'mr-1': isPhone || isHomePresented || isHomeRoadTest }">
                        {{ modalTitle }}
                    </h4>
                    <icon :name="$icons.phoneAppointment" v-if="isPhone" />
                    <icon name="regular/house-chimney-2" v-if="isHomePresented || isHomeRoadTest" />
                </div>
            </template>

            <template slot="body">
                <div class="text-center -mt-4">
                    <!-- Dates -->
                    <div class="mt-4" :class="{ 'text-left': showDeliverable }" v-if="empty(enabledDates)">
                        <label class="mb-2" v-if="showDeliverable">{{ $t('date.availableDate') }}</label>
                        <date-time-picker :date-only="isDateOnly" :end-date="maxDate" v-model="followUpDate">
                            <template #before-button v-if="showRightSideButton">
                                <activix-tooltip :content="phoneTooltip" v-if="showPhone">
                                    <span class="w-12 attached-l attached-r">
                                        <activix-button
                                            class="h-full"
                                            :active="isPhone"
                                            :disabled="disabledPhoneIcon"
                                            @click="isPhone = !isPhone"
                                        >
                                            <icon :name="$icons.phoneAppointment" />
                                        </activix-button>
                                    </span>
                                </activix-tooltip>

                                <activix-button
                                    class="w-12 attached-l attached-r"
                                    :active="isHomePresented || isHomeRoadTest"
                                    @click="toogleHome"
                                    v-if="showHome"
                                >
                                    <icon name="regular/house-chimney-2" />
                                </activix-button>
                            </template>
                        </date-time-picker>
                    </div>
                    <div class="mt-4 text-left" v-if="showDeliverable">
                        <label class="mb-2 text-left">{{ $t('date.deliverableDate') }}</label>
                        <date-time-picker :date-only="isDateOnly" :end-date="maxDate" v-model="deliverableDate" />
                    </div>

                    <!-- Refinanced type -->
                    <div class="mt-4" v-if="canAutoRenew && showRefinancedType">
                        <activix-multiselect
                            label="text"
                            identifier="value"
                            :selected="refinancedType"
                            :options="refinancedTypes"
                            :searchable="false"
                            :allow-empty="false"
                            :multiple="false"
                            @update="updateSelectedRefinancedType"
                        />
                    </div>

                    <!-- Delivered by -->
                    <div class="mt-4" v-if="showDeliveredBy">
                        <activix-multiselect
                            label="text"
                            identifier="value"
                            :clearable="true"
                            :disabled="guestAndDeliveryMan"
                            :multiple="false"
                            :options="deliveryMen"
                            :placeholder="$t('clientCard.deliveredBy')"
                            :searchable="false"
                            :selected="deliveredBy"
                            @update="updateSelectedDeliveryMan"
                        />
                    </div>

                    <!-- NioText confirmation -->
                    <div class="flex mt-4" v-if="showConfirmation">
                        <div class="flex items-center rounded-l-sm bg-grey-200 px-3">
                            <activix-tooltip :content="sendConfirmationTooltip">
                                <activix-checkbox
                                    size="sm"
                                    class="flex"
                                    :disabled="!mobileNumber"
                                    v-model="sendConfirmation"
                                />
                            </activix-tooltip>
                        </div>

                        <nio-text-timeframe
                            class="flex-1"
                            :attached="true"
                            :timeframe="confirmationTimeFrame"
                            :disabled="!mobileNumber || !sendConfirmation"
                            @update="confirmationTimeFrame = $event"
                        />
                    </div>

                    <!-- ICS reminder -->
                    <activix-tooltip :content="$t('modal.icsTooltip')" v-if="showIcsInvite">
                        <div class="flex items-center mt-4">
                            <activix-checkbox
                                size="sm"
                                v-model="sendIcsInvite"
                            />
                            <span class="ml-2">
                                {{ $t('modal.icsInvite') }}
                            </span>
                        </div>
                    </activix-tooltip>

                    <!-- End contract date -->
                    <div class="mt-4" v-if="showEndContractDate">
                        <date-time-picker
                            :date-only="true"
                            :placeholder="$t('date.endContractDate')"
                            v-model="endContractDate"
                        />
                    </div>

                    <!-- Service prebooking -->
                    <div class="flex items-center mt-4" v-if="showCreatingPrebookingLead">
                        <activix-checkbox size="sm" v-model="creatingPrebookingLead">
                            {{ $t('modal.servicePrebooking') }}
                        </activix-checkbox>
                    </div>

                    <div class="mt-4" v-if="creatingPrebookingLead">
                        <date-time-picker :placeholder="$t('date.creatingPrebookingLeadDate')" v-model="creatingPrebookingLeadDate" />
                    </div>

                    <!-- Date choice -->
                    <div class="mt-4" v-if="!empty(enabledDates)">
                        <div class="whitespace-nowrap mb-2" :key="index" v-for="(date, index) in enabledDates">
                            <activix-radio name="enabledDates" :value="date.value" v-model="chosenDate">
                                {{ $t(`date.${date.name}`) }} - {{ date.value }}&nbsp;
                            </activix-radio>
                        </div>
                    </div>

                    <!-- Took over by -->
                    <div class="mt-4" v-if="showTookOverBy">
                        <activix-multiselect
                            label="text"
                            identifier="value"
                            :selected="takeOverDirector"
                            :options="activeDirectors"
                            :searchable="false"
                            :allow-empty="true"
                            :multiple="false"
                            :placeholder="$t('clientCard.chooseDirector')"
                            @update="updateSelectedDirector"
                        />
                    </div>
                </div>
            </template>

            <template slot="footer">
                <div class="flex items-center" :class="showTrash ? 'justify-between' : 'justify-center'">
                    <icon
                        :name="$icons.trash"
                        class="link-grey-light | hover:text-red-500"
                        @click="shouldOpenDeleteFollowUpModal"
                        v-if="showTrash"
                    />
                    <div>
                        <activix-button @click="close">
                            {{ $t('general.cancel') }}
                        </activix-button>

                        <activix-button type="primary" :disabled="disabledSave" @click="save">
                            {{ $t('general.save') }}
                        </activix-button>

                        <div class="followup-warning" v-if="!emptyDate && !validDate">
                            <span>{{ invalidDateText }}</span>
                        </div>
                    </div>
                </div>
            </template>
        </activix-modal>

        <activix-confirm-modal
            :content="deleteFollowupContent"
            :opened.sync="deleteFollowupModalOpenend"
            :title="$t('general.areYouSure')"
            type="error"
            @approve="deleteDate"
            @deny="deleteFollowupModalOpenend = false"
        />
    </div>
</template>

<script>
    import { clone, camelCase, get } from 'lodash-es';

    import { mapActions, mapState } from 'pinia';

    // Components
    import DateTimePicker from '../inputs/DateTimePicker.vue';
    import NioTextTimeframe from '../nio_text/NioTextTimeframe.vue';

    // Value Objects
    import Moment from '../../value-objects/Moment.js';
    import ActivixDate from '../../value-objects/ActivixDate.js';

    // Entities
    import ProgressState from '../../entities/ProgressState.js';
    import LeadType from '../../entities/LeadType.js';
    import Supplier from '../../entities/Supplier.js';
    import Division from '../../entities/Division.js';
    import Lead from '../../entities/Lead.js';

    // Pinia
    import { useDashboardStore } from '../../store/modules/dashboard/store.js';
    import { useGlobalStore } from '../../store/store.js';

    export default {
        components: {
            DateTimePicker,
            NioTextTimeframe,
        },

        props: {
            name: {
                type: String,
                required: true,
            },
        },

        data() {
            return {
                lead: new Lead(),
                loading: true,
                isPhone: false,
                isHomePresented: false,
                isHomeRoadTest: false,
                followUpDate: '',
                endContractDate: '',
                manualTaskeOverDirectorEmpty: false,
                chosenDate: '',
                deliverableDate: '',
                deliveredById: null,
                refinancedType: null,
                refinancedTypes: [
                    {
                        value: 'financed',
                        text: this.$t('renewal.financed'),
                    },
                    {
                        value: 'cash',
                        text: this.$t('renewal.cash'),
                    },
                ],
                sendConfirmation: false,
                sendIcsInvite: false,
                deleteFollowupModalOpenend: false,
                confirmationTimeFrame: {
                    when: '24_hours_before',
                    time: null,
                },
                state: {
                    current: null,
                    last: null,
                },
                showPhoneIcon: false,
                hideTrash: false,
                creatingPrebookingLead: false,
                creatingPrebookingLeadDate: '',
            };
        },

        computed: {
            ...mapState(useGlobalStore, ['authUser', 'guest', 'configs', 'resultDetails', 'activeUsers', 'activeDirectors']),
            ...mapState(useDashboardStore, ['dashboardType']),

            canAutoRenew() {
                return this.lead.canAutoRenew();
            },

            dateFunction() {
                return this.isDateOnly ? this.localeDate : this.locale_dt;
            },

            emptyDate() {
                return this.followUpDate === '';
            },

            phoneTooltip() {
                if (this.state.current == 'sale') {
                    if (!this.lead.presented_date) {
                        return this.$t('clientCard.needPresentedChecked');
                    }

                    if (this.lead.delivered_date && this.lead.lead_type_id != LeadType.RENEWAL) {
                        return this.$t('clientCard.needDeliveredUnchecked');
                    }

                    return this.$t('modal.saleByPhoneTooltip');
                }

                return this.$t('modal.appointmentCallTooltip');
            },

            validDate() {
                return (
                    !this.emptyDate &&
                    this.followUpDate &&
                    (!this.maxDate || this.dateFunction(this.followUpDate).isSameOrBefore(this.maxDate))
                );
            },

            guestAndDeliveryMan() {
                return this.guest && this.lead.hasDeliveryMan(this.authUser.id);
            },

            maxDate() {
                if (
                    [
                        'appt_confirmation',
                        'presented',
                        'home_presented',
                        'sale',
                        'delivered',
                        'csi',
                        'road_test',
                        'home_road_test',
                        'take_over',
                        'be_back',
                    ].includes(this.state.current)
                ) {
                    return now().endOfDay().toString();
                }

                if (this.state.current == 'appt_call') {
                    const appointment = new ActivixDate(this.lead.appointment_date);
                    return appointment.endOfDay().toString();
                }

                return null;
            },

            validDateEndContract() {
                return !!this.endContractDate;
            },

            currentDate() {
                switch (this.state.current) {
                    case 'recorded':
                        return this.soldOrFirstWantedVehicle.recorded_date || '';

                    default:
                        return this.lead[`${this.state.current}_date`] || '';
                }
            },

            deleteFollowupContent() {
                const followUpContent = `modal.followUpDate.${this.state.current}`;

                return this.state.current ? this.$t('modal.deleteFollowUp', [this.$t(followUpContent)]) : '';
            },

            enabledDates() {
                const enabledDates = [];
                const presentedDate = new ActivixDate(this.lead.presented_date);
                const beBackDate = new ActivixDate(this.lead.be_back_date);

                if (this.state.current == 'take_over') {
                    if (presentedDate.isNotEmpty()) {
                        enabledDates.push({
                            name: 'presentedDate',
                            value: presentedDate.toHumanShort(false),
                        });
                    }

                    if (beBackDate.isNotEmpty() && !beBackDate.isSameDay(presentedDate)) {
                        enabledDates.push({
                            name: 'beBackDate',
                            value: beBackDate.toHumanShort(false),
                        });
                    }
                }

                return enabledDates;
            },

            initialDate() {
                if (this.currentDate) {
                    return this.currentDate;
                }

                let initialDate = '';

                switch (this.state.current) {
                    case 'presented':
                    case 'home_presented':
                    case 'road_test':
                    case 'home_road_test':
                        initialDate = this.currentDate;

                        if (!initialDate) {
                            initialDate = this.lead.appointment_date;
                        }

                        break;

                    case 'delivered':
                        initialDate = this.lead.delivered_date;

                        if (!initialDate) {
                            initialDate = this.lead.delivery_date;
                        }

                        if (!initialDate) {
                            initialDate = this.lead.sale_date;
                        }

                        break;

                    case 'sale':
                        if (!this.state.last) {
                            initialDate = now().toString();
                        } else if (this.lead.be_back_date) {
                            initialDate = this.lead.be_back_date;
                        } else if (this.lead.presented_date) {
                            initialDate = this.lead.presented_date;
                        }

                        break;

                    case 'take_over':
                        initialDate = this.lead.presented_date;

                        break;

                    case 'be_back':
                        if (this.lead.presented_date) {
                            initialDate = now().toString();
                        }

                        break;

                    default:
                        initialDate = this.currentDate;
                        break;
                }

                if (!initialDate && this.defaultDate) {
                    initialDate = (this.isDateOnly ? now_date() : now()).toString();
                }

                return initialDate;
            },

            isDateOnly() {
                return get(this.configs.dateCasts, `${this.state.current}_date`) == 'date';
            },

            defaultDate() {
                return get(this.resultDetails, `${camelCase(this.state.current)}.default`);
            },

            hasAllPhonesValidated() {
                return !this.lead.lead_phones.find(p => !p.validated);
            },

            hasCashModality() {
                if (!empty(this.soldOrFirstWantedVehicle)) {
                    return this.soldOrFirstWantedVehicle.modality == 'cash';
                }

                return false;
            },

            hasEndContractDate() {
                if (this.endContractDate) {
                    return true;
                }

                if (!empty(this.soldOrFirstWantedVehicle)) {
                    return !!this.soldOrFirstWantedVehicle.end_contract_date;
                }

                return false;
            },

            mobileNumber() {
                return this.lead.lead_phones.find(p => p.validated && p.mobile);
            },

            soldOrFirstWantedVehicle() {
                return this.lead.getSoldOrFirstWantedVehicle() || {};
            },

            deliveredBy: {
                get() {
                    const user = this.activeUsers.find(tmpUser => {
                        if (!this.deliveredById) {
                            return tmpUser.id == this.lead.account.default_deliveryman_user_id;
                        }

                        return tmpUser.id == this.deliveredById;
                    });

                    if (empty(user)) {
                        return {
                            value: null,
                            text: this.$t('clientCard.chooseDeliveryMan'),
                        };
                    }

                    return {
                        value: user.id,
                        text: this.getFullName(user),
                    };
                },
            },

            deliveryMen() {
                const tempUsers = [];

                if (this.guest && this.lead.hasDeliveryMan(this.authUser.id)) {
                    tempUsers.push({ value: this.authUser.id, text: this.authUser.fullName });
                    return tempUsers;
                }

                for (const deliveryMan of this.activeUsers) {
                    tempUsers.push({ value: deliveryMan.id, text: this.getFullName(deliveryMan) });
                }

                return tempUsers;
            },

            takeOverDirector() {
                let userToFind = this.lead.take_over_director_id;

                if (
                    empty(this.lead.take_over_director_id) &&
                    this.authUser.isDirector() &&
                    !this.manualTaskeOverDirectorEmpty
                ) {
                    userToFind = this.authUser.id;
                }

                const user = this.activeUsers.find(tmpUser => {
                    return tmpUser.id == userToFind && tmpUser.isDirector();
                });

                if (empty(user) || empty(userToFind)) {
                    return {
                        value: '',
                        text: this.$t('clientCard.chooseDirector'),
                    };
                }

                return {
                    value: user.id,
                    text: this.getFullName(user),
                };
            },

            /*
             * Layout
             */

            modalTitle() {
                if (!this.state.current) {
                    return '';
                }

                if (this.state.current == 'sale') {
                    if (this.lead.lead_type_id == LeadType.RENEWAL) {
                        return this.$t('date.renewalDate');
                    }
                }

                if (this.isPhone && ['appointment', 'appt_call'].includes(this.state.current)) {
                    return this.$t('date.apptCallDate');
                }

                if (this.state.current == 'appt_call') {
                    return this.$t('date.appointmentDate');
                }

                if (this.state.current == 'home_presented') {
                    return this.$t('date.presentedDate');
                }

                if (this.state.current == 'home_road_test') {
                    return this.$t('date.roadTestDate');
                }

                if (this.state.current == 'available') {
                    return this.$t('date.available');
                }

                return this.$t(`date.${camelCase(this.state.current)}Date`);
            },

            isVisit() {
                return ['home_presented', 'presented'].includes(this.state.current);
            },

            isRoadTest() {
                return ['home_road_test', 'road_test'].includes(this.state.current);
            },

            showHome() {
                return !this.lead.isService() && (this.isRoadTest || this.isVisit);
            },

            showPhone() {
                return ['appointment', 'appt_call', 'sale'].includes(this.state.current) && this.showPhoneIcon;
            },

            showRightSideButton() {
                return this.showPhone || this.showHome;
            },

            disabledPhoneIcon() {
                if (['appointment', 'appt_call'].includes(this.state.current)) {
                    return !!this.lead.appointment_date && !!this.lead.appt_call_date;
                }

                if (!this.lead.presented_date && !this.lead.home_presented_date) {
                    return true;
                }

                if (this.state.current == 'sale' && this.state.last === null) {
                    return true;
                }

                if (this.lead.delivered_date && this.lead.lead_type_id != LeadType.RENEWAL) {
                    return true;
                }

                return this.lead.sale_by_phone;
            },

            showRefinancedType() {
                return this.state.current == 'refinanced' && empty(this.enabledDates);
            },

            showDeliverable() {
                return this.state.current == 'available' && this.lead.account.hasProcessField('deliverable');
            },

            showCreatingPrebookingLead() {
                return this.state.current == 'delivered' && this.lead.account.service && !this.currentDate;
            },

            showDeliveredBy() {
                const deliveredByFollowUp = ['delivery', 'delivered'];

                return deliveredByFollowUp.includes(this.state.current) && !empty(this.activeUsers);
            },

            showConfirmation() {
                if (this.lead.account.suppliers) {
                    const accountHasSupplierImportService = this.lead.account.suppliers.some(supplier => {
                        return Supplier.isSupplierImportService(supplier.id);
                    });

                    if (
                        accountHasSupplierImportService &&
                        this.confirmationEnabled &&
                        !this.lead.appointment_date &&
                        this.lead.division_id == Division.SERVICE &&
                        this.lead.account.confirmation_appt_service
                    ) {
                        return true;
                    }
                }

                const settingAppointment = this.state.current == 'appointment' && !this.lead.appointment_date;
                const settingDelivery = this.state.current == 'delivery' && !this.lead.delivery_date;

                return (
                    this.confirmationEnabled &&
                    (settingAppointment || settingDelivery) &&
                    this.lead.division_id != Division.SERVICE &&
                    this.lead.account.confirmation_appt_sale
                );
            },

            showIcsInvite() {
                return this.state.current == 'appointment' && !this.lead.appointment_date && (this.lead.user_id || this.lead.bdc_user_id || !this.authUser.isAdmin());
            },

            confirmationEnabled() {
                return (
                    ['appointment', 'delivery'].includes(this.state.current) &&
                    this.authUser.hasNioText() &&
                    this.lead.account.hasNioText()
                );
            },

            showEndContractDate() {
                return (
                    this.state.current == 'delivered' &&
                    this.authUser.end_contract_required &&
                    (!this.hasEndContractDate || !this.soldOrFirstWantedVehicle.end_contract_date)
                );
            },

            showTrash() {
                return (
                    !this.hideTrash &&
                    this.lead[`${this.state.current}_date`] != null &&
                    this.lead[`${this.state.current}_date`] != ''
                );
            },

            showTookOverBy() {
                return this.state.current == 'take_over' && !empty(this.activeDirectors);
            },

            disabledSave() {
                return (
                    !this.followUpDate ||
                    !this.validDate ||
                    this.isInvalidRefinanced() ||
                    this.isInvalidTakeOver() ||
                    this.isInvalidDeliveredEndContract() ||
                    this.missingCreatingPrebookingLeadDate
                );
            },

            /*
             * Tooltips
             */

            sendConfirmationTooltip() {
                if (!this.mobileNumber) {
                    return this.$t('modal.confirmationDisabled');
                }

                return this.$t('modal.sendConfirmation');
            },

            invalidDateText() {
                if (
                    [
                        'appointment',
                        'appt_call',
                        'refinanced',
                        'recorded',
                        'delivery',
                        'available',
                        'discounted',
                    ].includes(this.state.current)
                ) {
                    return this.$t('followUp.invalidDate');
                }

                return this.$t('followUp.invalidDateBefore');
            },

            missingCreatingPrebookingLeadDate() {
                return this.state.current == 'delivered' && this.creatingPrebookingLead && !this.creatingPrebookingLeadDate;
            },
        },

        watch: {
            chosenDate(newValue) {
                this.followUpDate = this.dateFunction(newValue, Moment.humanShortLocalizedFormat(this.isDateOnly)).toString();
            },

            initialDate(newValue) {
                const initialDate = this.dateFunction(newValue);
                const matchingDate = this.enabledDates.find(date => date.value == initialDate.humanShort());

                if (matchingDate) {
                    this.chosenDate = matchingDate.value;
                }
            },

            'confirmationTimeFrame.when'(newValue) {
                if (newValue && !this.sendConfirmation) {
                    this.sendConfirmation = true;
                    return;
                }

                if (!newValue) {
                    this.sendConfirmation = false;
                }
            },

            sendConfirmation(newValue, oldValue) {
                if (newValue && !oldValue) {
                    this.confirmationTimeFrame = clone(this.authUser.confirmation_timeframe);
                }
            },
        },

        methods: {
            ...mapActions(useGlobalStore, [
                'fetchLead',
                'addLeadVehicleAction',
                'updateLeadAction',
                'updateLeadVehicleAction',
                'updateLeadPhone',
                'appendNewError',
            ]),

            toogleHome() {
                if (this.isRoadTest) {
                    this.isHomeRoadTest = !this.isHomeRoadTest;
                }

                if (this.isVisit) {
                    this.isHomePresented = !this.isHomePresented;
                }
            },

            init() {
                if (this.state.current) {
                    if (this.state.current == 'appt_call') {
                        this.isPhone = true;
                    } else if (this.state.current == 'sale') {
                        if (!this.lead.account.sale_by_phone || this.lead.lead_type_id == LeadType.RENEWAL) {
                            this.isPhone = false;
                        } else if (
                            (!this.lead.sale_date &&
                                !this.lead.presented_date &&
                                !this.lead.home_presented_date &&
                                !this.lead.sale_by_phone) ||
                            this.state.last === null
                        ) {
                            this.isPhone = true;
                        } else {
                            this.isPhone = this.lead.sale_by_phone;
                        }
                    } else if (['delivery', 'delivered'].includes(this.state.current)) {
                        this.deliveredById = this.lead.delivered_by_id;

                        if (!this.deliveredById && (this.lead.delivery_date || this.lead.delivered_date)) {
                            this.deliveredById = -1;
                        }
                    } else if (this.state.current === 'home_presented') {
                        this.isHomePresented = true;
                    } else if (this.state.current === 'home_road_test') {
                        this.isHomeRoadTest = true;
                    }

                    this.followUpDate = this.initialDate;
                    this.deliverableDate = this.lead.deliverable_date;
                    this.endContractDate = this.soldOrFirstWantedVehicle.endContractDate;

                    if (this.state.current == 'refinanced' && !empty(this.lead.refinanced_type)) {
                        this.refinancedType = {
                            value: this.lead.refinanced_type,
                            text: this.$t(`renewal.${this.lead.refinanced_type}`),
                        };
                    }
                }
            },

            shouldOpenDeleteFollowUpModal() {
                const displayModalOn = [
                    'recorded',
                    'discounted',
                    'delivered',
                    'delivery',
                    'sale',
                    'refinanced',
                    'available',
                    'paperwork',
                    'presented',
                    'home_presented',
                ];

                if (displayModalOn.includes(this.state.current)) {
                    this.deleteFollowupModalOpenend = true;
                } else {
                    if (this.state.current === 'appointment') {
                        this.$eventBus.$emit('open-cancel-appointment');
                    } else if (this.state.current === 'appt_call') {
                        this.$eventBus.$emit('open-cancel-phone-appointment');
                    } else {
                        this.deleteDate();
                    }

                    this.close();
                }
            },

            toLowerCase(dateType) {
                return `${dateType.charAt(0).toLowerCase()}${dateType.slice(1)}`;
            },

            deleteDate() {
                if (this.state.current == 'recorded') {
                    const vehicleData = {
                        type: 'wanted',
                        recorded_date: null,
                    };

                    this.updateLeadVehicleAction(vehicleData, this.soldOrFirstWantedVehicle.id);
                } else if (this.state.current == 'discounted') {
                    this.updateLeadAction(this.lead.id, { discounted_date: null });
                } else {
                    const data = {};

                    data[this.state.current] = false;
                    data[`${this.state.current}_date`] = null;

                    if (this.state.current == 'take_over') {
                        this.lead.take_over_director_id = null;
                    }

                    if (this.state.current == 'delivery') {
                        data.delivered_by_id = null;
                    }

                    if (this.state.current == 'home_road_test') {
                        delete data.home_road_test;
                    }

                    if (this.state.current == 'available') {
                        data.deliverable = false;
                        data.deliverable_date = null;
                    }

                    this.updateLeadAction(this.lead.id, data);
                }

                this.close();
            },

            updateSelectedRefinancedType(value) {
                this.refinancedType = value;
            },

            updateSelectedDeliveryMan(value) {
                this.deliveredById = !empty(value) ? value.value : -1;
            },

            updateSelectedDirector(value) {
                this.lead.take_over_director_id = !empty(value) ? value.value : null;
                this.manualTaskeOverDirectorEmpty = true;
            },

            resetConfirmation() {
                if (this.showConfirmation) {
                    this.confirmationTimeFrame = clone(this.authUser.confirmation_timeframe);
                }

                if (this.mobileNumber && this.mobileNumber.valid == true) {
                    this.sendConfirmation = true;
                } else {
                    this.sendConfirmation = false;
                }
            },

            resetIcs() {
                this.sendIcsInvite = false;
            },

            updateRecordedDate(recordedDate) {
                const vehicleData = {
                    type: 'wanted',
                    recorded_date: recordedDate,
                };

                if (this.lead.progress_state == ProgressState.CASH_DEAL) {
                    vehicleData.modality = 'cash';
                }

                if (this.lead.sale_date) {
                    vehicleData.sold = true;
                }

                if (empty(this.soldOrFirstWantedVehicle)) {
                    this.addLeadVehicleAction(vehicleData, this.lead.id);
                } else {
                    this.updateLeadVehicleAction(vehicleData, this.soldOrFirstWantedVehicle.id);
                }
            },

            async validatePhones() {
                if (!this.confirmationEnabled || this.mobileNumber || this.hasAllPhonesValidated) {
                    return;
                }

                try {
                    const response = await this.$axios.post(`v1/leads/${this.lead.id}/validate-phones`);

                    response.data.data.lead_phones.forEach(leadPhone => {
                        this.updateLeadPhone(leadPhone);
                    });
                } catch (error) {
                    this.appendNewError({
                        code: '0109',
                        display: true,
                        error,
                        request: {
                            id: this.lead.id,
                        },
                    });
                }
            },

            save() {
                // Save Lead
                const data = {};
                const mainEventData = {};

                if (this.state.onApproveCallback) {
                    this.state.onApproveCallback();
                }

                if (this.state.current == 'recorded') {
                    this.updateRecordedDate(this.followUpDate);
                } else if (this.state.current == 'discounted') {
                    data[`${this.state.current}_date`] = this.followUpDate;
                    this.updateLeadAction(this.lead.id, data, {});
                } else {
                    data[this.state.current] = true;
                    data[`${this.state.current}_date`] = this.followUpDate;

                    if (this.showDeliverable) {
                        data.deliverable = true;
                        data.deliverable_date = this.deliverableDate;
                    }

                    if (this.sendConfirmation && this.showConfirmation && this.mobileNumber) {
                        mainEventData.confirmation_timeframe = this.confirmationTimeFrame;
                    }

                    if (this.sendIcsInvite && this.showIcsInvite) {
                        mainEventData.send_ics = this.sendIcsInvite;
                    }

                    if (this.isVisit) {
                        if (this.isHomePresented) {
                            data.home_presented = true;
                            data.home_presented_date = this.followUpDate;
                            data.presented = false;
                            data.presented_date = null;
                        } else {
                            data.presented = true;
                            data.presented_date = this.followUpDate;
                            data.home_presented = false;
                            data.home_presented_date = null;
                        }
                    }

                    if (this.isRoadTest) {
                        delete data.home_road_test;

                        if (this.isHomeRoadTest) {
                            data.home_road_test_date = this.followUpDate;
                            data.road_test = false;
                            data.road_test_date = null;
                        } else {
                            data.road_test = true;
                            data.road_test_date = this.followUpDate;
                            data.home_road_test_date = null;
                        }
                    }

                    if (this.state.current == 'appointment') {
                        if (this.isPhone) {
                            data.appt_call = this.isPhone;
                            data.appt_call_date = this.followUpDate;
                            data.appointment = false;
                            data.appointment_date = null;
                        } else {
                            data.appointment = true;
                            data.appointment_date = this.followUpDate;
                        }
                    }

                    if (this.state.current == 'appt_call') {
                        if (this.isPhone) {
                            data.appt_call = this.isPhone;
                            data.appt_call_date = this.followUpDate;
                        } else {
                            data.appt_call = false;
                            data.appt_call_date = null;
                            data.appointment = true;
                            data.appointment_date = this.followUpDate;
                        }
                    }

                    if (this.state.current == 'delivered' && !this.lead.delivery_date) {
                        data.delivery_date = this.followUpDate;
                    }

                    if (this.showDeliveredBy) {
                        data.delivered_by_id = this.deliveredBy.value;

                        this.lead.delivered_by = {
                            id: this.deliveredBy.value,
                            first_name: '',
                            last_name: this.deliveredBy.text,
                        };
                    }

                    if (this.state.current == 'take_over' && !empty(this.takeOverDirector)) {
                        data.take_over_director_id = this.takeOverDirector.value;

                        this.lead.take_over_director = {
                            id: this.takeOverDirector.value,
                            first_name: '',
                            last_name: this.takeOverDirector.text,
                        };
                    }

                    if (this.state.current == 'sale' && this.isPhone) {
                        data.sale_by_phone = true;
                        data.sale = false;
                    }

                    if (this.state.current == 'sale') {
                        this.$eventBus.$emit('make-confetti-sale');
                    }

                    if (this.state.current == 'refinanced' && !empty(this.refinancedType)) {
                        data.refinanced_type = this.refinancedType.value;
                    }

                    this.manualTaskeOverDirectorEmpty = false;

                    if (
                        this.state.current == 'sale' &&
                        !empty(this.soldOrFirstWantedVehicle) &&
                        !this.soldOrFirstWantedVehicle.sold
                    ) {
                        this.updateLeadVehicleAction({ sold: true }, this.soldOrFirstWantedVehicle.id);
                    }

                    if (this.state.current == 'delivered' && this.validDateEndContract) {
                        if (!empty(this.soldOrFirstWantedVehicle)) {
                            this.updateLeadVehicleAction(
                                {
                                    sold: true,
                                    end_contract_date: this.endContractDate,
                                },
                                this.soldOrFirstWantedVehicle.id,
                            );
                        }
                    }

                    if (this.state.current == 'delivered' && this.creatingPrebookingLead && this.creatingPrebookingLeadDate) {
                        this.createPrebookingLead();
                    }

                    this.updateLeadAction(this.lead.id, data, { mainEvent: mainEventData });
                }

                this.close();
            },

            removeDateFromUrl() {
                if (this.$route.query?.date) {
                    const { date, ...query } = this.$route.query;

                    this.followUpDate = date;
                    this.$router.replace({ query });
                }
            },

            isInvalidRefinanced() {
                return this.state.current == 'refinanced' && this.canAutoRenew && empty(this.refinancedType);
            },

            isInvalidTakeOver() {
                return (
                    this.state.current == 'take_over' &&
                    this.lead.account.take_over_director &&
                    empty(this.takeOverDirector) &&
                    !empty(this.activeUsers)
                );
            },

            isInvalidDeliveredEndContract() {
                return (
                    this.state.current == 'delivered' &&
                    this.authUser.end_contract_required &&
                    !this.hasEndContractDate &&
                    !this.soldOrFirstWantedVehicle.end_contract_date &&
                    !this.hasCashModality
                );
            },

            async onBeforeOpen({ leadId, current, last, showPhoneIcon = false, hideTrash = false, onApproveCallback = undefined }) {
                this.lead = await this.fetchLead(leadId);

                this.state.current = current;
                this.state.last = last;
                this.state.onApproveCallback = onApproveCallback;
                this.showPhoneIcon = showPhoneIcon;
                this.hideTrash = hideTrash;

                this.onOpen();
                this.loading = false;
            },

            onOpen() {
                this.removeDateFromUrl();
                this.resetConfirmation();
                this.resetIcs();
                this.validatePhones();
                this.init();
            },

            close() {
                this.$refs.modal.hide();
            },

            onClosed() {
                this.$eventBus.$emit('cancel-follow-up');
                this.isPhone = false;
                this.isHomePresented = false;
                this.isHomeRoadTest = false;
                this.lead = new Lead();
                this.loading = true;
                this.showPhoneIcon = false;
                this.hideTrash = false;
                this.state.current = null;
                this.state.last = null;
                this.state.onApproveCallback = null;
            },

            async createPrebookingLead() {
                const payload = {
                    lead_id: this.lead.id,
                    start_at: this.creatingPrebookingLeadDate,
                };

                try {
                    const response = await this.$api.leads.createPrebooking(payload);
                    const serviceLead = response.data.data;

                    if (serviceLead) {
                        this.$notify.success(
                            this.$t('toastr.prebookingLeadCreated'),
                        );
                    }
                    this.creatingPrebookingLead = false;
                } catch (error) {
                    if (error.response && error.response.status === 403) {
                        return;
                    }

                    this.appendNewError({
                        code: '0107',
                        display: true,
                        error,
                        payload,
                    });
                }
            },
        },
    };
</script>
