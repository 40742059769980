<template>
    <activix-tooltip :content="tooltip">
        <div class="input-wrapper">
            <activix-checkbox
                :disabled="disabled"
                :value="checked"
                :size="size"
                :color="color"
                :cross="isCross"
                @mouseenter.native="disabled || fetchLead(lead.id)"
                @click.native.prevent="onClick"
            />

            <template v-if="inClientCard">
                <span class="editable | ml-4 | md:ml-2" :class="{ disabled: disabled }" @click="onDateClick">
                    {{ localizedDate || emptyValue }}
                </span>
            </template>

            <activix-confirm-modal
                :approve-text="$t('dashboards.removeIt')"
                :content="$t('dashboards.deleteFollowUpConfirmation', [$t('dashboards.performance.appointment')])"
                :deny-text="$t('general.cancel')"
                :opened.sync="uncheckModalOpened"
                :title="$t('general.areYouSure')"
                type="error"
                @approve="triggerUncheck"
                @deny="updateChecked"
            />
        </div>
    </activix-tooltip>
</template>

<script>
    import { mapActions, mapState } from 'pinia';

    import ActivixDate from '../../../../value-objects/ActivixDate.js';
    import TaskEventType from '../../../../entities/TaskEventType.js';
    import Division from '../../../../entities/Division.js';
    import LeadType from '../../../../entities/LeadType.js';

    import FollowUpMixin from '../../../../mixins/FollowUpMixin.js';
    import { useGlobalStore } from '../../../../store/store.js';

    export default {
        mixins: [FollowUpMixin],

        props: {
            lead: {
                type: Object,
                required: true,
            },
            startDate: {
                type: ActivixDate,
                default: null,
            },
            endDate: {
                type: ActivixDate,
                default: null,
            },
            inClientCard: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            return {
                checked: false,
                uncheckModalOpened: false,
            };
        },

        computed: {
            ...mapState(useGlobalStore, ['authUser', 'guest']),

            date() {
                return new ActivixDate(this.lead.appointment_date);
            },

            localizedDate() {
                return this.date.toHumanShort();
            },

            isInDates() {
                return this.date.isBetween(this.startDate, this.endDate);
            },

            mainEvent() {
                if (!this.lead || !this.lead.task_events) {
                    return null;
                }

                return this.lead.task_events.find(taskEvent => {
                    return taskEvent.task_event_type_id == TaskEventType.APPOINTMENT && taskEvent.main_event;
                });
            },

            color() {
                if (this.lead.appointment_date && this.mainEvent && this.mainEvent.no_show) {
                    return 'orange';
                }

                if (this.mainEvent && this.mainEvent.canceled) {
                    return 'red';
                }

                if (this.mainEvent && this.mainEvent.confirmed) {
                    return 'green';
                }

                return 'blue';
            },

            disabled() {
                if (this.lead.lead_type_id == LeadType.WALK_IN) {
                    return true;
                }

                if (['invalid', 'lost', 'duplicate'].includes(this.lead.status)) {
                    return true;
                }

                if (this.lead.sale_date && !this.lead.sale_by_phone) {
                    return true;
                }

                if (this.lead.presented_date || this.lead.home_presented_date) {
                    return true;
                }

                if (this.lead.division_id == Division.SERVICE && this.lead.appt_confirmation_date) {
                    return true;
                }

                if (!this.$can('leads.appointment_date:update')) {
                    return true;
                }

                if (this.lead.disabledBySaleVerified(this.authUser)) {
                    return true;
                }

                return this.guest;
            },

            isCross() {
                if (this.inClientCard || !this.lead.appointment_date || this.isInDates) {
                    return false;
                }

                return true;
            },

            tooltip() {
                let tooltip = '';

                if (!this.inClientCard) {
                    tooltip += `<strong>${this.$t('result.appointment')}</strong>`;
                }

                let disabledTooltip = '';

                if (this.lead.lead_type_id == LeadType.WALK_IN) {
                    disabledTooltip += this.$t('result.leadIsWalkIn');
                } else if (['invalid', 'lost', 'duplicate'].includes(this.lead.status)) {
                    disabledTooltip += `${this.$t(`clientCard.statusTooltip.${this.lead.status}`, [
                        this.$t('clientCard.statusTooltip.inputs.appointment'),
                    ])}`;
                } else if (this.lead.division_id == Division.SERVICE && this.lead.appt_confirmation_date) {
                    disabledTooltip += this.$t('result.leadIsServiceWithConfirmation');
                } else if (this.lead.disabledBySaleVerified(this.authUser)) {
                    disabledTooltip = this.$t('clientCard.verifiedSaleUneditable');
                } else if (this.guest) {
                    disabledTooltip += this.$t('clientCard.guestMode');
                }

                if (disabledTooltip) {
                    tooltip += tooltip ? '<br>' : '';
                    tooltip += `<em>${disabledTooltip}</em>`;
                    return tooltip;
                }

                if (!this.inClientCard) {
                    if (this.lead.appointment_date) {
                        tooltip += `<br>${this.localizedDate}`;

                        if (!this.isInDates) {
                            tooltip += `<br><em>${this.$t('dashboards.notInDate')}</em>`;
                        }
                    }
                }

                if (this.lead.appointment_date && this.mainEvent && this.mainEvent.no_show) {
                    if (tooltip) {
                        tooltip += '<br>';
                    }

                    tooltip += this.$t('modal.noShowTooltip');
                }

                if (this.lead.presented_date || this.lead.home_presented_date) {
                    if (tooltip) {
                        tooltip += '<br>';
                    }

                    tooltip += this.$t('clientCard.needPresentedUnchecked');
                }

                return tooltip;
            },
        },

        watch: {
            'lead.appointment_date': {
                immediate: true,
                handler() {
                    this.updateChecked();
                },
            },
        },

        methods: {
            ...mapActions(useGlobalStore, ['updateLeadAction', 'fetchLead']),

            onDateClick() {
                const toggleChecked = !this.checked;

                this.onClick(toggleChecked);
            },

            updateChecked() {
                this.checked = !!this.lead.appointment_date;
            },

            onClick(toggleChecked = true) {
                if (this.disabled) {
                    return;
                }

                if (toggleChecked) {
                    this.checked = !this.checked;
                }

                this.openModal();
            },

            openModal() {
                if (this.checked) {
                    this.$modal.show('followUp', {
                        leadId: this.lead.id,
                        showPhoneIcon:
                            !this.lead.appt_call_date && this.inClientCard && this.lead.division_id != Division.SERVICE,
                        current: 'appointment',
                        last: null,
                    });
                } else if (this.mainEvent && !this.mainEvent.canceled && this.lead.account.confirmation_appt_sale) {
                    this.openCancelAppointmentModal();
                } else {
                    this.uncheckModalOpened = true;
                }
            },

            triggerUncheck() {
                this.updateLeadAction(this.lead.id, {
                    appointment: false,
                    appointment_date: null,
                });
            },

            openCancelAppointmentModal() {
                this.$modal.show('cancelAppointment', {
                    leadId: this.lead.id,
                    mainEventId: this.mainEvent.id,
                    onClose: this.updateChecked,
                });
            },
        },

        created() {
            this.$eventBus.$on('cancel-follow-up', this.updateChecked);
            this.$eventBus.$on('open-cancel-appointment', this.openCancelAppointmentModal);
        },

        mounted() {
            this.updateChecked();
        },

        beforeDestroy() {
            this.$eventBus.$off('cancel-follow-up', this.updateChecked);
            this.$eventBus.$off('open-cancel-appointment', this.openCancelAppointmentModal);
        },
    };
</script>
