<template>
    <activix-modal
        size="lg"
        :opened="opened"
        portal="modal-2"
        @close="close"
        @open="onOpen"
    >
        <template slot="header">
            <div class="flex items-center justify-center">
                <h4 class="modal-title">
                    {{ $t('client.leadxpressEmail') }}
                </h4>
                <button
                    class="link-blue text-xl ml-2"
                    :disabled="guest || !hasValidEmail"
                    @click="closeAndOpenEmailLeadModal(false)"
                >
                    <activix-tooltip :content="replyTooltip">
                        <icon name="bold/email-action-reply" />
                    </activix-tooltip>
                </button>
            </div>
        </template>
        <template slot="body">
            <email-accordion :lead="contextLead" parent="modal" />
        </template>
        <template slot="footer">
            <div class="flex justify-between">
                <div class="flex items-center">
                    <button
                        class="link-blue text-2xl ml-2"
                        :disabled="guest || !hasValidEmail"
                        @click="closeAndOpenEmailLeadModal(false)"
                    >
                        <activix-tooltip :content="replyTooltip">
                            <icon name="bold/email-action-reply" />
                        </activix-tooltip>
                    </button>
                </div>
                <activix-button @click="close">
                    {{ $t('general.close') }}
                </activix-button>
            </div>
        </template>
    </activix-modal>
</template>

<script>
    import EmailAccordion from '@/components/lead/EmailAccordion.vue';

    // Pinia
    import { mapState } from 'pinia';
    import { useClientCardStore } from '../../store/modules/clientCard/store.js';
    import { useContextStore } from '../../store/modules/context/store.js';
    import { useGlobalStore } from '../../store/store.js';

    export default {
        components: {
            EmailAccordion,
        },

        props: {
            opened: {
                type: Boolean,
                required: true,
            },
            communicationId: {
                type: Number,
                validator: value => (typeof value === 'number') || value === null,
            },
        },

        computed: {
            ...mapState(useContextStore, ['contextLead']),
            ...mapState(useGlobalStore, ['guest']),

            hasValidEmail() {
                const email = this.contextLead.lead_emails.find(tmpEmail => {
                    return tmpEmail.valid;
                });

                return !empty(email) && !this.contextLead.unsubscribe_email;
            },

            replyTooltip() {
                return this.contextLead.unsubscribe_email
                    ? this.$t('clientCard.clientIsEmailUnsubscribed')
                    : this.$t('client.reply');
            },
        },

        methods: {
            closeAndOpenEmailLeadModal(isForwarding = false) {
                useClientCardStore().replyingEmail = true;

                this.close();

                this.$nextTick(() => this.$eventBus.$emit('open-email-lead-modal', isForwarding));
            },

            close() {
                this.$emit('update:opened', false);
            },

            onOpen() {
                useClientCardStore().currentCommunicationId = this.communicationId;
            },
        },

        // @TODO: 2018-08-22 - This hasn't been optimized yet and is currently not working because #leadxpress-email-modal doesn't exist anymore
        mounted() {
            this.$eventBus.$on('closeAccordionModalAndOpenEmailLeadModal', this.closeAndOpenEmailLeadModal);

            this.$nextTick(() => {
                const panels = $('#leadxpress-email-modal .panel-collapse');

                panels.on('shown.bs.collapse', e => {
                    const panel = $(e.currentTarget);
                    if (!panel.hasClass('current-collapse')) {
                        return;
                    }

                    modal.animate(
                        {
                            scrollTop: panel.position().top,
                        },
                        'slow',
                    );

                    panel.removeClass('current-collapse');
                });
            });
        },

        beforeDestroy() {
            this.$eventBus.$off('closeAccordionModalAndOpenEmailLeadModal', this.closeAndOpenEmailLeadModal);

            $('#leadxpress-email-modal .panel-collapse').off('shown.bs.collapse');
        },
    };
</script>
