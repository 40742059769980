<template>
    <activix-tooltip :content="tooltip">
        <div class="input-wrapper">
            <activix-checkbox
                :disabled="disabled"
                :value="checked"
                :size="size"
                :color="color"
                :cross="isCross"
                @mouseenter.native="disabled || fetchLead(lead.id)"
                @click.native.prevent="onClick"
            />

            <template v-if="inClientCard">
                <span class="editable | ml-4 | md:ml-2" :class="{ disabled: disabled }" @click="onDateClick">
                    {{ localizedDate || emptyValue }}
                </span>
            </template>
        </div>
    </activix-tooltip>
</template>

<script>
    import { mapActions, mapState } from 'pinia';
    import { getIconMarkup } from '../../../../utils/icon.js';
    import ActivixDate from '../../../../value-objects/ActivixDate.js';

    import FollowUpMixin from '../../../../mixins/FollowUpMixin.js';
    import LeadStatus from '../../../../entities/LeadStatus.js';
    import { useGlobalStore } from '../../../../store/store.js';

    export default {
        mixins: [FollowUpMixin],

        props: {
            lead: {
                type: Object,
                required: true,
            },
            startDate: {
                type: ActivixDate,
                default: null,
            },
            endDate: {
                type: ActivixDate,
                default: null,
            },
            inClientCard: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            return {
                checked: false,
                uncheckModalOpened: false,
                visitWarningOpened: false,
                shouldOpenFollowUpModal: false,
            };
        },

        computed: {
            ...mapState(useGlobalStore, ['authUser', 'guest']),

            date() {
                if (this.lead.home_road_test_date) {
                    return new ActivixDate(this.lead.home_road_test_date);
                }

                return new ActivixDate(this.lead.road_test_date);
            },

            color() {
                return this.isHomeRoadTest ? 'purple' : 'blue';
            },

            localizedDate() {
                return this.date.toHumanShort(true);
            },

            isInDates() {
                return this.date.isBetween(this.startDate, this.endDate);
            },

            isHomeRoadTest() {
                return !!this.lead.home_road_test_date;
            },

            disabled() {
                if ([LeadStatus.INVALID, LeadStatus.LOST, LeadStatus.DUPLICATE].includes(this.lead.status)) {
                    return true;
                }

                if (this.authUser.id && !this.authUser.isAuthorizedToActionOnLead(this.lead)) {
                    return true;
                }

                if (!this.lead.presented_date && !this.lead.home_presented_date) {
                    return true;
                }

                if (!this.$can('leads.road_test_date:update')) {
                    return true;
                }

                if (this.lead.disabledBySaleVerified(this.authUser)) {
                    return true;
                }

                return this.guest;
            },

            isCross() {
                return !(this.inClientCard || (!this.lead.road_test_date && !this.lead.home_road_test_date) || this.isInDates);
            },

            tooltip() {
                let tooltip = '';

                if (this.inClientCard) {
                    return tooltip;
                }

                if (this.lead.road_test_date) {
                    tooltip += `<strong>${this.$t('date.roadTest')}</strong><br>`;
                }

                if (this.lead.home_road_test_date) {
                    tooltip += `
                        <div class="flex items-center justify-center">
                            <strong class="mr-1">${this.$t('result.roadTest')}</strong>
                            ${getIconMarkup('regular/house-chimney-2', { class: 'event-icon' })}
                        </div>
                    `;
                }

                tooltip += `${this.localizedDate}`;

                if (!this.isInDates) {
                    tooltip += `<br><em>${this.$t('dashboards.notInDate')}</em>`;
                }

                return tooltip;
            },
        },

        watch: {
            'lead.road_test_date': {
                immediate: true,
                handler() {
                    this.updateChecked();
                },
            },
        },

        methods: {
            ...mapActions(useGlobalStore, ['updateLeadAction', 'fetchLead']),

            onDateClick() {
                const toggleChecked = !this.checked;

                this.onClick(toggleChecked);
            },

            updateChecked() {
                this.checked = !!this.lead.road_test_date || !!this.lead.home_road_test_date;
            },

            onClick(toggleChecked = true) {
                if (this.disabled) {
                    return;
                }

                if (toggleChecked) {
                    this.checked = !this.checked;
                }

                if (this.checked) {
                    const presentedDate = new ActivixDate(this.lead.presented_date);

                    if (this.inClientCard && this.date.isEmpty() && presentedDate.isToday()) {
                        const data = {
                            road_test: true,
                            road_test_date: presentedDate.toString(),
                        };

                        this.updateLeadAction(this.lead.id, data);
                    } else {
                        this.$modal.show('followUp', {
                            leadId: this.lead.id,
                            current: this.isHomeRoadTest ? 'home_road_test' : 'road_test',
                            last: null,
                        });
                    }
                } else {
                    this.removeProcess();
                }
            },

            removeProcess() {
                this.updateLeadAction(this.lead.id, {
                    road_test: false,
                    road_test_date: null,
                    home_road_test_date: null,
                });
            },
        },

        created() {
            this.$eventBus.$on('cancel-follow-up', this.updateChecked);
        },

        mounted() {
            this.updateChecked();
        },

        beforeDestroy() {
            this.$eventBus.$off('cancel-follow-up', this.updateChecked);
        },
    };
</script>
