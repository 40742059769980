// Utils
import { get } from 'lodash-es';

import { mapState } from 'pinia';

// Entities
import Role from '../entities/Role.js';

// Pinia
import { useContextStore } from '../store/modules/context/store.js';
import { useGlobalStore } from '../store/store.js';

export default {

    computed: {
        ...mapState(useGlobalStore, ['authUser', 'activeUsers']),
        ...mapState(useContextStore, {
            contextLead: 'contextLead',
            contextAccount: 'account',
        }),

        url() {
            if (!this.communication.url) {
                return '';
            }

            if (!this.communication.url.endsWith('.wav') && !this.communication.url.endsWith('.mp3')) {
                return `${this.communication.url}.wav`;
            }

            return this.communication.url;
        },

        userHasAccessToMedia() {
            if (this.communication.created_by_user && !this.communication.created_by_api) {
                return false;
            }

            if (this.contextAccount.disable_communication_audio) {
                return false;
            }

            if (this.authUser.isAdmin()) {
                return true;
            }

            if (this.userHasLimitedAccessToAudio) {
                return false;
            }

            if (this.userCannotListenCommercialAudio) {
                return false;
            }

            return true;
        },

        userHasLimitedAccessToAudio() {
            const hasAccessToReceptionistCommunication =
                this.communication.receptionist && this.contextLead.user_id == this.authUser.id;

            const isAccountLimited =
                this.authUser.account.limited_audio_access &&
                this.authUser.isAdvisor() &&
                this.authUser.id != this.communication.user_id &&
                this.authUser.id != this.contextLead.user_id;

            const isUserLimited =
                get(this.authUser, 'custom_permissions.limit_audio_access') &&
                this.authUser.id != this.communication.user_id;

            return (isAccountLimited || isUserLimited) && !hasAccessToReceptionistCommunication;
        },

        userCannotListenCommercialAudio() {
            const commercialCommunication = this.activeUsers.find(
                user => user.role_id == Role.COMMERCIAL && user.id == this.communication.user_id,
            );

            return (
                this.authUser.id != this.communication.user_id &&
                !this.authUser.isAdmin() &&
                !this.authUser.isCommercialDirector() &&
                !this.authUser.isDirector() &&
                commercialCommunication
            );
        },
    },
};
