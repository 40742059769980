<template>
    <div>
        <template v-if="disabled">
            <div class="ui360 small disabled" :class="customClass">
                <div class="sm2-360ui">
                    <span class="sm2-360 sm2-360" />
                </div>
            </div>
        </template>
        <template v-else>
            <div class="ui360 small" :class="customClass" ref="player">
                <a :href="url" />
            </div>
            <div class="flex justify-center items-center mt-2" v-if="duration">
                <a
                    referrerpolicy="origin"
                    target="_blank"
                    class="flex items-center mr-1"
                    :download="fileName"
                    :href="url"
                    v-if="downloadable"
                >
                    <icon class="link-grey" name="regular/download-button" />
                </a>
                <div>{{ duration }}</div>
            </div>
        </template>
    </div>
</template>

<script>
    export default {
        name: 'ActivixPlayer',

        props: {
            customClass: String,
            url: String,
            duration: String,
            fileName: {
                type: String,
                default: 'audio.wav',
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            downloadable: {
                type: Boolean,
                default: false,
            },
        },

        watch: {
            disabled(newValue) {
                if (!newValue) {
                    this.init();
                } else {
                    this.teardown();
                }
            },
        },

        methods: {
            init() {
                if (this.disabled) {
                    return;
                }

                if (this.$refs.player) {
                    activixPlayer.init(this.$refs.player);
                }
            },

            teardown() {
                if (!this.url) {
                    return;
                }

                const sound = activixPlayer.getSoundByURL(this.url);

                if (sound) {
                    activixPlayer.stopSound(sound);
                    activixPlayer.remove(this.url);
                }
            },
        },

        mounted() {
            this.$nextTick(() => {
                this.init();
            });
        },

        beforeDestroy() {
            this.teardown();
        },
    };
</script>
