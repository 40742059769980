<template>
    <activix-tooltip :content="tooltip">
        <div class="input-wrapper">
            <activix-checkbox
                :disabled="disabled"
                :value="checked"
                :size="size"
                :cross="isCross"
                @mouseenter.native="disabled || fetchLead(lead.id)"
                @click.native.prevent="onClick"
            />

            <template v-if="inClientCard">
                <span class="editable | ml-4 | md:ml-2" :class="{ disabled: guest || disabled }" @click="onDateClick">
                    {{ localizedDate || emptyValue }}
                </span>
            </template>

            <activix-confirm-modal
                :approve-text="$t('dashboards.removeIt')"
                :content="$t('dashboards.deleteFollowUpConfirmation', [$t('dashboards.performance.refinanced')])"
                :deny-text="$t('general.cancel')"
                :opened.sync="uncheckModalOpened"
                :title="$t('general.areYouSure')"
                type="error"
                @approve="triggerUncheck"
                @deny="updateChecked"
            />
        </div>
    </activix-tooltip>
</template>

<script>
    import { camelCase } from 'lodash-es';
    import { mapActions, mapState } from 'pinia';

    import ActivixDate from '../../../../value-objects/ActivixDate.js';

    import FollowUpMixin from '../../../../mixins/FollowUpMixin.js';
    import { useGlobalStore } from '../../../../store/store.js';

    export default {
        mixins: [FollowUpMixin],

        props: {
            lead: {
                type: Object,
                required: true,
            },
            parentTooltip: {
                type: String,
                default: '',
            },
            startDate: {
                type: ActivixDate,
                default: null,
            },
            endDate: {
                type: ActivixDate,
                default: null,
            },
            inClientCard: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            return {
                checked: false,
                uncheckModalOpened: false,
            };
        },

        computed: {
            ...mapState(useGlobalStore, ['authUser', 'guest']),

            date() {
                return new ActivixDate(this.lead.refinanced_date);
            },

            localizedDate() {
                return this.date.toHumanShort(false);
            },

            isInDates() {
                return this.date.isBetween(this.startDate, this.endDate);
            },

            currentLeasedVehicle() {
                return this.lead.exchange_vehicles.find(v => v.modality == 'leasing');
            },

            disabled() {
                if ((this.lead.sale_date && !this.lead.refinanced_date) || !this.currentLeasedVehicle) {
                    return true;
                }

                if (['invalid', 'lost', 'duplicate'].includes(this.lead.status)) {
                    return true;
                }

                if (this.lead.disabledBySaleVerified(this.authUser)) {
                    return true;
                }

                return !this.$can('leads.refinanced:update');
            },

            isCross() {
                if (this.inClientCard || !this.lead.refinanced_date || this.isInDates) {
                    return false;
                }

                return true;
            },

            tooltip() {
                if (this.parentTooltip) {
                    return this.parentTooltip;
                }

                let tooltip = '';

                if (!this.inClientCard) {
                    tooltip += `<strong>${this.$t('date.refinanced')}</strong>`;

                    if (this.lead.refinanced_date) {
                        tooltip += `<br>${this.localizedDate}`;

                        if (!this.isInDates) {
                            tooltip += `<br><em>${this.$t('dashboards.notInDate')}</em>`;
                        }
                    }
                }

                if (this.lead.refinanced_date && !empty(this.lead.refinanced_type)) {
                    if (tooltip) {
                        tooltip += '<br>';
                    }

                    tooltip += `<em>${this.$t(`renewal.${camelCase(this.lead.refinanced_type)}`)}`;
                }

                if ((!this.lead.sale_date || this.lead.refinanced_date) && !this.currentLeasedVehicle) {
                    tooltip += tooltip ? '<br>' : '';
                    tooltip += `${this.$t('clientCard.refinancedTooltip')}`;
                }

                return tooltip;
            },
        },

        watch: {
            'lead.refinanced_date': {
                immediate: true,
                handler() {
                    this.updateChecked();
                },
            },
        },

        methods: {
            ...mapActions(useGlobalStore, ['updateLeadAction', 'fetchLead']),

            onDateClick() {
                const toggleChecked = !this.checked;

                this.onClick(toggleChecked);
            },

            updateChecked() {
                this.checked = !!this.lead.refinanced_date;
            },

            onClick(toggleChecked = true) {
                if (this.disabled) {
                    return;
                }

                if (toggleChecked) {
                    this.checked = !this.checked;
                }

                this.openModal();
            },

            openModal() {
                if (this.checked) {
                    this.$modal.show('followUp', {
                        leadId: this.lead.id,
                        current: 'refinanced',
                        last: 'presented',
                    });
                } else {
                    this.uncheckModalOpened = true;
                }
            },

            triggerUncheck() {
                this.updateLeadAction(this.lead.id, {
                    refinanced: false,
                    refinanced_date: null,
                });
            },
        },

        created() {
            this.$eventBus.$on('cancel-follow-up', this.updateChecked);
        },

        mounted() {
            this.updateChecked();
        },

        beforeDestroy() {
            this.$eventBus.$off('cancel-follow-up', this.updateChecked);
        },
    };
</script>
