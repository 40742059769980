<template>
    <activix-modal :opened="opened" size="xs" @closed="onClose">
        <div slot="header">
            <h4 class="modal-title | text-center">
                {{ $t('modal.titleAddRenew') }}

                <button type="button" @click="onClose" />
            </h4>
        </div>

        <div slot="body">
            <p class="text-center">
                {{ $t('modal.oneInformationRequired') }}
            </p>
            <hr />
            <activix-multiselect
                identifier="value"
                label="label"
                :placeholder="$t('modal.modality')"
                :options="modalityOptions"
                @update="updateSelectedModality"
            />
            <date-time-picker
                class="mt-4"
                :date-only="true"
                :placeholder="$t('modal.endContractRenew')"
                :start-date="currentTime"
                v-model="endContractDate"
                v-if="empty(selectedTerm) || selectedTerm.value != 'cash'"
            />

            <activix-multiselect
                class="mt-4"
                identifier="value"
                label="label"
                :options="terms"
                :placeholder="$t('modal.term')"
                @update="updateSelectedTerm"
            />
        </div>

        <div slot="footer">
            <activix-button type="primary" :disabled="disabled" @click="addRenewalData">
                {{ $t('modal.save') }}
            </activix-button>
        </div>
    </activix-modal>
</template>

<script>
    import { mapActions, mapState } from 'pinia';

    import DateTimePicker from '../inputs/DateTimePicker.vue';

    // Pinia
    import { useDashboardStore } from '../../store/modules/dashboard/store.js';
    import { useContextStore } from '../../store/modules/context/store.js';
    import { useGlobalStore } from '../../store/store.js';

    export default {
        components: {
            DateTimePicker,
        },

        props: {
            lead: {
                type: Object,
                default: () => new Lead(),
            },
            opened: {
                type: Boolean,
                required: true,
            },
        },

        data() {
            return {
                currentTime: now().toString(),
                selectedTerm: null,
                selectedModality: null,
                endContractDate: '',
                approbationCashDeal: '',
                modality: '',
                terms: [
                    { value: '12', label: `12 ${this.$t('clientCard.vehicles.months')}` },
                    { value: '24', label: `24 ${this.$t('clientCard.vehicles.months')}` },
                    { value: '32', label: `32 ${this.$t('clientCard.vehicles.months')}` },
                    { value: '33', label: `33 ${this.$t('clientCard.vehicles.months')}` },
                    { value: '34', label: `34 ${this.$t('clientCard.vehicles.months')}` },
                    { value: '36', label: `36 ${this.$t('clientCard.vehicles.months')}` },
                    { value: '38', label: `38 ${this.$t('clientCard.vehicles.months')}` },
                    { value: '39', label: `39 ${this.$t('clientCard.vehicles.months')}` },
                    { value: '40', label: `40 ${this.$t('clientCard.vehicles.months')}` },
                    { value: '42', label: `42 ${this.$t('clientCard.vehicles.months')}` },
                    { value: '48', label: `48 ${this.$t('clientCard.vehicles.months')}` },
                    { value: '54', label: `54 ${this.$t('clientCard.vehicles.months')}` },
                    { value: '60', label: `60 ${this.$t('clientCard.vehicles.months')}` },
                    { value: '64', label: `64 ${this.$t('clientCard.vehicles.months')}` },
                    { value: '66', label: `66 ${this.$t('clientCard.vehicles.months')}` },
                    { value: '72', label: `72 ${this.$t('clientCard.vehicles.months')}` },
                    { value: '78', label: `78 ${this.$t('clientCard.vehicles.months')}` },
                    { value: '84', label: `84 ${this.$t('clientCard.vehicles.months')}` },
                    { value: '90', label: `90 ${this.$t('clientCard.vehicles.months')}` },
                    { value: '96', label: `96 ${this.$t('clientCard.vehicles.months')}` },
                    { value: '108', label: `108 ${this.$t('clientCard.vehicles.months')}` },
                    { value: '120', label: `120 ${this.$t('clientCard.vehicles.months')}` },
                ],
                modalityOptions: [
                    { value: 'cash', label: this.$t('clientCard.vehicles.cash') },
                    { value: 'financing', label: this.$t('clientCard.vehicles.financing') },
                    { value: 'leasing', label: this.$t('clientCard.vehicles.leasing') },
                ],
            };
        },

        computed: {
            ...mapState(useGlobalStore, ['authUser', 'groupDisplay']),
            ...mapState(useContextStore, {
                contextAccount: 'account',
                contextGroup: 'group',
                contextUser: 'user',
            }),
            ...mapState(useDashboardStore, ['startDate', 'endDate', 'session', 'dashboardType', 'filteredCampaigns', 'filteredDates', 'division']),

            disabled() {
                if (empty(this.selectedModality)) {
                    return true;
                }

                if (
                    this.selectedModality.value != 'cash' &&
                    (!this.selectedTerm ||
                        !this.endContractDate ||
                        !as_locale(this.endContractDate, 'end_contract_date').isValid())
                ) {
                    return true;
                }

                return false;
            },
        },

        methods: {
            ...mapActions(useGlobalStore, ['updateLeadVehicleAction']),

            updateSelectedTerm(value) {
                this.selectedTerm = value;
            },

            updateSelectedModality(value) {
                this.selectedModality = value;
            },

            addRenewalData() {
                let payload;

                if (this.selectedTerm) {
                    payload = { term: this.selectedTerm.value };
                }

                if (this.endContractDate) {
                    payload = { ...payload, end_contract_date: this.endContractDate };
                }

                if (this.selectedModality) {
                    payload = { ...payload, modality: this.selectedModality.value };
                }

                this.updateLeadVehicleAction(payload, this.lead.getSoldOrFirstWantedVehicle().id);
                this.$emit('update:opened', false);
            },

            onClose() {
                this.$emit('update:opened', false);
                this.$emit('closed');
            },
        },
    };
</script>
