<template>
    <activix-modal
        size="sm"
        :closable="!$wait.is('creating.communication')"
        :name="name"
        :portal="portal"
        :loading="loading"
        :loading-overlay="false"
        ref="modal"
        @closed="onClosed"
        @before-open="onBeforeOpen"
    >
        <template slot="header">
            <h4 class="modal-title">
                {{ $t('communications.videoconference.invitation.modalTitle') }}
            </h4>
        </template>

        <template slot="body">
            <div class="text-center">
                <div class="alert alert-warning | text-center" v-if="lead.unsubscribe_email_date">
                    {{ $t('communications.videoconference.invitation.unsubscribedFromEmail') }}
                </div>

                <next-automation-alert :lead="lead" />

                <template v-if="newInvitationMode">
                    <p class="font-semibold">
                        {{ $t('communications.videoconference.invitation.method.email') }}
                    </p>

                    <communication-method-selection
                        name="emailInvitation"
                        type="checkbox"
                        :disabled="$wait.is('creating.communication')"
                        :items="emailOptions"
                        class="space-y-2"
                        data-intercom-target="videoconference.invitation.email"
                        v-model="selectedEmails"
                    />
                </template>

                <template v-else-if="invitationSent">
                    <sweet-alert-icon class="mx-auto -mt-2" type="success" />
                    <div class="mt-4" v-text="$t('communications.videoconference.invitation.success')" />
                </template>

                <template v-else>
                    <sweet-alert-icon class="mx-auto -mt-2" type="info" />
                    <div class="mt-4" v-text="videoSentText" />
                </template>
            </div>
        </template>

        <template slot="footer">
            <div class="flex items-center justify-center">
                <activix-button
                    :disabled="$wait.is('creating.communication')"
                    @click="close"
                    v-if="newInvitationMode || invitationSent"
                >
                    {{ newInvitationMode ? $t('general.cancel') : $t('general.close') }}
                </activix-button>
                <activix-button @click="onBypassInvitationClick" v-else>
                    {{ $t('communications.videoconference.invitation.newInvite') }}
                </activix-button>

                <activix-button
                    type="primary"
                    :disabled="callDisabled"
                    :loading="$wait.is('creating.communication')"
                    data-intercom-target="videoconference.invitation.send"
                    @click="sendInvitation"
                    v-if="newInvitationMode"
                >
                    {{ $t('modal.send') }}
                </activix-button>
                <activix-button
                    tag="a"
                    :href="videoRoomUrl"
                    target="_blank"
                    type="primary"
                    data-intercom-target="videoconference.invitation.join"
                    @click="onJoinClick"
                    v-else-if="videoRoomUrl"
                >
                    {{ $t('communications.videoconference.invitation.join') }}
                </activix-button>
            </div>
        </template>
    </activix-modal>
</template>

<script>
    import { orderBy } from 'lodash-es';
    import { isNetworkDisconnectedError } from '@/plugins/axios.js';

    import Lead from '@/entities/Lead.js';
    import ActivixDate from '@/value-objects/ActivixDate.js';
    import CommunicationMethod from '@/entities/CommunicationMethod.js';
    import CommunicationStatus from '@/entities/CommunicationStatus.js';

    import SweetAlertIcon from '@/components/elements/SweetAlert/Icon.vue';
    import NextAutomationAlert from '@/components/lead/NextAutomationAlert.vue';
    import CommunicationMethodSelection from '@/components/CommunicationMethodSelection.vue';
    import { mapActions, mapState } from 'pinia';
    import { useContextStore } from '../../store/modules/context/store.js';
    import { useGlobalStore } from '../../store/store.js';

    export default {
        components: {
            SweetAlertIcon,
            NextAutomationAlert,
            CommunicationMethodSelection,
        },

        props: {
            name: {
                type: String,
                required: true,
            },
            portal: {
                type: String,
                default: 'modal-1',
            },
        },

        data() {
            return {
                bypassInProgressVideoCommunication: false,
                communicating: false,
                invitationSent: false,
                loading: true,
                lead: new Lead(),
                selectedEmails: [],
            };
        },

        computed: {
            ...mapState(useGlobalStore, ['authUser', 'parentAuthUser']),
            ...mapState(useContextStore, {
                contextAccount: 'account',
            }),

            emailOptions() {
                return this.validLeadEmails.map(email => ({
                    id: email.id,
                    type: email.type,
                    value: email.email,
                    label: email.email,
                }));
            },

            callDisabled() {
                return !!(
                    this.lead.unsubscribe_call ||
                    this.lead.unsubscribe_all_date ||
                    this.$wait.is('creating.communication') ||
                    this.loading ||
                    !this.selectedEmails.length
                );
            },

            newInvitationMode() {
                return !this.inProgressVideoCommunication || this.bypassInProgressVideoCommunication;
            },

            currentUser() {
                const authUser = (this.parentAuthUser.children || []).find(
                    child => child.account_id == this.lead.account_id,
                );

                return authUser || this.authUser;
            },

            validLeadEmails() {
                return this.lead.lead_emails.filter(e => e.valid);
            },

            hasValidEmail() {
                return !!this.validLeadEmails.length;
            },

            displayContactMethodSelection() {
                return !this.loading && this.hasValidEmail && this.newInvitationMode;
            },

            videoSentText() {
                if (!this.inProgressVideoCommunication) {
                    return '';
                }

                return this.$t('communications.videoconference.invitation.sentMessage', [
                    new ActivixDate(this.inProgressVideoCommunication.created_at).toHumanShort(false),
                ]);
            },

            inProgressVideoCommunication() {
                const videoCommunications = this.lead.communications.filter(
                    c => c.communication_method_id === CommunicationMethod.VIDEO,
                );
                const [lastVideoCommunication] = orderBy(videoCommunications, 'created_at', 'desc');
                const lastVideoCommunicationIsInProgress = [
                    CommunicationStatus.PENDING,
                    CommunicationStatus.ATTEMPTED,
                    CommunicationStatus.CALLING,
                ].includes(lastVideoCommunication?.status);

                return lastVideoCommunicationIsInProgress ? lastVideoCommunication : null;
            },

            videoRoomUrl() {
                if (!this.inProgressVideoCommunication) {
                    return '';
                }

                return `${process.env.VUE_APP_VIDEO_SERVICE_URL}${this.inProgressVideoCommunication.video_room.user_url}`;
            },
        },

        methods: {
            ...mapActions(useGlobalStore, ['fetchLead', 'communicationCreated', 'appendNewError']),

            async sendInvitation() {
                if (this.callDisabled) {
                    return;
                }

                this.$wait.start('creating.communication');

                try {
                    const room = await this.createRoom();
                    const communication = await this.createCommunication(room);

                    this.communicationCreated(communication);
                    this.invitationSent = true;

                    this.$behavior.track('VideoConference', { action: 'invite', location: 'invitation' });
                } catch (error) {
                    if (isNetworkDisconnectedError(error) || error.response?.status === 403) {
                        return;
                    }

                    if (error.response?.status === 422) {
                        const message = Object.values(error.response.data.errors)[0][0] || '';

                        if (message) {
                            this.$notify.warning(message);
                        } else {
                            this.$notify.warning(this.$t('communications.videoconference.invitation.failed'));
                        }

                        return;
                    }

                    this.appendNewError({
                        code: '0103',
                        display: true,
                        error,
                    });
                } finally {
                    this.$wait.end('creating.communication');
                }

                this.bypassInProgressVideoCommunication = false;
            },

            async createRoom() {
                const response = await this.$axios.post(`${process.env.VUE_APP_VIDEO_SERVICE_URL}/api/rooms`, {
                    account_sid: this.lead.account.sid,
                    user_name: this.authUser.fullName,
                    client_name: this.lead.fullName,
                    client_locale: this.lead.locale,
                    allow_recording: this.$feature.isEnabled('videoconference_recordings'),
                });

                return response.data.data;
            },

            async createCommunication(room) {
                const response = await this.$axios.post('v1/communications/video', {
                    ...room,
                    lead_id: this.lead.id,
                    invitations: this.selectedEmails.map(email => ({
                        contact_value: email.value,
                        invite_type: 'email',
                    })),
                });

                return response.data.data;
            },

            initInvitationSelection() {
                if (this.emailOptions.length === 1) {
                    this.selectedEmails = [...this.emailOptions];
                }
            },

            close() {
                this.$behavior.track('VideoConference', { action: 'cancel', location: 'invitation' });
                this.$refs.modal.hide();
            },

            onJoinClick() {
                this.$behavior.track('VideoConference', { action: 'join', location: 'invitation' });
                this.close();
            },

            onClosed() {
                this.lead = new Lead();
                this.loading = true;
                this.selectedEmails = [];
                this.bypassInProgressVideoCommunication = false;
                this.invitationSent = false;
                this.$wait.end('creating.communication');
            },

            onBypassInvitationClick() {
                this.$behavior.track('VideoConference', { action: 'bypass', location: 'invitation' });
                this.bypassInProgressVideoCommunication = true;
            },

            async onBeforeOpen(leadId) {
                this.lead = await this.fetchLead(leadId);
                this.initInvitationSelection();
                this.loading = false;

                this.$behavior.track('VideoConference', { action: 'show', location: 'invitation' });
            },
        },

        created() {
            this.$eventBus.$on('screen-pop-video-conference-open', this.close);
        },

        beforeDestroy() {
            this.$eventBus.$on('screen-pop-video-conference-open', this.close);
        },
    };
</script>
