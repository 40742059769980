<template>
    <div class="input-wrapper">
        <activix-tooltip :content="walkAroundTooltip">
            <activix-checkbox
                name="walk_around"
                :disabled="disabled"
                :value="checked"
                :size="size"
                :color="color"
                @click.native.prevent="onClick"
            />
        </activix-tooltip>
    </div>
</template>

<script>
    import { mapActions, mapState } from 'pinia';

    import LeadStatus from '../../../../entities/LeadStatus.js';
    import FollowUpMixin from '../../../../mixins/FollowUpMixin.js';
    import { useGlobalStore } from '../../../../store/store.js';

    export default {
        mixins: [FollowUpMixin],

        props: {
            lead: {
                type: Object,
                required: true,
            },
            inClientCard: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            return {
                checked: false,
            };
        },

        computed: {
            ...mapState(useGlobalStore, ['authUser', 'guest']),

            color() {
                return !this.inClientCard ? 'green' : 'blue';
            },

            leadHasInvalidStatus() {
                if ([LeadStatus.INVALID, LeadStatus.LOST, LeadStatus.DUPLICATE].includes(this.lead.status)) {
                    return true;
                }

                return false;
            },

            walkAroundTooltip() {
                if (this.leadHasInvalidStatus) {
                    return `${this.$t(`clientCard.statusTooltip.${this.lead.status}`, [
                        this.$t('clientCard.statusTooltip.inputs.walkAround'),
                    ])}`;
                }

                return '';
            },

            disabled() {
                if (this.leadHasInvalidStatus) {
                    return true;
                }

                if (!this.$can('leads.walk_around:update')) {
                    return true;
                }

                if (this.lead.disabledBySaleVerified(this.authUser)) {
                    return true;
                }

                return this.guest;
            },

        },

        watch: {
            'lead.walk_around': {
                immediate: true,
                handler() {
                    this.updateChecked();
                },
            },
        },

        methods: {
            ...mapActions(useGlobalStore, ['updateLeadAction']),

            onClick() {
                if (this.disabled) {
                    return;
                }

                this.checked = !this.checked;
                this.updateLeadAction(this.lead.id, { walk_around: this.checked });
            },

            updateChecked() {
                this.checked = this.lead.walk_around;
            },
        },

        created() {
            this.$eventBus.$on('cancel-follow-up', this.updateChecked);
        },

        mounted() {
            this.updateChecked();
        },

        beforeDestroy() {
            this.$eventBus.$off('cancel-follow-up', this.updateChecked);
        },
    };
</script>
