import Enum from './Enum.js';

export default class CampaignType extends Enum {
    static get entries() {
        return {
            event: 'event',
            mailing: 'mailing',
        };
    }

    static get EVENT() {
        return this.entries.event;
    }

    static get MAILING() {
        return this.entries.mailing;
    }
}
