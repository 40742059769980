<template>
    <tr class="h-20 whitespace-nowrap" :class="{ 'opacity-50': isInactiveAutomation }">
        <td class="text-center pl-6">
            <template v-if="isEmail">
                <activix-tooltip :content="statusTooltip || tooltip" v-if="showEmailIcon">
                    <icon
                        class="text-3xl"
                        :class="[{ 'cursor-pointer': editable }, colorClass]"
                        :name="emailIcon"
                        @click="openViewModal"
                    />
                </activix-tooltip>
                <template v-else>
                    {{ $t('clientCard.noEmail') }}
                </template>
            </template>
            <template v-else-if="isSms">
                <activix-tooltip :content="tooltip">
                    <icon
                        class="text-3xl"
                        :class="[{ 'cursor-pointer': userHasAccessToNioText && editable }, colorClass]"
                        :name="$icons.sms"
                        @click="openSmsModal"
                    />
                </activix-tooltip>
            </template>
            <template v-else-if="isMessenger">
                <activix-tooltip :content="communicationTooltip">
                    <icon
                        class="text-3xl"
                        name="bold/social-media-facebook-1"
                        :class="[{ 'cursor-pointer': !communication.created_by_user }, colorClass]"
                        @click="openMessengerModal"
                    />
                </activix-tooltip>
            </template>
            <template v-else-if="isPhone">
                <template v-if="communication.url">
                    <activix-tooltip :content="userHasAccessToAudioTooltip" v-if="!userHasAccessToMedia || !url">
                        <activix-player class="pt-2" :custom-class="audioClasses" :disabled="true" />
                    </activix-tooltip>
                    <activix-tooltip :content="tooltip" v-else>
                        <activix-player
                            class="pt-2"
                            :custom-class="audioClasses"
                            :url="url"
                            :duration="timeHms(communication.duration_call)"
                            :downloadable="true"
                            :file-name="filename(communication, contextLead) + getExtension(communication)"
                        />
                    </activix-tooltip>

                    <template v-if="authUser.analyst_access">
                        ID: {{ communication.id }}
                    </template>
                </template>
                <template v-else-if="communication.created_by_user">
                    <activix-tooltip :content="tooltip">
                        <div class="manual-audio small" :class="serviceName">
                            M
                        </div>
                    </activix-tooltip>
                </template>
                <template v-else>
                    -
                </template>
            </template>
            <template v-else-if="isVideo">
                <videoconference-icon :communication="communication" :disabled="!userHasAccessToMedia" />
            </template>
        </td>
        <td class="max-w-64">
            <div class="flex items-center">
                <template v-if="isEmail">
                    <icon
                        name="regular/pencil-1"
                        class="link-grey-light text-sm mr-2"
                        @click="$emit('open-update-modal')"
                        v-if="editable && communication.created_by_user"
                    />
                    <div class="overflow-hidden" :class="{ 'cursor-pointer': editable }" @click="openViewModal">
                        <activix-tooltip :content="emailSubjectTooltip" v-if="communication.email_subject">
                            <div class="truncate">
                                {{ communication.email_subject }}
                            </div>
                        </activix-tooltip>
                        <template v-else>
                            ({{ $t('mailClient.noSubject') }})
                        </template>
                    </div>
                </template>
                <template v-else-if="isSms">
                    <icon
                        name="regular/pencil-1"
                        class="link-grey-light text-sm mr-2"
                        @click.stop="$emit('open-update-modal')"
                        v-if="communication.created_by_user"
                    />
                    <span
                        class="truncate"
                        :class="{ 'cursor-pointer': userHasAccessToNioText && editable }"
                        @click="openSmsModal"
                        v-html="smsExcerpt"
                    />
                </template>
                <template v-else-if="isMessenger">
                    <icon
                        name="regular/pencil-1"
                        class="link-grey-light text-sm mr-2"
                        @click.stop="$emit('open-update-modal')"
                    />
                    <span class="truncate cursor-pointer" @click="openMessengerModal">
                        {{ communication.description || $t('clientCard.noDescription') }}
                    </span>
                </template>
                <template v-else-if="isPhone">
                    <span class="min-w-1/6">
                        <icon
                            name="regular/pencil-1"
                            class="link-grey-light mr-2"
                            @click="$emit('open-update-modal')"
                            v-if="editable"
                        />
                    </span>
                    <span class="truncate" :data-lead-id="communication.lead_id">
                        <template v-if="authUser.block_lead_info && !communication.created_by_user">
                            {{ maskPhone(formatPhone(communication.phone, true)) }}
                        </template>
                        <template v-else-if="communication.created_by_user">
                            {{ communication.description || $t('clientCard.noDescription') }}
                        </template>
                        <template v-else>
                            {{ formatPhone(communication.phone, true) || communication.description || $t('clientCard.noDescription') }}
                        </template>
                    </span>
                </template>
                <template v-else-if="isVideo">
                    <icon
                        name="regular/pencil-1"
                        class="link-grey-light mr-2"
                        @click="$emit('open-update-modal')"
                        v-if="editable"
                    />
                    <span v-text="communication.description || $t('clientCard.noDescription')" />
                </template>
            </div>
        </td>
        <td class="text-center" v-if="showTimeElapsed">
            {{ timeElapsed }}
        </td>
        <td>
            <div class="flex items-center justify-center">
                <icon class="text-grey-500 mr-2" name="regular/time-clock-circle" />
                {{ createdAt }}
                <activix-tooltip :content="crmCreatedAtTooltip" v-if="crmCreatedAtTooltip">
                    <icon class="ml-2 text-orange-500 text-sm" name="regular/information-circle" />
                </activix-tooltip>
            </div>
        </td>
        <td class="text-center">
            {{ userName }}
        </td>
        <td>
            <div class="flex items-center justify-center">
                <template v-if="isEmail && !isAutomation">
                    <icon
                        class="text-grey-500 mr-2"
                        name="regular/time-clock-circle"
                        v-if="communication.email_read_at"
                    />
                    {{ emailReadAt }}
                </template>
                <template v-else>
                    -
                </template>
            </div>
        </td>
        <td class="text-center" :class="{ 'pr-6': !showExchangeCount && !showAutomationAction }">
            <template v-if="!empty(communication.attachments)">
                <activix-email-attachments-button :attachments="communication.attachments" />
            </template>
            <template v-else>
                -
            </template>
        </td>
        <td class="text-center" :class="{ 'pr-6': !showAutomationAction }" v-if="showExchangeCount">
            {{ exchangeCount }}
        </td>
        <td class="text-center pr-6" v-if="showAutomationAction">
            <div class="flex items-center justify-center" v-if="isAutomation">
                <activix-tooltip :content="activateAutomationTooltip">
                    <activix-switcher
                        :disabled="guest || isPastAutomation"
                        :value="communication.automation_job.active"
                        @input="toggleAutomationActivation"
                    />
                </activix-tooltip>
                <activix-tooltip :content="$t('communications.automationTooltip')">
                    <router-link
                        :to="{ name: 'automations.update', params: { id: communication.automation_job.action.automation_id } }"
                        v-if="communication.automation && parentAuthUser.hasAccessTo('automations.index')"
                    >
                        <activix-button class="ml-2" size="small">
                            <icon name="regular/video-game-magic-wand" />
                        </activix-button>
                    </router-link>
                </activix-tooltip>
            </div>
            <template v-else>
                -
            </template>
        </td>
    </tr>
</template>

<script>
    import emojione from 'emojione';

    // Utils
    import { mapActions, mapState } from 'pinia';
    import { timeHms } from '../../utils/time.js';

    // Entities
    import CommunicationKind from '../../entities/CommunicationKind.js';
    import CommunicationMethod from '../../entities/CommunicationMethod.js';
    import CommunicationStatus from '../../entities/CommunicationStatus.js';
    import CommunicationType from '../../entities/CommunicationType.js';
    import LeadType from '../../entities/LeadType.js';
    import Service from '../../entities/Service.js';

    // Components
    import ActivixPlayer from '../elements/ActivixPlayer.vue';
    import VideoconferenceIcon from '../VideoconferenceIcon.vue';
    import ActivixEmailAttachmentsButton from '../buttons/ActivixEmailAttachmentsButton.vue';

    // Value Objects
    import ActivixDate from '../../value-objects/ActivixDate.js';

    // Mixins
    import CommunicationMixin from '../../mixins/Communication.js';
    import { useGlobalStore } from '../../store/store.js';
    import { useContextStore } from '../../store/modules/context/store.js';

    export default {
        components: {
            ActivixEmailAttachmentsButton,
            ActivixPlayer,
            VideoconferenceIcon,
        },

        mixins: [CommunicationMixin],

        props: {
            communication: {
                type: Object,
                required: true,
            },
            showExchangeCount: {
                type: Boolean,
                required: true,
            },
            showTimeElapsed: {
                type: Boolean,
                required: true,
            },
            showAutomationAction: {
                type: Boolean,
                required: true,
            },
            editable: {
                type: Boolean,
                default: true,
            },
        },

        data: () => ({
            niotextTemplateCategories: [],
        }),

        computed: {
            ...mapState(useContextStore, ['contextLead']),
            ...mapState(useGlobalStore, ['parentAuthUser', 'authUser', 'guest']),

            activateAutomationTooltip() {
                if (!this.isAutomation) {
                    return '';
                }

                if (this.isPastAutomation) {
                    return this.$t('automations.pastExecutionDate');
                }

                if (this.communication.automation_job.active) {
                    return this.$t('automations.disableJob');
                }

                return this.$t('automations.enableJob');
            },

            audioClasses() {
                if (this.communication.service_id == Service.TELBOOST && this.contextLead.ivr) {
                    return 'ivr';
                }

                return Service.getKey(this.communication.service_id);
            },

            communicationTooltip() {
                if (
                    this.communication.service_id == Service.TELBOOST &&
                    this.contextLead.ivr &&
                    !this.communication.created_by_user
                ) {
                    return this.$t('phoneProvider.ivr');
                }

                if (this.communication.communication_method_id == CommunicationMethod.EMAIL) {
                    if (this.communication.media?.ziggeo_id) {
                        return this.$t('users.edit.emailVideo');
                    }

                    return this.$t('users.edit.email');
                }

                if (this.communication.communication_method_id == CommunicationMethod.SMS) {
                    if (this.communication.created_by_user) {
                        return this.$t('clientCard.manualSMS');
                    }

                    return this.$t('users.edit.sms');
                }

                if (this.communication.communication_method_id == CommunicationMethod.MESSENGER) {
                    return this.$t('users.edit.messenger');
                }

                switch (this.communication.service_id) {
                    case Service.WEBBOOST:
                        return this.$t('general.webBoost');

                    case Service.REVIVE:
                        if (this.communication.created_by_user) {
                            return this.$t('clientCard.manualCall');
                        }

                        return this.$tc('general.call', 1);

                    case Service.TELBOOST:
                        if (this.communication.created_by_user) {
                            if (this.communication.communication_method_id == CommunicationMethod.SMS) {
                                return this.$t('clientCard.manualSMS');
                            }

                            return this.$t('clientCard.manualCall');
                        }

                        return this.$t('general.phoneUp');
                }

                return '';
            },

            createdAt() {
                if (this.communication.original_created_at) {
                    return new ActivixDate(this.communication.original_created_at).toHumanShort();
                }

                return new ActivixDate(this.communication.created_at).toHumanShort();
            },

            crmCreatedAtTooltip() {
                if (!this.communication.original_created_at) {
                    return '';
                }

                const originalCreatedAt = new ActivixDate(this.communication.original_created_at);
                const crmCreatedAt = new ActivixDate(this.communication.created_at);

                if (crmCreatedAt.isSameDay(originalCreatedAt)) {
                    return '';
                }

                return this.$t('clientCard.crmCreatedAt', { date: crmCreatedAt.toHumanShort(false) });
            },

            emailReadAt() {
                return as_locale(this.communication.email_read_at, 'email_read_at').humanShort() || '-';
            },

            emailSubjectTooltip() {
                if (this.communication.client_email) {
                    return this.maskEmail(this.communication.client_email);
                }

                return '';
            },

            exchangeCount() {
                const counter = (this.communication.inbound_counter + this.communication.outbound_counter) || this.communication.exchange_counter;

                if (counter && [CommunicationMethod.SMS, CommunicationMethod.MESSENGER].includes(this.communication.communication_method_id)) {
                    return counter;
                }

                return '-';
            },

            hasEmailReadAt() {
                return (
                    this.authUser.id == this.communication.user_id &&
                    this.communication.email_read_at &&
                    !this.communication.user_seen_email_read_at
                );
            },

            isAutomation() {
                return !!this.communication.automation;
            },

            isInactiveAutomation() {
                return this.isAutomation && !this.communication.automation_job.active;
            },

            isPastAutomation() {
                const now = new ActivixDate('now');
                const executionDate = new ActivixDate(this.communication.created_at);

                return this.isAutomation && executionDate.isBefore(now);
            },

            isEmail() {
                return this.communication.communication_method_id == CommunicationMethod.EMAIL;
            },

            isSms() {
                return this.communication.communication_method_id == CommunicationMethod.SMS;
            },

            isMessenger() {
                return this.communication.communication_method_id == CommunicationMethod.MESSENGER;
            },

            isVideo() {
                return this.communication.communication_method_id == CommunicationMethod.VIDEO;
            },

            isPhone() {
                return this.communication.communication_method_id == CommunicationMethod.PHONE;
            },

            serviceName() {
                return Service.getKey(this.communication.service_id);
            },

            showEmailIcon() {
                return (
                    !!this.communication.email_subject ||
                    !!this.communication.email_body ||
                    !!this.communication.excerpt
                );
            },

            emailIcon() {
                return this.communication.media?.ziggeo_id ? this.$icons.emailVideo : this.$icons.email;
            },

            niotextTemplate() {
                if (!this.communication.automation_job) {
                    return null;
                }

                const templateId = this.communication.automation_job.action.parameters.template.id;
                const templateCategoryId = this.communication.automation_job.action.parameters.template.category_id;

                const category = this.niotextTemplateCategories.find(templateCategory => {
                    return templateCategory.id === templateCategoryId;
                });

                return category?.templates?.find(template => {
                    return template.id === templateId;
                });
            },

            smsExcerpt() {
                if (this.communication.excerpt) {
                    return emojione.shortnameToImage(this.communication.excerpt);
                }

                if (this.niotextTemplate) {
                    return emojione.shortnameToImage(this.niotextTemplate.content[this.communication.locale]);
                }

                if (this.communication.description) {
                    return this.communication.description;
                }

                return `(${this.$t('clientCard.noSms')})`;
            },

            colorClass() {
                if (this.communication.status == CommunicationStatus.DELIVERY_FAILED || this.communicationHasBounced) {
                    return 'text-red-500';
                }

                if (this.isAutomation) {
                    return 'text-grey-650';
                }

                if (this.communication.kind == CommunicationKind.AUTO || this.communication.kind == CommunicationKind.PLANNED) {
                    return 'text-grey-500';
                }

                if (this.communication.communication_type_id == CommunicationType.OUTGOING) {
                    return 'text-orange-500';
                }

                return 'text-green-500';
            },

            statusTooltip() {
                if (this.communicationHasBounced) {
                    return this.$t('client.emailNotDelivered');
                }

                if (this.isAutomation || this.communication.kind == CommunicationKind.AUTO) {
                    return this.$t('clientCard.automatedEmail');
                }

                if (this.communication.kind == CommunicationKind.PLANNED) {
                    return this.$t('clientCard.plannedEmail');
                }

                if (this.communication.status == CommunicationStatus.DELIVERY_FAILED) {
                    return this.$t('leadXpress.deliveryFailed');
                }

                return '';
            },

            timeElapsed() {
                if (this.isAutomation) {
                    return '-';
                }

                return timeHms(this.communication.duration_reached);
            },

            tooltip() {
                let tooltip = '';

                if (this.communication.communication_type_id == CommunicationType.OUTGOING) {
                    tooltip = `${this.communicationTooltip} - ${this.$t('general.outgoing')}`;
                } else {
                    tooltip = `${this.communicationTooltip} - ${this.$t('general.incoming')}`;
                }

                if (this.communication.kind == CommunicationKind.MASS_MAILING) {
                    tooltip += ` - ${this.$t('options.massMailing')}`;
                }

                if (!this.communication.url && this.communication.duration_call) {
                    tooltip += ` - ${this.timeHms(this.communication.duration_call)}`;
                }

                return tooltip;
            },

            typeName() {
                return CommunicationType.getKey(this.communication.communication_type_id);
            },

            userHasAccessToAudioTooltip() {
                if (this.contextLead.account.disable_communication_audio) {
                    return this.$t('audio.noAudio');
                }

                return this.$t('dashboards.noAudioAccess');
            },

            userHasAccessToNioText() {
                return this.authUser.hasNioText() && this.authUser.account.hasNioText();
            },

            userName() {
                if (this.contextLead.isNotOfType(LeadType.EVENT)) {
                    if (!empty(this.communication.user)) {
                        return this.getShortName(this.communication.user);
                    }

                    if (this.communication.receptionist) {
                        return this.$t('divisions.reception');
                    }

                    return '';
                }

                if (
                    !empty(this.communication.external_content) &&
                    !empty(this.communication.external_content.dialogs_agent_id)
                ) {
                    return this.communication.external_content.dialogs_agent_id;
                }

                if (!empty(this.communication.user)) {
                    return this.getShortName(this.communication.user);
                }

                return '';
            },

            communicationHasBounced() {
                return !!this.communication.bounced_at;
            },
        },

        methods: {
            ...mapActions(useGlobalStore, ['clearSingleSeenEmailReadAlert', 'updateAutomationJobAction']),

            openViewModal() {
                if (!this.editable) {
                    return;
                }

                this.clearEmailRead();

                if (this.isAutomation) {
                    this.$emit('open-automation-modal');
                } else {
                    this.$emit('open-view-modal');
                }
            },

            openSmsModal() {
                if (!this.editable) {
                    return;
                }

                if (this.userHasAccessToNioText) {
                    this.$emit('open-sms-modal');
                }
            },

            openMessengerModal() {
                if (this.communication.created_by_user) {
                    this.$emit('open-update-modal');
                    return;
                }

                this.$emit('open-messenger-modal');
            },

            clearEmailRead() {
                if (this.hasEmailReadAt) {
                    this.clearSingleSeenEmailReadAlert(this.communication);
                }
            },

            toggleAutomationActivation(active) {
                if (!this.isAutomation || this.isPastAutomation) {
                    return;
                }

                this.updateAutomationJobAction({
                    id: this.communication.automation_job.id,
                    leadId: this.contextLead.id,
                    payload: { active },
                });
            },

            async loadNioTextTemplates() {
                this.niotextTemplateCategories = await this.$api.niotextTemplates.index(this.contextLead.account_id);
            },
        },

        mounted() {
            if (this.communication.automation_job && this.communication.communication_method_id === CommunicationMethod.SMS) {
                this.loadNioTextTemplates();
            }
        },
    };
</script>
