<template>
    <activix-tooltip :content="tooltip">
        <div :class="{ 'cursor-not-allowed': disabled }" class="input-wrapper">
            <activix-checkbox
                :disabled="disabled"
                :value="checked"
                :size="size"
                :color="color"
                :cross="isCross"
                @mouseenter.native="disabled || fetchLead(lead.id)"
                @click.native.prevent="onClick"
            />

            <template v-if="inClientCard">
                <span class="editable | ml-4 | md:ml-2" :class="{ disabled: disabled }" @click="onDateClick">
                    {{ localizedDate || emptyValue }}
                </span>
            </template>

            <activix-confirm-modal
                :approve-text="$t('dashboards.removeIt')"
                :content="$t('dashboards.deleteFollowUpConfirmation', [$t('dashboards.performance.sale')])"
                :deny-text="$t('general.cancel')"
                :opened.sync="uncheckModalOpened"
                :title="$t('general.areYouSure')"
                type="error"
                @approve="triggerUncheck"
                @deny="updateChecked"
            />
            <activix-confirm-modal
                :approve-text="$t('followUp.addSaleByPhone')"
                :content="$t('followUp.saleIsByPhone')"
                :deny-text="$t('general.cancel')"
                :opened.sync="confirmSaleByPhoneModalOpened"
                :title="$t('general.areYouSure')"
                @approve="openModalSelection"
                @deny="updateChecked"
            />
            <vue-fetti v-if="visibleConfetti" />
        </div>
    </activix-tooltip>
</template>

<script>
    import VueFetti from 'vuefetti/src/components/VueFetti.vue';

    import AccountType from '@/entities/AccountType.js';

    import { mapActions, mapState } from 'pinia';

    // Entities
    import ProgressState from '../../../../entities/ProgressState.js';
    import LeadType from '../../../../entities/LeadType.js';

    // Value Objects
    import ActivixDate from '../../../../value-objects/ActivixDate.js';

    import FollowUpMixin from '../../../../mixins/FollowUpMixin.js';

    // Pinia
    import { useContextStore } from '../../../../store/modules/context/store.js';
    import { useGlobalStore } from '../../../../store/store.js';

    export default {
        components: {
            VueFetti,
        },

        mixins: [FollowUpMixin],

        props: {
            lead: {
                type: Object,
                required: true,
            },
            startDate: {
                type: ActivixDate,
                default: null,
            },
            endDate: {
                type: ActivixDate,
                default: null,
            },
            inClientCard: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            return {
                checked: false,
                uncheckModalOpened: false,
                confirmSaleByPhoneModalOpened: false,
                visibleConfetti: false,
            };
        },

        computed: {
            ...mapState(useGlobalStore, ['authUser', 'guest']),
            ...mapState(useContextStore, {
                contextAccount: 'account',
            }),

            date() {
                return new ActivixDate(this.lead.sale_date);
            },

            presentedDate() {
                return new ActivixDate(this.lead.presented_date).or(this.lead.home_presented_date);
            },

            localizedDate() {
                return this.date.toHumanShort(false);
            },

            isInDates() {
                return this.date.isBetween(this.startDate, this.endDate);
            },

            soldOrFirstWantedVehicle() {
                return this.lead.getSoldOrFirstWantedVehicle();
            },

            disabledByVehicleInfoRule() {
                return (
                    !this.lead.sale_date &&
                    this.lead.account.stock_required_for_sale &&
                    this.lead.wanted_vehicles.every(vehicle => {
                        return !vehicle.make || !vehicle.model;
                    })
                );
            },

            disabledByStockRule() {
                return (
                    !this.lead.sale_date &&
                    this.lead.account.client_card_fields.wanted_vehicle.stock &&
                    this.lead.account.stock_required_for_sale &&
                    this.lead.wanted_vehicles.every(vehicle => {
                        return !vehicle.make || !vehicle.model || this.emptyStock(vehicle);
                    })
                );
            },

            color() {
                return this.lead.sale_by_phone ? 'purple' : 'blue';
            },

            disabled() {
                if (this.inClientCard) {
                    if (this.presentedDate.isEmpty() && !this.lead.account.sale_by_phone) {
                        return true;
                    }
                }

                if (!this.inClientCard) {
                    if (this.presentedDate.isEmpty() || this.lead.sale_by_phone) {
                        return true;
                    }
                }

                if (['invalid', 'lost', 'duplicate'].includes(this.lead.status)) {
                    return true;
                }

                if (this.lead.refinanced_date) {
                    if (!this.lead.sale_date || this.lead.delivery || this.lead.delivered) {
                        return true;
                    }
                }

                if ((this.disabledByStockRule || this.disabledByVehicleInfoRule) && this.lead.account.type != AccountType.CUSTOM) {
                    return true;
                }

                if (this.lead.delivered_date && this.lead.lead_type_id != LeadType.RENEWAL) {
                    // if the sale is not checked for some reason allow it to be checked to fix the error
                    return !this.date.isEmpty();
                }

                if (!this.$can('leads.sale_date:update')) {
                    return true;
                }

                if (this.lead.disabledBySaleVerified(this.authUser)) {
                    return true;
                }

                return this.guest;
            },

            isCross() {
                if (this.inClientCard || !this.date.isEmpty() || this.isInDates) {
                    return false;
                }

                return true;
            },

            tooltip() {
                if (!this.lead.presented_date && !this.lead.home_presented_date) {
                    return this.$t('clientCard.needPresentedChecked');
                }

                if (this.disabledByStockRule) {
                    return this.$t('clientCard.needVehicleInfoAndStockForSale');
                }

                if (this.disabledByVehicleInfoRule) {
                    return this.$t('clientCard.needVehicleInfoForSale');
                }

                if (!this.lead.sale_date && this.lead.sale_by_phone) {
                    return this.$t('clientCard.carIsAlreadySold');
                }

                if (this.inClientCard) {
                    return '';
                }

                let tooltip = '';

                if (this.lead.sale_by_phone) {
                    tooltip += `<strong>${this.$t('date.saleByPhone')}</strong>`;
                } else {
                    tooltip += `<strong>${this.$t('result.sale')}</strong>`;
                }

                if (!this.date.isEmpty()) {
                    tooltip += `<br>${this.localizedDate}`;

                    if (!this.isInDates) {
                        tooltip += `<br><em>${this.$t('dashboards.notInDate')}</em>`;
                    }
                }

                if (this.lead.sale_by_phone) {
                    tooltip += `<br><em>${this.$t('clientCard.needSaleByPhoneUnchecked')}</em>`;

                    return tooltip;
                }

                if (this.lead.delivered_date && this.lead.lead_type_id != LeadType.RENEWAL) {
                    tooltip += `<br><em>${this.$t('clientCard.needDeliveredUnchecked')}</em>`;

                    return tooltip;
                }

                return tooltip;
            },

            shouldDisplayConfirmSaleByPhone() {
                return this.inClientCard && this.presentedDate.isEmpty() && this.date.isEmpty();
            },

            shouldDisplaySelectVehicle() {
                return this.lead.wanted_vehicles.length > 1;
            },
        },

        watch: {
            'lead.sale_date': {
                immediate: true,
                handler() {
                    this.updateChecked();

                    if (
                        this.lead.progress_state == ProgressState.CASH_DEAL &&
                        this.soldOrFirstWantedVehicle &&
                        this.soldOrFirstWantedVehicle.modality != 'cash'
                    ) {
                        this.updateLeadVehicleAction(
                            {
                                modality: 'cash',
                            },
                            this.soldOrFirstWantedVehicle.id,
                        );
                    }
                },
            },
        },

        methods: {
            ...mapActions(useGlobalStore, ['updateLeadAction', 'updateLeadVehicleAction', 'fetchLead']),

            onDateClick() {
                const toggleChecked = !this.checked;

                this.onClick(toggleChecked);
            },

            updateChecked() {
                this.checked = !this.date.isEmpty();
            },

            openModalSelection() {
                if (this.shouldDisplaySelectVehicle) {
                    this.openSelectVehicleModal();
                } else {
                    this.openFollowUpModal();
                }
            },

            async makeConfetti() {
                if (!this.inClientCard || !this.$feature.isEnabled('special-sale')) {
                    return;
                }

                this.visibleConfetti = false;
                await this.$nextTick();
                this.visibleConfetti = true;
            },

            onClick(toggleChecked = true) {
                if (this.disabled) {
                    return;
                }

                if (toggleChecked) {
                    this.checked = !this.checked;
                }

                if (!this.checked) {
                    this.uncheckModalOpened = true;
                    return;
                }

                if (this.shouldDisplaySelectVehicle) {
                    if (this.shouldDisplayConfirmSaleByPhone) {
                        this.confirmSaleByPhoneModalOpened = true;
                    } else if (!toggleChecked) {
                        this.openFollowUpModal();
                    } else {
                        this.openSelectVehicleModal();
                    }
                } else if (this.inClientCard && this.date.isEmpty() && this.presentedDate.isToday()) {
                    this.autoSetToday();
                    this.makeConfetti();
                } else if (this.shouldDisplayConfirmSaleByPhone) {
                    this.confirmSaleByPhoneModalOpened = true;
                } else {
                    this.openFollowUpModal();
                }
            },

            autoSetToday() {
                this.updateLeadAction(this.lead.id, {
                    sale: true,
                    sale_date: this.presentedDate.toString(),
                });

                if (!empty(this.lead.wanted_vehicles.length)) {
                    this.updateLeadVehicleAction({ sold: true }, this.lead.wanted_vehicles[0].id);
                }
            },

            emptyStock(vehicle) {
                return !vehicle.stock_state || (vehicle.stock_state == 'stock' && !vehicle.stock);
            },

            triggerUncheck() {
                this.updateLeadAction(this.lead.id, {
                    delivery_date: null,
                    sale: false,
                    sale_date: null,
                    sale_by_phone: false,
                });
            },

            openFollowUpModal() {
                this.$modal.show('followUp', {
                    leadId: this.lead.id,
                    current: 'sale',
                    last: 'presented',
                });
            },

            openSelectVehicleModal() {
                this.$modal.show('selectVehicle', {
                    leadId: this.lead.id,
                    step: 'sale',
                });
            },
        },

        created() {
            this.$eventBus.$on('cancel-follow-up', this.updateChecked);
            this.$eventBus.$on('make-confetti-sale', this.makeConfetti);
        },

        mounted() {
            this.updateChecked();
        },

        beforeDestroy() {
            this.$eventBus.$off('cancel-follow-up', this.updateChecked);
            this.$eventBus.$off('make-confetti-sale', this.makeConfetti);
        },
    };
</script>
