<template>
    <activix-tooltip :content="tooltip">
        <div class="input-wrapper">
            <activix-checkbox
                :disabled="disabled"
                :value="checked"
                :size="size"
                :color="color"
                @click.native.prevent="onClick"
            />
        </div>
    </activix-tooltip>
</template>

<script>
    import LeadType from '@/entities/LeadType';
    import { mapActions, mapState } from 'pinia';

    import FollowUpMixin from '../../../../mixins/FollowUpMixin.js';

    // Pinia
    import { useDashboardStore } from '../../../../store/modules/dashboard/store.js';
    import { useGlobalStore } from '../../../../store/store.js';

    export default {
        mixins: [FollowUpMixin],

        props: {
            lead: {
                type: Object,
                required: true,
            },
            inClientCard: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            return {
                checked: false,
            };
        },

        computed: {
            ...mapState(useGlobalStore, ['authUser', 'guest']),
            ...mapState(useDashboardStore, ['dashboardType']),

            disabled() {
                if (['invalid', 'lost', 'duplicate'].includes(this.lead.status) ||
                    !this.lead.sale_date ||
                    this.lead.delivered_date) {
                    return true;
                }

                if (!this.$can('leads.waiting_sale:update')) {
                    return true;
                }

                return this.guest;
            },

            color() {
                if (!this.inClientCard && this.dashboardType == 'saleTable') {
                    return 'green';
                }

                return 'blue';
            },

            tooltip() {
                if (['invalid', 'lost', 'duplicate'].includes(this.lead.status)) {
                    return `${this.$t(`clientCard.statusTooltip.${this.lead.status}`, [
                        this.$t('clientCard.statusTooltip.inputs.waitingSale'),
                    ])}`;
                }

                if (!this.lead.sale_date) {
                    if (this.lead.isOfType(LeadType.RENEWAL)) {
                        return this.$t('clientCard.needRenewalChecked');
                    }

                    return this.$t('clientCard.needSaleChecked');
                }

                if (this.lead.delivered_date) {
                    return this.$t('clientCard.waitingSaleDisabled');
                }

                return this.$t('clientCard.waitingSaleTooltip');
            },
        },

        watch: {
            'lead.waiting_sale': {
                immediate: true,
                handler() {
                    this.updateChecked();
                },
            },
        },

        methods: {
            ...mapActions(useGlobalStore, ['updateLeadAction']),

            updateChecked() {
                this.checked = this.lead.waiting_sale;
            },

            onClick() {
                if (this.disabled) {
                    return;
                }

                const checked = !this.checked;
                this.updateLeadAction(this.lead.id, { waiting_sale: checked });
            },
        },

        created() {
            this.$eventBus.$on('cancel-follow-up', this.updateChecked);
        },

        mounted() {
            this.updateChecked();
        },

        beforeDestroy() {
            this.$eventBus.$off('cancel-follow-up', this.updateChecked);
        },
    };
</script>
