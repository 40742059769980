<template>
    <activix-tooltip :content="videoTooltip">
        <div class="flex items-center justify-center">
            <a
                :class="colorClass"
                target="_blank"
                :href="url"
                @click="onUrlClick"
                v-if="!isDisabled"
            >
                <icon class="text-3xl" :name="$icons.camera" />
            </a>
            <icon
                class="text-3xl"
                :class="colorClass"
                :name="$icons.camera"
                v-else
            />
        </div>
    </activix-tooltip>
</template>

<script>

    import CommunicationStatus from '@/entities/CommunicationStatus.js';
    import CommunicationType from '@/entities/CommunicationType.js';
    import { mapState } from 'pinia';
    import { useGlobalStore } from '../store/store.js';

    export default {
        props: {
            communication: {
                type: Object,
                required: true,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
        },

        computed: {
            ...mapState(useGlobalStore, ['authUser']),

            colorClass() {
                if (this.isDisabled) {
                    return this.communication.communication_type_id == CommunicationType.OUTGOING
                        ? 'text-orange-500'
                        : 'text-green-500';
                }

                return this.communication.communication_type_id == CommunicationType.OUTGOING
                    ? 'link-orange'
                    : 'link-green';
            },

            videoTooltip() {
                if (this.communication.url) {
                    return this.$t('communications.videoconference.openVideo');
                }

                if (this.canJoinRoom) {
                    return this.$t('communications.videoconference.join');
                }

                if (this.communication.video_room?.allow_recording) {
                    if (this.roomUrl && this.communication.status === CommunicationStatus.ANSWERED) {
                        return this.$t('communications.videoconference.processing');
                    }

                    return this.$t('communications.videoconference.noVideo');
                }

                return '';
            },

            url() {
                return this.communication.url || this.roomUrl;
            },

            roomUrl() {
                // eslint-disable-next-line camelcase
                const videoRoomUserUrl = this.communication?.video_room?.user_url;

                if (!videoRoomUserUrl) {
                    return '';
                }

                return `${process.env.VUE_APP_VIDEO_SERVICE_URL}${videoRoomUserUrl}`;
            },

            isDisabled() {
                return this.disabled || (!this.canJoinRoom && !this.communication.url);
            },

            canJoinRoom() {
                return (
                    !this.authUser.isAdmin() &&
                    !this.$auth.impersonating() &&
                    !!this.roomUrl &&
                    [CommunicationStatus.PENDING, CommunicationStatus.ATTEMPTED, CommunicationStatus.CALLING].includes(
                        this.communication.status,
                    )
                );
            },
        },

        methods: {
            onUrlClick() {
                if (this.communication.url) {
                    this.$behavior.track('VideoConference', { action: 'play', location: 'communication' });
                } else {
                    this.$behavior.track('VideoConference', { action: 'join', location: 'communication' });
                }
            },
        },
    };
</script>
