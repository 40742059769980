<template>
    <activix-modal
        size="sm"
        :closable="false"
        :name="name"
        :loading="loading"
        :loading-overlay="false"
        ref="modal"
        @before-open="onBeforeOpen"
        @closed="onClosed"
    >
        <template slot="header">
            <h4 class="modal-title">
                {{ modalTitle }}
            </h4>
        </template>

        <template slot="body">
            <div class="text-center py-1.5 flex justify-center" :key="vehicle.id" v-for="vehicle in lead.wanted_vehicles">
                <activix-tooltip placement="left" :content="disabledByStockRule(vehicle) ? $t('clientCard.needVehicleInfoToSelectSoldVehicle') : selectTooltip(vehicle)">
                    <activix-radio
                        name="vehicleSold"
                        :value="vehicle.id"
                        :disabled="disabledByStockRule(vehicle)"
                        v-model="soldVehicle"
                    >
                        <div class="text-left w-56 ml-1" v-if="vehicle.make || vehicle.model || vehicle.year">
                            <div class="text-lg capitalize truncate">
                                {{ vehicle.make }} {{ vehicle.model }} {{ vehicle.year }}
                            </div>
                            <div class="text-gray-600 text-sm text-left h-6 truncate flex items-start" v-if="vehicle.stock && vehicle.stock_state">
                                {{ vehicle.stock_state }} #{{ vehicle.stock }}
                                <span class="label | border border-green-500 text-green-500 ml-2 leading-none" v-if="isPolestarVehicleDeposited(vehicle)">
                                    {{ $t('clientCard.deposit') }}
                                </span>
                            </div>
                        </div>

                        <div class="w-56 text-left text-lg capitalize" v-else>
                            {{ $t('clientCard.unknown') }}
                        </div>
                    </activix-radio>
                </activix-tooltip>
            </div>
        </template>

        <template slot="footer">
            <activix-button @click="uncheck">
                {{ $t('general.cancel') }}
            </activix-button>
            <activix-button
                type="primary"
                :disabled="!soldVehicle"
                :loading="saving"
                @click="submit"
            >
                {{ $t('general.save') }}
            </activix-button>
        </template>
    </activix-modal>
</template>

<script>
    import { mapActions, mapState } from 'pinia';

    // Entities
    import LeadType from '../../entities/LeadType.js';
    import Lead from '../../entities/Lead.js';

    // Pinia
    import { useContextStore } from '../../store/modules/context/store.js';
    import { useGlobalStore } from '../../store/store.js';

    export default {

        props: {
            name: {
                type: String,
                required: true,
            },
            inClientCard: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            return {
                soldVehicle: null,
                loading: true,
                saving: false,
                lead: new Lead(),
                step: null,
            };
        },

        computed: {
            ...mapState(useContextStore, {
                contextAccount: 'account',
            }),

            modalTitle() {
                if (this.lead.lead_type_id != LeadType.RENEWAL && this.step == 'sale') {
                    return this.$t('clientCard.chooseSoldVehicle');
                }

                if (this.lead.lead_type_id == LeadType.RENEWAL && (this.step == 'renewal' || this.step == 'sale')) {
                    return this.$t('clientCard.chooseRenewalVehicle');
                }

                if (this.step == 'refinanced') {
                    return this.$t('clientCard.chooseRefinancedVehicle');
                }

                if (this.step == 'delivered') {
                    return this.$t('clientCard.chooseDeliveredVehicle');
                }

                if (this.step == 'recorded') {
                    return this.$t('clientCard.chooseRecordedVehicle');
                }

                return '';
            },
        },

        methods: {
            ...mapActions(useGlobalStore, ['updateLeadAction', 'updateLeadVehicleAction', 'fetchLead']),

            uncheck() {
                this.$eventBus.$emit('cancel-follow-up');

                this.close();
            },

            submit() {
                this.saving = true;

                if (this.inClientCard && as_locale(this.lead.presented_date, 'presented_date').isToday()) {
                    this.updateLeadVehicleAction({ sold: true }, this.soldVehicle);

                    this.updateLeadAction(this.lead.id, {
                        sale: true,
                        sale_date: this.lead.presented_date,
                    });
                } else {
                    this.dispatchSetCurrentFollowUp(this.lead.id, () => {
                        this.updateLeadVehicleAction({ sold: true }, this.soldVehicle);
                    });
                }

                this.close();
            },

            dispatchSetCurrentFollowUp(leadId, onApproveCallback = undefined) {
                setTimeout(() => {
                    this.$modal.show('followUp', {
                        leadId,
                        current: 'sale',
                        last: 'presented',
                        onApproveCallback: onApproveCallback || undefined,
                    });
                }, 900);
            },

            isPolestarVehicleDeposited(vehicle) {
                return vehicle?.custom_fields.some(customField => customField?.fixed_field === 'custom_field_order_deposit' && customField?.pivot?.value[0] === true);
            },

            disabledByStockRule(vehicle) {
                return (
                    this.lead.account.stock_required_for_sale &&
                    (!vehicle.make ||
                        !vehicle.model ||
                        !vehicle.stock_state ||
                        (vehicle.stock_state == 'stock' && !vehicle.stock))
                );
            },

            selectTooltip(vehicle) {
                const tooltip = [];

                if (vehicle.version) {
                    tooltip.push(`<span class="font-bold">${this.$t('clientCard.vehicles.version')}:</span> ${vehicle.version}`);
                }

                if (vehicle.color_exterior) {
                    tooltip.push(`<span class="font-bold">${this.$t('clientCard.vehicles.exteriorColor')}:</span> ${vehicle.color_exterior}`);
                }

                if (vehicle.vin) {
                    tooltip.push(`<span class="font-bold">${this.$t('clientCard.vehicles.vin')}:</span> ${vehicle.vin}`);
                }

                if (vehicle.price) {
                    tooltip.push(`<span class="font-bold">${this.$t('clientCard.vehicles.price')}:</span> ${this.toMoney(vehicle.price, 2)}`);
                }

                if (!tooltip.length) {
                    return '';
                }

                return `<div class="text-left">${tooltip.join('<br>')}</div>`;
            },

            async onBeforeOpen({ leadId, step }) {
                this.lead = await this.fetchLead(leadId);
                this.step = step;
                this.loading = false;
            },

            close() {
                this.$refs.modal.hide();
            },

            onClosed() {
                this.lead = new Lead();
                this.loading = true;
                this.saving = false;
                this.step = null;
            },
        },
    };
</script>
