<template>
    <div class="input-wrapper" :class="{ disabled: disabled, 'pr-8': lgLayout && centerIcon }">
        <div
            class="input-container"
            @keyup.enter="onEnter"
            @keyup.esc="onCancel"
            v-on-clickaway="onBlur"
            v-if="editing"
        >
            <slot />

            <template v-if="showButtons">
                <activix-button
                    class="ml-2 shadow h-8 w-9 p-0 flex-shrink-0"
                    size="small"
                    type="primary"
                    @mousedown="onAccept"
                >
                    <icon name="bold/check-1" class="text-xs" />
                </activix-button>
                <activix-button class="ml-2 shadow h-8 w-9 p-0 flex-shrink-0" size="small" @mousedown="onCancel">
                    <icon name="bold/close" class="text-xs text-grey-650" />
                </activix-button>
            </template>
        </div>

        <div
            class="link-grey-dark | truncate"
            :class="{
                disabled: disabled,
                'text-red-500': !valid,
            }"
            @click="onEdit"
            v-else
        >
            <slot name="value" />
        </div>

        <slot name="right" />
    </div>
</template>

<script>
    export default {
        props: {
            centerIcon: {
                type: Boolean,
                default: true,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            editing: {
                type: Boolean,
                default: false,
            },
            valid: {
                type: Boolean,
                default: true,
            },
            handleEnter: {
                type: Boolean,
                default: true,
            },
            showButtons: {
                type: Boolean,
                default: true,
            },
        },

        methods: {
            onEnter() {
                if (this.handleEnter) {
                    this.$emit('accept');
                }
            },

            onAccept() {
                this.$emit('accept');
            },

            onCancel() {
                this.$emit('cancel');
            },

            onEdit() {
                this.$emit('edit');
            },

            onBlur() {
                this.$emit('blur');
            },
        },
    };
</script>
