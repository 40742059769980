<template>
    <activix-tooltip :content="tooltip">
        <div class="input-wrapper">
            <activix-checkbox
                :disabled="disabled"
                :value="checked"
                :size="size"
                :color="color"
                @click.native.prevent="onClick"
            />
        </div>
    </activix-tooltip>
</template>

<script>
    import { mapActions, mapState } from 'pinia';

    import FollowUpMixin from '../../../../mixins/FollowUpMixin.js';
    import LeadType from '../../../../entities/LeadType.js';
    import { useDashboardStore } from '../../../../store/modules/dashboard/store.js';
    import { useGlobalStore } from '../../../../store/store.js';

    export default {
        mixins: [FollowUpMixin],

        props: {
            lead: {
                type: Object,
                required: true,
            },
            inClientCard: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            return {
                checked: false,
            };
        },

        computed: {
            ...mapState(useGlobalStore, ['authUser', 'guest']),
            ...mapState(useDashboardStore, ['dashboardType']),

            disabled() {
                if (['invalid', 'lost', 'duplicate'].includes(this.lead.status)) {
                    return true;
                }

                if (!this.lead.sale_date && !this.lead.sale_by_phone) {
                    return true;
                }

                if (!this.$can('leads.inspected:update')) {
                    return true;
                }

                return this.guest;
            },

            tooltip() {
                if (!this.inClientCard) {
                    return `<strong>${this.$t('clientCard.inspected')}</strong>`;
                }

                if (!this.lead.sale_date && !this.lead.sale_by_phone) {
                    if (this.lead.isOfType(LeadType.RENEWAL)) {
                        return this.$t('clientCard.needRenewalChecked');
                    }

                    return this.$t('clientCard.needSaleChecked');
                }

                if (['invalid', 'lost', 'duplicate'].includes(this.lead.status)) {
                    return `<em>${this.$t(`clientCard.statusTooltip.${this.lead.status}`, [
                        this.$t('clientCard.statusTooltip.inputs.inspected'),
                    ])}</em>`;
                }

                return '';
            },

            color() {
                if (!this.inClientCard && this.dashboardType == 'saleTable') {
                    return 'green';
                }

                return 'blue';
            },
        },

        watch: {
            'lead.inspected': {
                immediate: true,
                handler() {
                    this.updateChecked();
                },
            },
        },

        methods: {
            ...mapActions(useGlobalStore, ['updateLeadAction']),

            updateChecked() {
                this.checked = this.lead.inspected;
            },

            onClick() {
                if (this.disabled) {
                    return;
                }

                this.checked = !this.checked;
                this.updateLeadAction(this.lead.id, { inspected: this.checked });
            },
        },

        created() {
            this.$eventBus.$on('cancel-follow-up', this.updateChecked);
        },

        mounted() {
            this.updateChecked();
        },

        beforeDestroy() {
            this.$eventBus.$off('cancel-follow-up', this.updateChecked);
        },
    };
</script>
