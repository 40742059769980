<template>
    <activix-modal
        :name="name"
        :openable="isOpenable"
        ref="modal"
        @open="onOpen"
        @closed="onClose"
    >
        <template slot="header">
            <h4 class="modal-title">
                {{ $t('general.howToAssociate') }}
            </h4>
        </template>

        <template slot="body">
            <div class="gif-wrapper">
                <img
                    class="w-full"
                    :src="gifPath"
                    :alt="$t('clientCard.association') + ' / ' + $t('clientCard.fusion')"
                />
            </div>
        </template>

        <template slot="footer">
            <activix-button @click="close">
                OK
            </activix-button>
        </template>
    </activix-modal>
</template>

<script>
    export default {
        props: {
            name: {
                type: String,
                required: true,
            },
            inClientCard: {
                type: Boolean,
                default: false,
            },
        },

        computed: {
            gifPath() {
                if (this.inClientCard) {
                    return this.$i18n.locale ? '/img/associate_client_card_en.gif' : '/img/associate_client_card.gif';
                }

                return this.$i18n.locale ? '/img/associate_dashboard_en.gif' : '/img/associate_dashboard.gif';
            },
        },

        methods: {
            isOpenable() {
                const openCount = this.$ls.get('duplicateInfoModal:openCount', 0);

                return openCount < 3;
            },

            onOpen() {
                const openCount = this.$ls.get('duplicateInfoModal:openCount', 0);

                this.$ls.set('duplicateInfoModal:openCount', openCount + 1);
            },

            close() {
                this.$refs.modal.hide();
            },

            onClose() {
                this.$eventBus.$emit('open-delete-future-task-modal');
            },
        },
    };
</script>
