<template>
    <activix-checkbox
        :id="name"
        :size="size"
        :disabled="disabled"
        :tabindex="tabindex"
        :value="currentValue"
        :color="colorClass"
        @click.native.prevent="triggerAccept"
    />
</template>

<script>
    import { mapActions, mapState } from 'pinia';
    import { useGlobalStore } from '../../../store/store.js';
    import { useContextStore } from '../../../store/modules/context/store.js';

    export default {
        props: {
            name: String,
            value: Boolean,
            label: String,
            recordId: Number,
            disabled: Boolean,
            vehicle: {
                type: Boolean,
                default: false,
            },
            colorClass: {
                type: String,
                default: 'blue',
            },
            size: {
                type: String,
                default: 'sm',
            },
            tabindex: {
                type: String,
                default: '',
            },
            leadId: {
                type: Number,
                default: null,
            },
            customField: {
                type: Object,
                default: null,
            },
        },

        data() {
            return {
                currentValue: false,
            };
        },

        computed: {
            ...mapState(useContextStore, ['contextLead']),
        },
        watch: {
            value(newValue) {
                this.currentValue = newValue;
            },
        },

        methods: {
            ...mapActions(useGlobalStore, ['updateLeadAction', 'updateLeadVehicleAction']),

            triggerAccept() {
                if (this.disabled) {
                    return;
                }

                const value = !this.currentValue;

                this.$emit('input', value);

                this.accept(value, this.name);
            },

            async accept(value) {
                this.currentValue = value;

                // Only update if value changed and recordId is set
                if (value == this.value || empty(this.recordId)) {
                    return;
                }

                let options = {};
                const payload = {
                    [this.name]: value,
                };

                if (this.customField) {
                    options = {
                        customField: { ...this.customField, value },
                    };
                }

                if (this.vehicle) {
                    await this.updateLeadVehicleAction(payload, this.recordId, this.leadId, options);
                } else {
                    await this.updateLeadAction(this.recordId, payload, options);
                }
            },
        },

        created() {
            this.currentValue = this.value;
        },
    };
</script>
