<template>
    <activix-modal
        size="lg"
        portal="modal-2"
        :scrollable="true"
        :opened="opened"
        ref="modal"
        @close="close"
        @open="open"
    >
        <template slot="header">
            <h4 class="modal-title">
                {{ $t('users.edit.messenger') }}
            </h4>
        </template>
        <template slot="body">
            <activix-spinner v-if="fetching || !communication" />

            <template v-else>
                <messenger-message
                    :communication="communication"
                    :content="content"
                    :show-date-time="showDateTime(index, content)"
                    :key="index"
                    v-for="(content, index) in messages"
                />
            </template>
        </template>
        <template slot="footer">
            <div class="flex justify-between items-center">
                <div class="italic">
                    {{ $t('facebook.modal.noReply') }}
                </div>
                <activix-button @click="close">
                    {{ $t('general.close') }}
                </activix-button>
            </div>
        </template>
    </activix-modal>
</template>

<script>
    /* eslint-disable vue/require-prop-types, vue/no-mutating-props */
    // Components
    import ActivixDate from '@/value-objects/ActivixDate.js';
    import MessengerMessage from '../lead/MessengerMessage.vue';

    export default {
        components: {
            MessengerMessage,
        },

        props: {
            opened: {
                type: Boolean,
                required: true,
            },
            communication: {
                required: true,
            },
        },

        data() {
            return {
                fetching: true,
                messages: [],
            };
        },

        methods: {
            close() {
                this.$emit('update:opened', false);
            },

            open() {
                this.getBody();
            },

            async getBody() {
                this.fetching = true;

                try {
                    const response = await this.$axios.get(`v1/communications/${this.communication.id}/messages`);

                    this.messages = response.data.reverse();

                    this.fetching = false;

                    this.$refs.modal.scrollToBottom();
                } catch (error) {
                    this.$notify.error(this.$t('communications.alerts.show.error'));

                    throw error;
                }
            },

            showDateTime(index, message) {
                if (index == 0) {
                    return true;
                }

                const timeDifference = new ActivixDate(message.created_at).diffInMinutes(new ActivixDate(this.messages[index - 1].created_at));

                return timeDifference > 15;
            },
        },
    };
</script>
