<template>
    <activix-tooltip :content="tooltip">
        <div class="input-wrapper">
            <activix-checkbox
                :disabled="disabled"
                :value="checked"
                :size="size"
                @click.native.prevent="onClick"
            />
        </div>
    </activix-tooltip>
</template>

<script>
    /* eslint-disable vue/require-prop-types */
    import { mapActions, mapState } from 'pinia';
    import LeadVehicle from '../../../entities/LeadVehicle.js';
    import { useGlobalStore } from '../../../store/store.js';

    export default {
        props: ['lead', 'vehicleId', 'size', 'inClientCard'],

        data() {
            return {
                checked: false,
            };
        },

        computed: {
            ...mapState(useGlobalStore, ['authUser', 'guest']),

            disabled() {
                if (this.authUser.hasAdvisorSkills()) {
                    return true;
                }

                if (!this.soldVehicle) {
                    return true;
                }

                if (!this.lead.sale_date && !this.lead.sale_by_phone) {
                    return true;
                }

                if (['invalid', 'lost', 'duplicate'].includes(this.lead.status)) {
                    return true;
                }

                return this.guest;
            },

            tooltip() {
                if (this.inClientCard) {
                    return `${this.$t('accounts.edit.verifiedSaleTooltip')}`;
                }

                if (!this.verifiedBy) {
                    return `${this.$t('clientCard.vehicles.verifiedTooltip')}`;
                }

                return `
                    <div class="font-bold mb-2">${this.$t('clientCard.vehicles.verifiedTooltip')}</div>
                    <div>
                        <span class="font-bold">${this.$t('database.role_director')}</span> :
                        ${this.getFullName(this.verifiedBy)}
                    </div>
                    <div>
                        <span class="font-bold">${this.$t('general.vehicle')}</span> :
                        ${this.soldVehicle.toString()}
                    </div>
                `;
            },

            verifiedBy() {
                if (!this.soldVehicle) {
                    return null;
                }

                if (this.soldVehicle.verified_by_id == this.authUser.id) {
                    return this.authUser;
                }

                return this.lead.findUser(this.soldVehicle.verified_by_id);
            },

            soldVehicle() {
                const soldVehicle = this.lead.wanted_vehicles.find(vehicle => {
                    return vehicle.sold && (!this.vehicleId || this.vehicleId == vehicle.id);
                });

                return soldVehicle ? new LeadVehicle(soldVehicle) : null;
            },
        },

        watch: {
            'soldVehicle.verified_by_id': {
                immediate: true,
                handler(newValue) {
                    this.checked = !!newValue;
                },
            },
        },

        methods: {
            ...mapActions(useGlobalStore, ['updateLeadVehicleAction']),

            onClick() {
                if (this.disabled) {
                    return;
                }

                this.checked = !this.checked;

                const value = this.checked ? this.authUser.id : null;

                this.updateLeadVehicleAction({ verified_by_id: value }, this.soldVehicle.id, this.lead.id);
            },
        },
    };
</script>
