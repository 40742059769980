var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('activix-modal',{attrs:{"opened":_vm.opened,"portal":_vm.portal},on:{"close":_vm.close,"closed":_vm.onClosed}},[_c('template',{slot:"header"},[_c('h4',{staticClass:"modal-title"},[_vm._v(" "+_vm._s(_vm.$t('clientCard.chooseFusionRule'))+" ")])]),_c('template',{slot:"body"},[_c('div',{staticClass:"flex justify-center"},[_c('activix-tooltip',{attrs:{"content":_vm.$t('clientCard.mergeFillTooltip')}},[_c('activix-button',{attrs:{"active":_vm.currentAction === 'fill',"type":_vm.currentAction === 'fill' ? 'grey' : 'default'},on:{"click":function($event){_vm.currentAction = 'fill'}}},[_vm._v(" "+_vm._s(_vm.$t('accounts.edit.fill'))+" ")])],1),_c('activix-tooltip',{attrs:{"content":_vm.$t('clientCard.mergeReplaceTooltip')}},[_c('activix-button',{attrs:{"active":_vm.currentAction === 'overwrite',"type":_vm.currentAction === 'overwrite' ? 'grey' : 'default'},on:{"click":function($event){_vm.currentAction = 'overwrite'}}},[_vm._v(" "+_vm._s(_vm.$t('accounts.edit.overwrite'))+" ")])],1)],1),_c('hr',{staticClass:"my-6"}),(_vm.mergedLead && _vm.contextLead)?_c('div',{staticClass:"flex items-center lg:px-14"},[_c('div',{staticClass:"flex-1 flex justify-center overflow-hidden"},[_c('button',{staticClass:"text-left overflow-hidden",on:{"click":function($event){!_vm.isEvent ? (_vm.direction = 'toCurrent') : ''}}},[_c('div',[_c('icon',{staticClass:"text-blue-500 text-2xl",attrs:{"name":_vm.getIcon(_vm.contextLead.lead_type_id)}}),_c('span',{staticClass:"font-semibold ml-2"},[_vm._v(_vm._s(_vm.getTypeName(_vm.contextLead)))])],1),_c('div',[_vm._v(_vm._s(_vm.getDate(_vm.contextLead)))]),_c('div',{staticClass:"truncate max-w-48"},[_vm._v(" "+_vm._s(_vm.contextLead.fullName)+" ")]),_c('div',[_vm._v(" #"+_vm._s(_vm.contextLead.id)+" ")])])]),_c('div',{staticClass:"flex mx-4 flex-col items-center text-xl"},[_c('icon',{class:{
                            'opacity-50': _vm.direction === 'toOther',
                            'text-blue-500': _vm.direction === 'toCurrent',
                            'cursor-pointer': !_vm.isEvent,
                            'cursor-not-allowed': _vm.isEvent,
                        },attrs:{"name":"bold/arrow-thick-left"},on:{"click":function($event){!_vm.isEvent ? (_vm.direction = 'toCurrent') : ''}}}),_c('icon',{class:{
                            'opacity-50': _vm.direction === 'toCurrent',
                            'text-blue-500': _vm.direction === 'toOther',
                            'cursor-pointer': !_vm.isEvent,
                            'cursor-not-allowed': _vm.isEvent,
                        },attrs:{"name":"bold/arrow-thick-right"},on:{"click":function($event){!_vm.isEvent ? (_vm.direction = 'toOther') : ''}}})],1),_c('div',{staticClass:"flex-1 flex justify-center overflow-hidden"},[_c('button',{staticClass:"text-left overflow-hidden",on:{"click":function($event){!_vm.isEvent ? (_vm.direction = 'toOther') : ''}}},[_c('div',[_c('icon',{staticClass:"text-blue-500 text-2xl",attrs:{"name":_vm.getIcon(_vm.mergedLead.lead_type_id)}}),_c('span',{staticClass:"font-semibold ml-2"},[_vm._v(_vm._s(_vm.getTypeName(_vm.mergedLead)))])],1),_c('div',[_vm._v(_vm._s(_vm.getDate(_vm.mergedLead)))]),_c('div',{staticClass:"truncate max-w-48"},[_vm._v(" "+_vm._s(_vm.mergedLead.fullName)+" ")]),_c('div',[_vm._v(" #"+_vm._s(_vm.mergedLead.id)+" ")])])])]):_vm._e(),(!_vm.fromHasSms && _vm.toRenew)?_c('div',{staticClass:"mt-6 text-center"},[_c('span',{staticClass:"italic"},[_vm._v(_vm._s(_vm.$t('clientCard.mergeLeadRenewNote')))])]):_vm._e()]),_c('template',{slot:"footer"},[_c('activix-button',{attrs:{"disabled":_vm.executing},on:{"click":_vm.close}},[_vm._v(" "+_vm._s(_vm.$t('general.cancel'))+" ")]),_c('activix-button',{attrs:{"type":"primary","disabled":_vm.disabled,"loading":_vm.executing},on:{"click":function($event){_vm.modals.mergeConfirmationOpen = true}}},[_vm._v(" "+_vm._s(_vm.$t('clientCard.mergeLead'))+" ")])],1)],2),_c('activix-confirm-modal',{attrs:{"type":"warning","content":_vm.$t('clientCard.sureMerge'),"opened":_vm.modals.mergeConfirmationOpen},on:{"update:opened":function($event){return _vm.$set(_vm.modals, "mergeConfirmationOpen", $event)},"approve":_vm.executeAction}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }