<template>
    <activix-tooltip :content="tooltip">
        <div class="input-wrapper">
            <activix-checkbox
                :disabled="disabled"
                :value="checked"
                :size="size"
                :color="color"
                @click.native.prevent="onClick"
            />
        </div>
    </activix-tooltip>
</template>

<script>
    import { mapActions, mapState } from 'pinia';

    // Entities
    import LeadStatus from '../../../../entities/LeadStatus.js';

    import FollowUpMixin from '../../../../mixins/FollowUpMixin.js';
    import { useGlobalStore } from '../../../../store/store.js';

    export default {
        mixins: [FollowUpMixin],

        props: {
            lead: {
                type: Object,
                required: true,
            },
            inClientCard: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            return {
                checked: false,
            };
        },

        computed: {
            ...mapState(useGlobalStore, ['authUser', 'guest']),

            disabled() {
                if ([LeadStatus.INVALID, LeadStatus.LOST, LeadStatus.DUPLICATE].includes(this.lead.status)) {
                    return true;
                }

                return this.guest;
            },

            tooltip() {
                return !this.inClientCard ? this.$t('clientCard.loyaltyTooltip') : '';
            },

            color() {
                return !this.inClientCard ? 'green' : 'blue';
            },
        },

        watch: {
            'lead.loyalty': {
                immediate: true,
                handler() {
                    this.updateChecked();
                },
            },
        },

        methods: {
            ...mapActions(useGlobalStore, ['updateLeadAction']),

            onClick() {
                if (this.disabled) {
                    return;
                }

                this.checked = !this.checked;
                this.updateLeadAction(this.lead.id, { loyalty: this.checked });
            },

            updateChecked() {
                this.checked = this.lead.loyalty;
            },
        },

        created() {
            this.$eventBus.$on('cancel-follow-up', this.updateChecked);
        },

        mounted() {
            this.updateChecked();
        },

        beforeDestroy() {
            this.$eventBus.$off('cancel-follow-up', this.updateChecked);
        },
    };
</script>
