<template>
    <lead-input
        :disabled="disabled"
        :center-icon="centerIcon"
        :editing="editing"
        :valid="valid"
        @accept="triggerAccept"
        @cancel="cancel"
        @edit="editField"
        @blur="onBlur"
    >
        <activix-masked-input
            class="w-full | md:w-auto"
            :input-class="['input-sm', { 'p-0 border-none bg-transparent': lgLayout }]"
            :mask-type="maskType"
            :country="country"
            :placeholder="placeholder"
            :integer-limit="maxLengthNormalized"
            :disabled="disabled"
            :custom-mask="customMask"
            :allow-zero-value="allowZeroValue"
            ref="input"
            v-model="currentValue"
        />

        <template #value>
            <template v-if="prefix">
                {{ prefix }}
            </template>

            <template v-if="(allowZeroValue && unmaskedValue == 0) || !empty(unmaskedValue) || unmaskedValue === 0">
                <template v-if="type == 'currency'">
                    {{ toMoney(unmaskedValue, 2) }}
                </template>
                <template v-else-if="type == 'percent'">
                    {{ unmaskedValue }} %
                </template>
                <template v-else-if="type == 'kilometers'">
                    {{ unmaskedValue }} km
                </template>
                <template v-else-if="type == 'meters'">
                    {{ unmaskedValue }} m
                </template>
                <template v-else>
                    {{ unmaskedValue }}
                </template>
            </template>

            <template v-else>
                {{ emptyValue }}
            </template>
        </template>
    </lead-input>
</template>

<script>
    import { mapActions } from 'pinia';
    import { parseCustomField } from '../../../value-objects/CustomField.js';

    // Components
    import LeadInput from '../LeadInput.vue';
    import { useGlobalStore } from '../../../store/store.js';

    export default {
        components: {
            LeadInput,
        },

        props: {
            name: String,
            prefix: String,
            value: [String, Number, Array],
            placeholder: String,
            type: String,
            disabled: Boolean,
            recordId: Number,
            maxLength: Number,
            country: {
                type: String,
                default: 'CA',
            },
            vehicle: {
                type: Boolean,
                default: false,
            },
            customField: {
                type: Object,
                default: null,
            },
            valid: {
                type: Boolean,
                default: true,
            },
            emptyValue: {
                type: String,
                default: '...',
            },
            centerIcon: {
                type: Boolean,
                default: true,
            },
            allowZeroValue: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                currentValue: null,
                editing: false,
            };
        },

        computed: {
            maxLengthNormalized() {
                let max = this.maxLength || 255;

                switch (this.type) {
                    case 'percent':
                        max = 5;
                        break;

                    case 'currency':
                        max = 8;
                        break;

                    case 'driving':
                        max = 17;
                        break;

                    case 'integer':
                    case 'numeric':
                        max = 9;
                        break;

                    case 'meters':
                    case 'kilometers':
                        max = 9;
                        break;
                }

                return max;
            },

            maskType() {
                let mask = 'custom';

                switch (this.type) {
                    case 'postalCode':
                        mask = 'postalCode';
                        break;

                    case 'currency':
                        mask = 'money';
                        break;

                    case 'percent':
                        mask = 'percent';
                        break;

                    case 'kilometers':
                        mask = 'kilometers';
                        break;

                    case 'meters':
                        mask = 'meters';
                        break;

                    case 'numeric':
                        mask = 'number';
                        break;
                }

                return mask;
            },

            customMask() {
                switch (this.type) {
                    case 'driving':
                        return Array(17).fill(/./);

                    default:
                        return Array(this.maxLengthNormalized).fill(/./);
                }
            },

            unmaskedValue() {
                let unmaskedValue = this.currentValue || null;

                if (!unmaskedValue) {
                    return unmaskedValue;
                }

                if (!['currency', 'numeric'].includes(this.type)) {
                    unmaskedValue = unmaskedValue.trim();
                }

                if (this.type == 'percent') {
                    unmaskedValue = unmaskedValue.replace('%', '');
                }

                return unmaskedValue;
            },
        },

        watch: {
            value() {
                if ((!this.allowZeroValue && this.value === 0 && empty(this.value)) || this.value === false) {
                    this.currentValue = null;
                    return;
                }

                this.currentValue = this.decodeString(this.value).trim();
            },
        },

        methods: {
            ...mapActions(useGlobalStore, ['updateLeadAction', 'updateLeadVehicleAction']),

            async editField() {
                if (this.disabled) {
                    return;
                }

                this.editing = true;

                await this.$nextTick();

                this.$refs.input.focus();
            },

            triggerAccept() {
                if (!this.editing) {
                    return;
                }

                this.accept(this.unmaskedValue);
            },

            async accept(value) {
                this.editing = false;

                // Only update if value changed and recordId is set
                if (
                    value == this.value ||
                    (empty(value) && empty(this.value) && !this.allowZeroValue && value == 0) ||
                    empty(this.recordId)
                ) {
                    return;
                }

                if (!this.allowZeroValue && value == 0) {
                    value = null;
                }

                let options = {};
                const payload = {
                    [this.name]: value,
                };

                if (this.customField) {
                    options = {
                        customField: { ...this.customField, value: parseCustomField.toSave(value, this.customField.type) },
                    };
                }

                if (this.vehicle) {
                    await this.updateLeadVehicleAction(payload, this.recordId, this.leadId, options);
                } else {
                    await this.updateLeadAction(this.recordId, payload, options);
                }
            },

            cancel() {
                this.editing = false;

                this.currentValue = this.decodeString(this.value).trim();
            },

            init() {
                if (empty(this.value) && !this.allowZeroValue && this.value !== 0) {
                    return;
                }

                this.currentValue = this.decodeString(this.value).trim();
            },

            onBlur() {
                // Delay is necessary to prevent event overlapping
                setTimeout(() => {
                    this.triggerAccept();
                }, 50);
            },
        },

        created() {
            this.init();
        },
    };
</script>
