<template>
    <activix-tooltip :content="tooltip">
        <div class="input-wrapper">
            <activix-checkbox
                :disabled="disabled"
                :value="checked"
                :size="size"
                :cross="isCross"
                @mouseenter.native="disabled || fetchLead(lead.id)"
                @click.native.prevent="onClick"
            />

            <template v-if="inClientCard">
                <span class="editable | ml-4 | md:ml-2" :class="{ disabled: disabled }" @click="onDateClick">
                    {{ localizedDate || emptyValue }}
                </span>
            </template>
        </div>
    </activix-tooltip>
</template>

<script>
    import { mapActions, mapState } from 'pinia';

    import ActivixDate from '../../../../value-objects/ActivixDate.js';

    import FollowUpMixin from '../../../../mixins/FollowUpMixin.js';
    import { useGlobalStore } from '../../../../store/store.js';

    export default {
        mixins: [FollowUpMixin],

        props: {
            lead: {
                type: Object,
                required: true,
            },
            startDate: {
                type: ActivixDate,
                default: null,
            },
            endDate: {
                type: ActivixDate,
                default: null,
            },
            inClientCard: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            return {
                checked: false,
            };
        },

        computed: {
            ...mapState(useGlobalStore, ['authUser', 'guest']),

            date() {
                return new ActivixDate(this.lead.be_back_date);
            },

            localizedDate() {
                return this.date.toHumanShort();
            },

            isInDates() {
                return this.date.isBetween(this.startDate, this.endDate);
            },

            disabled() {
                if (!this.lead.presented_date || this.lead.status == 'lost') {
                    return true;
                }

                if (this.authUser.id && !this.authUser.isAuthorizedToActionOnLead(this.lead)) {
                    return true;
                }

                if (this.lead.disabledBySaleVerified(this.authUser)) {
                    return true;
                }

                return !this.$can('leads.be_back_date:update');
            },

            isCross() {
                if (this.inClientCard || !this.lead.be_back_date || this.isInDates) {
                    return false;
                }

                return true;
            },

            tooltip() {
                let tooltip = '';

                if (!this.inClientCard) {
                    tooltip += `<strong>${this.$t('date.beBack')}</strong>`;

                    if (this.lead.be_back_date) {
                        tooltip += `<br>${this.localizedDate}`;

                        if (!this.isInDates) {
                            tooltip += `<br><em>${this.$t('dashboards.notInDate')}</em>`;
                        }
                    }

                    if (!this.lead.presented_date) {
                        tooltip += `<br><em>${this.$t('clientCard.needPresentedDealerChecked')}</em>`;
                    }
                } else {
                    if (this.lead.disabledBySaleVerified(this.authUser)) {
                        return `<em>${this.$t('clientCard.verifiedSaleUneditable')}</em>`;
                    }

                    if (['invalid', 'lost', 'duplicate'].includes(this.lead.status)) {
                        return `<em>${this.$t(`clientCard.statusTooltip.${this.lead.status}`, [
                            this.$t('clientCard.statusTooltip.inputs.beBack'),
                        ])}</em>`;
                    }

                    if (!this.lead.presented_date) {
                        return this.$t('clientCard.needPresentedDealerChecked');
                    }
                }

                return tooltip;
            },

            showModalFromUrl() {
                return (
                    !this.disabled &&
                    this.lead.presented_date &&
                    !this.lead.delivered_date &&
                    !this.lead.sale_date &&
                    !this.lead.be_back_date &&
                    !empty(this.$route.query.date)
                );
            },
        },

        watch: {
            'lead.be_back_date': {
                immediate: true,
                handler() {
                    this.updateChecked();
                },
            },

            '$route.query': {
                immediate: true,
                handler() {
                    if (this.showModalFromUrl) {
                        this.openFollowUpModal();
                    }
                },
            },
        },

        methods: {
            ...mapActions(useGlobalStore, ['updateLeadAction', 'fetchLead']),

            onDateClick() {
                const toggleChecked = !this.checked;

                this.onClick(toggleChecked);
            },

            updateChecked() {
                this.checked = !!this.lead.be_back_date;
            },

            onClick(toggleChecked = true) {
                if (this.disabled) {
                    return;
                }

                if (toggleChecked) {
                    this.checked = !this.checked;
                }

                if (this.checked) {
                    this.openFollowUpModal();
                } else {
                    this.removeProcess();
                }
            },

            removeProcess() {
                this.updateLeadAction(this.lead.id, {
                    be_back: false,
                    be_back_date: null,
                });
            },

            openFollowUpModal() {
                this.$modal.show('followUp', {
                    leadId: this.lead.id,
                    current: 'be_back',
                    last: null,
                });
            },
        },

        created() {
            this.$eventBus.$on('cancel-follow-up', this.updateChecked);
        },

        mounted() {
            this.updateChecked();
        },

        beforeDestroy() {
            this.$eventBus.$off('cancel-follow-up', this.updateChecked);
        },
    };
</script>
