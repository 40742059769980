<template>
    <div class="vue-stars">
        <activix-tooltip :content="lead.rating_comment" v-if="!simpleView">
            <div class="ratings" :class="{ readonly: readonly, notouch: notouch }">
                <input
                    :id="`${name}0`"
                    type="radio"
                    :checked="value === 0"
                    :name="name"
                    value="0"
                />

                <template v-for="x in range">
                    <label :class="{ 'cursor-default': readonly }" :for="`${name}${x}`" :key="`l${x}`">
                        <icon name="bold/rating-star-1" class="active" scale="1.3" />
                        <icon name="bold/rating-star-1" class="inactive" scale="1.3" />
                    </label>

                    <input
                        :id="`${name}${x}`"
                        type="radio"
                        :checked="value === x"
                        :disabled="readonly"
                        :name="name"
                        :value="x"
                        :key="`i${x}`"
                        @click="updateInput($event.target.value)"
                    />
                </template>
            </div>
        </activix-tooltip>
        <activix-tooltip :content="lead.rating_comment" v-else>
            <div class="relative flex items-center justify-center">
                <icon name="bold/rating-star-1" scale="2.4" :class="value ? 'text-yellow-500' : 'text-grey-600'" />
                <span
                    class="absolute font-bold leading-none"
                    :class="value ? 'text-grey-700' : 'text-white'"
                    v-text="value"
                />
            </div>
        </activix-tooltip>

        <rating-modal
            :opened.sync="ratingModalOpened"
            :rating="lead.rating"
            :rating-comment="lead.rating_comment"
            @update-lead="updateLead"
            v-if="!simpleView"
        />
    </div>
</template>

<script>
    /* eslint-disable vue/no-mutating-props */
    import { range } from 'lodash-es';

    // Components
    import { mapActions } from 'pinia';
    import RatingModal from '../modals/RatingModal.vue';
    import { useGlobalStore } from '../../store/store.js';

    export default {
        components: {
            RatingModal,
        },

        props: {
            simpleView: {
                type: Boolean,
                default: false,
            },

            max: {
                type: Number,
                required: false,
                default: 5,
            },

            default: {
                type: Number,
                required: false,
                default: 0,
            },

            name: {
                type: String,
                required: false,
                default: 'rating',
            },

            readonly: {
                type: Boolean,
                required: false,
                default: false,
            },

            lead: {
                type: Object,
                required: true,
            },
        },

        data() {
            return {
                ratingModalOpened: false,
            };
        },

        computed: {
            notouch() {
                // For iPhone specifically but really any touch device, there is no true hover state, disabled any pseudo-hover activity.
                return !('ontouchstart' in document.documentElement);
            },

            range() {
                return range(1, this.max + 1);
            },

            value() {
                const isEmpty = !this.lead.rating || this.lead.rating === 'none';

                return isEmpty ? this.default : this.lead.rating;
            },
        },

        methods: {
            ...mapActions(useGlobalStore, ['updateLeadAction']),

            openRatingModal() {
                this.ratingModalOpened = true;
            },

            updateInput(value) {
                const newRating = parseInt(value, 10);

                if (this.lead.rating != newRating) {
                    this.updateLead({ rating: newRating });

                    this.$emit('input', this.value);
                }

                this.openRatingModal();
            },

            updateLead(data) {
                this.updateLeadAction(this.lead.id, data);
            },
        },
    };
</script>

<style lang="less">
    .vue-stars {
        .ratings {
            display: inline-flex;
            flex-flow: row nowrap;
            align-items: flex-start;
            line-height: 1em;

            label {
                @apply text-yellow-500;

                display: block;
                padding: 0.125em;
                width: 1.2em;
                text-align: center;
                cursor: pointer;
                margin-bottom: 0;
            }

            input,
            label .inactive,
            input:checked ~ label .active,
            &.notouch:not(.readonly):hover label .inactive,
            &.notouch:not(.readonly) label:hover ~ label .active {
                display: none;
            }

            input:checked ~ label .inactive,
            &.notouch:not(.readonly):hover label .active,
            &.notouch:not(.readonly) label:hover ~ label .inactive {
                display: inline;
            }

            &.notouch:not(.readonly):hover label {
                @apply text-yellow-500;
            }

            input:checked ~ label,
            &.notouch:not(.readonly) label:hover ~ label {
                @apply text-grey-500;
            }
        }
    }
</style>
