<template>
    <activix-modal size="sm" :opened="opened" @close="close">
        <template slot="body">
            <sweet-alert-icon class="mx-auto" type="error" />
            <div class="text-2xl font-semibold text-center text-grey-650" v-text="$t('general.areYouSure')" />
            <div class="text-lg text-center" v-text="$t('dashboards.addOrModifyAppointment')" />
        </template>

        <template slot="footer">
            <activix-button type="danger" @click="dispatchSetCurrentFollowUp('appt_call', true)">
                {{ $t('result.modifyPhoneAppointment') }}
            </activix-button>
            <activix-button type="danger" @click="dispatchSetCurrentFollowUp('appointment', false)">
                {{ $t('result.addAppointment') }}
            </activix-button>
        </template>
    </activix-modal>
</template>

<script>
    import SweetAlertIcon from '../elements/SweetAlert/Icon.vue';

    export default {
        components: {
            SweetAlertIcon,
        },

        props: {
            leadId: {
                type: Number,
                required: true,
            },
            opened: {
                type: Boolean,
                required: true,
            },
        },
        methods: {
            dispatchSetCurrentFollowUp(currentSetup, showPhoneIcon) {
                this.$modal.show('followUp', {
                    leadId: this.leadId,
                    current: currentSetup,
                    last: null,
                    showPhoneIcon,
                });

                this.close();
            },

            close() {
                this.$emit('update:opened', false);
            },
        },
    };
</script>
